import { memo, useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useConfirm } from 'material-ui-confirm';
import Snackbar from '@mui/material/Snackbar';

import { TableData } from '../helper';
import { MoreBox, StatusButton, StatusChangerButton, StyledTableBody } from './styled';
import Button from '../../../../common/components/Button';
import { currencyParser, getCDNImagePath } from '../../../../utils/helpers';
import MorePopover from '../../../../common/components/Table/MorePopover';
import { deleteConfirmOption, getPaymentStatus, paymentStatusColors } from './helpers';
import { ORDER_STATUS } from '../../../../types/orders';
import { changeStatusCloseOrderApi, changeStatusOpenOrderApi } from '../../../../requests/orders';
import { STAGING_URL } from '../../../../utils/constants';
import Alert from '../../../../common/components/Alert ';
import { StyledRedText } from '../../../AllProducts/Table/TableBody/styled';

interface ITableBodyProps {
  page: number;
  rows: TableData[];
  rowsPerPage: number;
  handleOpen?: (id: string, status: keyof typeof ORDER_STATUS) => void;
  isDialogContent: boolean;
  getFilteredOrders?: () => void;
  handleStatusChanging?: () => void;
}

const TableBody = ({
  rows,
  page,
  rowsPerPage,
  handleOpen,
  isDialogContent,
  getFilteredOrders,
  handleStatusChanging,
}: ITableBodyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const slicedAndSorted = isDialogContent ? rows : rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const [err, setErr] = useState(false);
  const confirm = useConfirm();
  const handleOutChangeStatus = async (e: any, id: string, status: keyof typeof ORDER_STATUS) => {
    if (status === ORDER_STATUS.CANCELED) {
      await confirm(deleteConfirmOption);
    }

    e.stopPropagation();

    try {
      await changeStatusCloseOrderApi(id, { status });
      getFilteredOrders?.();
    } catch (error: any) {
      if (error.data.errors.error === 'OrderHaveChangedItems') {
        setErr(true);
      }

      throw error;
    }
  };

  const handleInChangeStatus = async (e: any, id: string, status: keyof typeof ORDER_STATUS) => {
    e.stopPropagation();
    if (status === ORDER_STATUS.CANCELED) {
      await confirm(deleteConfirmOption);
    }

    await changeStatusOpenOrderApi(id, { status });
    handleStatusChanging?.();
  };

  const handleSeeMore = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (err) {
      const interval = setInterval(() => {
        setErr(false);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [err]);

  return (
    <>
      { err && (
      <Snackbar
        open={err}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginTop: '40px !important' }}
      >
        <Alert severity="error">
          This order contains items with status Delivered or Canceled!
        </Alert>
      </Snackbar>
      )}
      <StyledTableBody>
        {rows.map(({
          id, photo, order, date, seller, firstName, status, quantity, name, price,
          isReturned, variant, paymentStatus, listingId, isQtProblem,
        }) => {
          const checkReurned = isDialogContent ? !isReturned : true;

          return (
            <TableRow
              hover
              key={id}
              tabIndex={-1}
              role="checkbox"
              style={{ height: 64 }}
              onClick={() => !isDialogContent && handleOpen?.(id, status)}
            >
              <TableCell sx={{ padding: '16px' }} align="left" onClick={(e) => handleSeeMore(e)}>
                { photo ? (
                  <Link href={`${STAGING_URL}/product/${listingId}`} target="_blank" color="inherit">
                    <Avatar
                      alt="avatar"
                      src={getCDNImagePath(photo)}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                )
                  : (
                    <Avatar
                      alt="avatar"
                      src={photo && getCDNImagePath(photo)}
                      sx={{ width: 24, height: 24 }}
                    />
                  )}
              </TableCell>
              <TableCell>{order}</TableCell>
              <TableCell>{date}</TableCell>
              <TableCell>{seller}</TableCell>
              <TableCell>{firstName}</TableCell>
              {!isDialogContent && (
              <TableCell sx={{ padding: '0' }}>
                <StatusButton
                  text_color={paymentStatusColors(paymentStatus)}
                  variant="contained"
                  color="inherit"
                >
                  {getPaymentStatus(paymentStatus)}
                </StatusButton>
              </TableCell>
              )}
              <TableCell sx={{ padding: '0' }}>
                <Button
                  color={
                  (status === ORDER_STATUS.DELIVERED || status === ORDER_STATUS.COMPLETED) ? 'success'
                    : status === ORDER_STATUS.PROCESSED ? 'primary' : 'info'
                }
                  sx={{ textTransform: 'capitalize' }}
                  variant="contained"
                >
                  {status.toLocaleLowerCase()}
                </Button>
              </TableCell>
              <TableCell onClick={(e) => handleSeeMore(e)}>
                <Link
                  href={`${STAGING_URL}/product/${listingId}`}
                  target="_blank"
                  color="inherit"
                  sx={{ textDecoration: 'none' }}
                >
                  { name}
                </Link>
                <Typography>
                  {isDialogContent && variant?.option1 &&
                    `${variant?.option1?.option?.name}:  ${variant?.option1?.value},`}
                </Typography>
                <Typography />
                {isDialogContent && variant?.option2 &&
                  `${variant?.option2?.option?.name}:  ${variant?.option2?.value}`}
              </TableCell>
              <TableCell>
                {currencyParser(price)}
              </TableCell>
              <TableCell>
                {(isDialogContent && isQtProblem) ? (
                  <StyledRedText>
                    Not available. Order should be canceled!
                  </StyledRedText>
                ) : quantity}
              </TableCell>
              {isDialogContent && <TableCell sx={{ paddingLeft: '16px' }}>{isReturned ? 'Yes' : 'No'}</TableCell>}

              {(status !== ORDER_STATUS.CANCELED && paymentStatus === 'payment_deposited' && checkReurned) ? (
                <TableCell
                  sx={{ width: '10px', padding: 0 }}
                  align="right"
                  onClick={(e) => handleSeeMore(e)}
                >
                  <MorePopover>
                    <MoreBox>
                      {!isDialogContent && (
                      <StatusChangerButton
                        onClick={(e: any) => handleOutChangeStatus(e, id, ORDER_STATUS.COMPLETED)}
                      >
                        <Typography variant="body1">Complete Order</Typography>
                      </StatusChangerButton>
                      )}

                      {isDialogContent && (
                      <StatusChangerButton
                        onClick={(e: any) => handleInChangeStatus(e, id, ORDER_STATUS.DELIVERED)}
                      >
                        <Typography variant="body1">Delivered Order</Typography>
                      </StatusChangerButton>
                      )}
                      <Divider />
                      <StatusChangerButton
                        onClick={(e: any) => (
                          isDialogContent
                            ? handleInChangeStatus(e, id, ORDER_STATUS.CANCELED)
                            : handleOutChangeStatus(e, id, ORDER_STATUS.CANCELED))}
                      >
                        <Typography variant="body1">Cancel Order</Typography>
                      </StatusChangerButton>
                      { isDialogContent && <Divider />}
                      {/* TODO: ADD this call
                    onClick={(e: any) => handleInChangeStatus(e, id, ORDER_STATUS.READY_TO_SHIP)} */}
                      {isDialogContent && (
                      <StatusChangerButton>
                        <Typography variant="body1">Ready to ship</Typography>
                      </StatusChangerButton>
                      )}
                      <Divider />
                      {isDialogContent && (
                      <StatusChangerButton>
                        <Typography variant="body1">Shipped</Typography>
                      </StatusChangerButton>
                      ) }
                    </MoreBox>
                  </MorePopover>
                </TableCell>
              ) : <TableCell />}
            </TableRow>
          );
        })}
      </StyledTableBody>
    </>
  );
};

export default memo(TableBody);
