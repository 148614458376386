import {
  memo,
  useState,
  Dispatch,
  useEffect,
  ChangeEvent,
  SetStateAction,
  MouseEvent,
} from 'react';

import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import { TableData } from './helper';
import TableHead from './TableHead';
import TableBody from './TableBody/index';
import { Order } from '../../../utils/types';
import { IAllShopsCore } from '../../../types/shops';

interface ITableProps {
  order: Order;
  offset: number;
  shops: IAllShopsCore[];
  totalShops: number;
  rowsPerPage: number;
  orderBy?: keyof TableData;
  setOffset: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  handleActivateRequest: (shopId: string, isActive: boolean) => Promise<void>;
  getShops: () => void;
  handleSortRequest: (order: Order, sort: keyof TableData) => Promise<void>;
  openModal: (id: string) => void;
  handleDeleteRequest: (shopId: string) => Promise<void>;
}

const Table = ({
  order,
  shops,
  offset,
  orderBy,
  setOffset,
  totalShops,
  rowsPerPage,
  setRowsPerPage,
  handleActivateRequest,
  handleDeleteRequest,
  getShops,
  handleSortRequest,
  openModal,
}: ITableProps) => {
  const [rows, setRows] = useState<IAllShopsCore[]>([]);

  useEffect(() => {
    setRows(shops);
  }, [shops]);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    handleSortRequest(isAsc ? 'desc' : 'asc', property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody
              handleDeleteRequest={handleDeleteRequest}
              openModal={openModal}
              rows={rows}
              handleActivateRequest={handleActivateRequest}
              getShops={getShops}
            />
          </MuiTable>
        </TableContainer>

        <TablePagination
          page={offset}
          component="div"
          count={totalShops}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, newPage: number) => setOffset(newPage)}
        />
      </Paper>
    </Box>
  );
};

export default memo(Table);
