import { memo, useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputAdornment from '@mui/material/InputAdornment';
import Flag from 'react-world-flags';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';

import { validationSchema } from '../../helper';
import SaveButton from '../../../../common/components/Button';
import BaseInput from '../../../../common/components/Input/BaseInput';
import InputLabel from '../../../../common/components/InputLabel/InputLabel';
import { SpecificationGroupCreatePayload } from '../../../../models/category-model';
import { initialTranlationsState, replceSpacingWithUnderscore } from '../../../../utils/helpers';
import { createSpecificationGroupApi } from '../../../../requests/specification-group';
import { SpecificationGroupCore } from '../../../../models/category-specifications-model';
import SectionTitle from '../../../../common/components/SectionTitle/SectionTitle';
import { FLAGS_LANGUAGES } from '../../../../utils/constants';
import { updateRefundableCategoryApi } from '../../../../requests/category';

interface SpecificationsGroupProps {
  currentCategory: SpecificationGroupCore | null;
  creatHandler: ()=> void;
}

const SpecificationsGroup = ({
  currentCategory,
  creatHandler,
}: SpecificationsGroupProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SpecificationGroupCreatePayload>({
    defaultValues: {
      translations: initialTranlationsState,
    },
    resolver: yupResolver(validationSchema),
  });

  const { id } = useParams();

  const onSubmit = (data: SpecificationGroupCreatePayload) => {
    const key = replceSpacingWithUnderscore(data.translations[0].text);

    const createSpecificationGroup = async () => {
      const response = await createSpecificationGroupApi({
        ...data,
        key,
        categoryId: currentCategory?.id,
        isActive: true,
      });

      if (!response) {
        reset();
        creatHandler();
      }
    };

    createSpecificationGroup();
  };

  const [isRefundable, setIsRefundable] = useState(true);

  useEffect(() => {
    setIsRefundable(!!currentCategory?.isRefundable);
  }, [currentCategory?.isRefundable]);

  const handleCheckboxChange = async () => {
    try {
      await updateRefundableCategoryApi(id as string, { isRefundable: !isRefundable });
      setIsRefundable(!isRefundable);
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <SectionTitle title="Create" />
        <Paper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          sx={{ marginBottom: '20px', padding: '0 10px' }}
        >
          <FormControlLabel
            control={
              <Checkbox size="small" checked={!isRefundable} onChange={handleCheckboxChange} />
            }
            label="Not Refundable"
          />
        </Paper>
      </Stack>

      <Paper
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={{ width: '100%', padding: '20px', marginBottom: '20px' }}
      >
        <InputLabel title="Selected category" />
        <TextField
          disabled
          sx={{ width: '100%', margin: '10px 0' }}
          InputProps={{
            value: currentCategory?.translation?.text || '',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small">
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Stack
          direction="row"
          sx={{ width: '100%', padding: '20px 0', justifyContent: 'space-between' }}
        >
          {
            FLAGS_LANGUAGES.map((item, idx) => (
              <BaseInput
                key={item}
                label={<InputLabel title="Name" flag={<Flag code={item} height="22" width="22" />} />}
                errors={errors}
                inputProps={{
                  placeholder: 'Input Name',
                  ...register(`translations.${idx}.text`, { required: true }),
                }}
                sx={{ minWidth: '230px' }}
              />
            ))
          }
        </Stack>
        <SaveButton type="submit" title="Create" sx={{ width: '100%' }} />
      </Paper>
    </>

  );
};

export default memo(SpecificationsGroup);
