import { Components, Theme } from '@mui/material';

import { COLORS } from '../types/global/theme/colors';

export const MuiPaper: Components<Theme>['MuiPaper'] = {
  styleOverrides: {
    root: {
      borderRadius: '6px',
      background: COLORS.white,
    },
  },
};
