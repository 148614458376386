import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  AUTH_ACTIONS,
  setSignInSucceed,
  setUserAction,
  setUsrFailureAction,
} from '../actions/auth';
import { signInApi } from '../../requests/auth';
import { setLocaleStorageData } from '../../utils/helpers';
import StorageManager from '../../utils/storage-manager';
import handleSagaError from '../../utils/handle-errors';
import { ISignInResponseType, IUserType } from '../../models/auth-model';

function* signIn({ payload }: any) {
  try {
    const { data }: AxiosResponse<ISignInResponseType> = yield call(
      signInApi,
      payload,
    );

    setLocaleStorageData(data);

    yield put(setSignInSucceed({ accessToken: data.accessToken }));
  } catch (err: unknown) {
    yield put({
      type: AUTH_ACTIONS.SET_SIGN_IN_FAILURE,
      payload: handleSagaError(err),
    });
  }
}

function* getUserWorker() {
  try {
    // TODO: must add endpoint for get my userId by access token, Temporary solution
    const storageUser: IUserType = StorageManager.getItem('user');

    // TODO: Maybe used later:

    // const { data }: ResponseData<IUserType> = yield call(
    //   getUserByIdApi,
    //   storageUser.id,
    // );

    // StorageManager.setItem('user', data);
    yield put(setUserAction(storageUser));
  } catch (err) {
    yield put(setUsrFailureAction(handleSagaError(err)));
  }
}

function* authSaga() {
  yield takeLatest(AUTH_ACTIONS.ATTEMPT_TO_SIGN_IN, signIn);
  yield takeLatest(AUTH_ACTIONS.ATTEMPT_TO_GET_USER, getUserWorker);
}

export default authSaga;
