import { memo, useCallback, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useForm } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm';

import { SpecificationGroup } from '../../../../../models/category-specifications-model';
import { initialTranlationsState, sortedCollection } from '../../../../../utils/helpers';
import { SpecificationGroupUpdatePayload } from '../../../../../models/category-model';
import BaseInput from '../../../../../common/components/Input/BaseInput';
import Button from '../../../../../common/components/Button';
import {
  specificationGroupDeleteApi,
  updatSpecificationGroupApi,
} from '../../../../../requests/specification-group';
import SpecificationsRows from './SpecificationsRows';
import { StyledCellBox, StyledRowBox } from './styled';
import { deleteConfirmOption } from '../../../../../common/components/Table/helper';

interface RowProps {
  row: SpecificationGroup | null;
  getCategoryInfo: () => void;
}

const Row = ({ row, getCategoryInfo }:RowProps) => {
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SpecificationGroupUpdatePayload>({
    defaultValues: {
      translations: initialTranlationsState,
    },
  });

  const sortedTranslations = sortedCollection(row?.translations);
  const rowSpec = row?.specifications || [];
  const onSubmit = (data: SpecificationGroupUpdatePayload) => {
    if (row) {
      const { key, id, isActive } = row;
      const body = { key, id, isActive, translations: data.translations };

      const updatSpecificationGroup = async () => {
        const response = await updatSpecificationGroupApi(id, body);

        if (!response) {
          setIsFocused(false);
          getCategoryInfo();
        }
      };

      updatSpecificationGroup();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (sortedTranslations) {
      setValue('translations', [...sortedTranslations]);
    }
  };

  const confirm = useConfirm();
  const deleteHandler = useCallback(() => {
    const deleteFunction = async () => {
      await confirm(deleteConfirmOption);

      if (row?.id) {
        const response = await specificationGroupDeleteApi(row.id);

        if (!response) {
          getCategoryInfo();
        }
      }
    };

    deleteFunction();
  }, [confirm, getCategoryInfo, row?.id]);

  return (
    <>
      {
        !isFocused ? (
          <StyledRowBox>
            <StyledCellBox>
              {rowSpec.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) }
            </StyledCellBox>
            {[0, 1, 2].map((idx) => (
              <StyledCellBox key={idx} onClick={handleFocus}>
                <Typography>{sortedTranslations?.[idx]?.text}</Typography>
              </StyledCellBox>
            ))}
            <IconButton onClick={deleteHandler}>
              <DeleteIcon color="error" />
            </IconButton>
          </StyledRowBox>
        ) : (
          <StyledRowBox>
            <StyledCellBox />
            {[0, 1, 2].map((idx) => (
              <StyledCellBox key={idx}>
                <BaseInput
                  autoFocus
                  errors={errors}
                  inputProps={{ ...register(`translations.${idx}.text`) }}
                />
              </StyledCellBox>
            ))}
            <StyledCellBox sx={{ width: '150px' }}>
              <IconButton
                onClick={() =>
                  setIsFocused(false)}
                size="small"
                sx={{ width: '10px', height: '10px', marginRight: '15px' }}
              >
                <ClearIcon />
              </IconButton>
              <Button sx={{ fontSize: '14px' }} onClick={handleSubmit(onSubmit)}>Save</Button>
            </StyledCellBox>
          </StyledRowBox>
        )
      }
      {rowSpec.length > 0 && (
        <SpecificationsRows
          open={open}
          toggleCollapse={() => setOpen(!open)}
          row={rowSpec}
          getCategoryInfo={getCategoryInfo}
        />
      )}
    </>
  );
};

export default memo(Row);
