import { memo, useState, SyntheticEvent } from 'react';

import {
  StyledAppBar,
  StyledToolbar,
} from './styled';
import RightPart from './components/RightPart';
import Menu from './components/Menu';
import LeftPart from './components/LeftPart';

interface IAppBarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const AppBar = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
}: IAppBarProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<HTMLElement | null>(null);

  const handleProfileMenuOpen = (event: SyntheticEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>

        <LeftPart
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />

        <RightPart
          menuId={menuId}
          mobileMenuId={mobileMenuId}
          handleMobileMenuOpen={handleMobileMenuOpen}
          handleProfileMenuOpen={handleProfileMenuOpen}
        />

        <Menu
          menuId={menuId}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          setMobileMoreAnchorEl={setMobileMoreAnchorEl}
          handleProfileMenuOpen={handleProfileMenuOpen}
        />
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default memo(AppBar);
