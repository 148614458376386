import { FC, forwardRef, memo, useState } from 'react';

import TableCell, { TableCellProps } from '@mui/material/TableCell';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

import { TableData } from '../../helper';

type CellType = 'input' | 'date' | 'contact';

// TODO: must typed
interface ICustomTableCellProps extends Omit<TableCellProps, 'onChange'> {
  row: TableData;
  name: keyof TableData;
  onChange: (e: any, rowData: TableData) => void;
  handleDatePickerChange?: (date: Date | null, rowData: TableData) => void;
  cellType?: CellType;
}

const CustomTableCell: FC<ICustomTableCellProps> = forwardRef(
  (
    {
      row,
      name,
      onChange,
      cellType,
      handleDatePickerChange,
      children,
      ...restProps
    },
    ref,
  ) => {
    const { isEditMode } = row;
    const [dateValue, setDateValue] = useState<null | string>(
      row.createdAt,
    );

    const renderTextField = (type?: CellType) => {
      switch (type) {
        case 'date': {
          return (
            <DesktopDatePicker
              inputFormat="yyyy/MM/dd"
              value={dateValue}
              onChange={(date: null) => {
                setDateValue(date);
                handleDatePickerChange?.(date, row);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          );
        }

        case 'contact': {
          return (
            <Input
              name={name}
              type="email"
              value={row[name]}
              onChange={(e) => onChange(e, row)}
              sx={{ width: '188px' }}
            />
          );
        }

        default:
          return (
            <Input
              name={name}
              value={row[name]}
              onChange={(e) => onChange(e, row)}
            />
          );
      }
    };

    return (
      <TableCell
        ref={ref}
        sx={{ padding: '0 20px' }}
        align="left"
        {...restProps}
      >
        {isEditMode ? <>{renderTextField(cellType)}</> : children || row[name]}
      </TableCell>
    );
  },
);

export default memo(CustomTableCell);
