import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { COLORS } from '../../../types/global/theme/colors';

export const StyledLabel = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',

  '.MuiTypography-root': {
    fontWeight: 550,
    fontSize: '14px',
    color: COLORS.black,
  },
}));
