import { LanguageEnum } from '../../../../types/enums/translations';

export const initialPolicyState = [
  { lang: LanguageEnum.en, text: '', paragraph: 'paragraph' },
  { lang: LanguageEnum.hy, text: '', paragraph: 'paragraph' },
  { lang: LanguageEnum.ru, text: '', paragraph: 'paragraph' },
];

export const toolbars = 'undo redo | styles |' +
  'bold italic underline | alignleft aligncenter alignright alignjustify | ' +
  'bullist numlist outdent indent | link | preview | ' +
  'forecolor backcolor';

export const EditorInitValue = {
  height: 350,
  menubar: false,
};

export const apiKey = 'p7yeoygfe8ux6ipcck0f4xo5pqhv218rdclj7clp6iixa3f8';

export const flagsList = [
  {
    text: 'English',
    code: 'us',
  },
  {
    text: 'Armenian',
    code: 'am',
  },
  {
    text: 'Russian',
    code: 'ru',
  },
];
