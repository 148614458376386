import { BannerActionPayloadType, IBannerState, BANNER_ACTIONS } from '../types/banners';

export const initialState = (): IBannerState => ({
  loading: false,
  error: '',
  data: [],
});

const SET_ALL_HOME_BANNER_DATA = (state: IBannerState, payload: IBannerState['data']) => ({
  ...state,
  loading: false,
  data: payload,
});

const SET_BANNER_LOADING = (state: IBannerState, payload: IBannerState['loading']) => ({
  ...state,
  loading: payload,
});

export default (
  state: IBannerState = initialState(),
  action: BannerActionPayloadType,
): IBannerState => {
  switch (action.type) {
    case BANNER_ACTIONS.SET_ALL_HOME_BANNER_DATA:
      return SET_ALL_HOME_BANNER_DATA(state, action.payload);

    case BANNER_ACTIONS.SET_BANNER_LOADING:
      return SET_BANNER_LOADING(state, action.payload);

    default:
      return state;
  }
};
