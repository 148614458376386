import { memo } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LensIcon from '@mui/icons-material/Lens';

import { StyledBox, StyledListItem, StyledListItemButton, StyledListItemText, TitleTypography } from './styled';
import { ISingleOrderCore } from '../../../types/orders';
import { currencyParser } from '../../../utils/helpers';

interface DownloadPageProps {
    data: ISingleOrderCore;
}

const DownloadPage = ({ data }: DownloadPageProps) => {
  const { id, shippingMethod, orderNote, total, shops, buyer, shippingAddress } = data;

  return (
    <>
      <StyledBox sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <List>
          <StyledListItem disablePadding>
            <StyledListItemButton>
              <StyledListItemText primary="Order ID:" />
              <Typography>
                { `${id},`}
              </Typography>
            </StyledListItemButton>
            <StyledListItemButton>
              <StyledListItemText primary="Shiping method:" />
              <Typography>{ `${shippingMethod},`}</Typography>
            </StyledListItemButton>
            {orderNote && (
              <StyledListItemButton>
                <StyledListItemText primary="Order Note:" />
                <Typography>
                  { `${orderNote},`}
                </Typography>
              </StyledListItemButton>
            )}
            <StyledListItemButton>
              <StyledListItemText primary="Total:" />
              <Typography>
                { `${currencyParser(total)},`}
              </Typography>
            </StyledListItemButton>
          </StyledListItem>
          <Typography variant="h3" padding={2}>Shops</Typography>

        </List>
        <List>
          {
            shops?.map(({ id: shopId, name, address, contactPhone, orderItems }, idx) => (
              <Box key={shopId}>
                <StyledListItem disablePadding>
                  <ListItemButton>
                    <Typography variant="h1" padding={1}>{`${idx + 1}.`}</Typography>
                    <Typography variant="h1" padding={1}>{name}</Typography>
                  </ListItemButton>

                  <Divider />
                  <StyledListItemButton>
                    <StyledListItemText primary="- Phone" />
                    <Typography>
                      { `${contactPhone},`}
                    </Typography>
                  </StyledListItemButton>
                  <StyledListItemButton>
                    <StyledListItemText primary="- Address" />
                    <Typography>
                      {`${address?.street}, 
                      ${address?.apartment ? `apt ${address?.apartment},` : ''} 
                      ${address?.city},  ${address?.country},`}
                    </Typography>
                  </StyledListItemButton>
                  <StyledListItemButton>
                    <StyledListItemText primary="- Postal Code" />
                    <Typography>
                      { `${address?.postalCode}, `}
                    </Typography>
                  </StyledListItemButton>
                  <StyledListItemButton sx={{ width: '100%' }}>
                    <StyledListItemText sx={{ width: '200px' }} primary="- Details " />
                    <Box
                      sx={{
                        width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
                    >
                      {
                        orderItems.map((order) => {
                          return (
                            <Box key={order.id} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                              <LensIcon sx={{ fontSize: '14px', margin: '12px' }} />
                              <List
                                sx={{
                                  width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                }}
                              >
                                <Typography>
                                  { `${order.name},`}
                                </Typography>
                                { order?.variant?.option1 && (
                                <Typography>
                                  { `${order?.variant?.option1?.option?.name}: ${order?.variant?.option1?.value},`}
                                </Typography>
                                )}

                                { order?.variant?.option2 && (
                                <Typography>
                                  { `${order?.variant?.option2?.option?.name}: ${order?.variant?.option2?.value},`}
                                </Typography>
                                )}

                                <Typography>
                                  { `Quantity: ${order.quantity}, Price: ${currencyParser(order.price)},`}
                                </Typography>
                              </List>
                            </Box>
                          );
                        })
                    }
                    </Box>

                  </StyledListItemButton>
                </StyledListItem>
                {(shops?.length && idx !== shops.length - 1) && <Divider />}
              </Box>

            ))
           }
        </List>
        <TitleTypography variant="h1" padding={2}>Buyer</TitleTypography>
        <List>
          <StyledListItem disablePadding>
            <StyledListItemButton>
              <StyledListItemText primary="Buyer Name:" />
              <Typography>
                { `${buyer.firstName} ${buyer.lastName || ''},`}
              </Typography>
            </StyledListItemButton>
            <StyledListItemButton>
              <StyledListItemText primary="Phone number:" />
              <Typography>{ `${data.phone || '--'},`}</Typography>
            </StyledListItemButton>
            <StyledListItemButton>
              <StyledListItemText primary="Shipping Address:" />
              <Typography>
                {`${shippingAddress?.street}, ${shippingAddress?.apartment ? `apt ${shippingAddress?.apartment},` : ''} 
                 ${shippingAddress?.city}, ${shippingAddress?.country},`}
              </Typography>
            </StyledListItemButton>
            <StyledListItemButton>
              <StyledListItemText primary="Postal Code" />
              <Typography>
                { `${shippingAddress?.postalCode}, `}
              </Typography>
            </StyledListItemButton>

          </StyledListItem>
        </List>
      </StyledBox>

    </>
  );
};

export default memo(DownloadPage);
