import { memo, MouseEvent } from 'react';

import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { headCells, TableData } from '../helper';
import { Order } from '../../../../utils/types';
import { StyledHeadCell, StyledTableHead } from '../../../../common/components/Table/styled';

interface EnhancedTableProps {
  orderBy?: string;
  order: Order;
  onRequestSort?: (
    event: MouseEvent<unknown>,
    property: keyof TableData
  ) => void;
}

const TableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof TableData) => (event: MouseEvent<unknown>) => {
      onRequestSort?.(event, property);
    };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return headCell.isSortable ? (
            <TableCell
              size="small"
              sx={{
                whiteSpace: 'nowrap',
                height: '64px',
                padding: 0,
                ...headCell.styles,
              }}
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ display: 'none' }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <StyledHeadCell
              size="small"
              sx={{ ...headCell.styles }}
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </StyledHeadCell>
          );
        })}
        <TableCell />
      </TableRow>
    </StyledTableHead>
  );
};

export default memo(TableHead);
