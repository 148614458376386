import {
  SpecificationCreatePayload, SpecificationUpdatePayload,
} from '../models/category-model';
import { http } from '../services/RequestService';
import { SortPayload } from '../utils/types';

const prefix = '/specifications';

export const createSpecificationApi = async (data: SpecificationCreatePayload) => {
  const response = await http.post<
    SpecificationCreatePayload
    >(`${prefix}`, data);

  return response.data;
};

export const updatSpecificationApi = async (
  id: string,
  data: SpecificationUpdatePayload,
) => {
  const response = await http.put<SpecificationUpdatePayload>(`${prefix}/${id}`, data);

  return response.data;
};

export const specificationDeleteApi = async (id: string | number) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const sortSpecificationsApi = async (data: SortPayload) => {
  const response = await http.patch<SortPayload>(`${prefix}/sort`, data);

  return response;
};
