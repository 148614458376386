import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledDraweContent = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  justifyContent: 'space-between',
}));
