import { BaseAction, PayloadAction } from '../../utils/types';
import { ISetSignInSuccessPayload, ISetUserActionProps, ISignInPayloadProps } from '../../models/auth-model';

export const AUTH_ACTIONS = {
  // Sign In
  ATTEMPT_TO_SIGN_IN: '@AUTH/ATTEMPT_TO_SIGN_IN',
  SET_SIGN_IN_SUCCEED: '@AUTH/SET_SIGN_IN_SUCCEED',
  SET_SIGN_IN_FAILURE: '@AUTH/SET_SIGN_IN_FAILURE',
  SIGN_OUT_REQUEST: '@AUTH/SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: '@AUTH/SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: '@AUTH/SIGN_OUT_FAILURE',

  // Log out
  ATTEMPT_TO_LOG_OUT: '@AUTH/ATTEMPT_TO_LOG_OUT',
  SET_LOG_OUT_SUCCEED: '@AUTH/SET_LOG_OUT_SUCCEED',
  SET_LOG_OUT_FAILURE: '@AUTH/SET_LOG_OUT_FAILURE',

  // set user
  ATTEMPT_TO_GET_USER: '@AUTH/ATTEMPT_TO_GET_USER',
  SET_USER_FAILURE: '@AUTH/SET_USER_FAILURE',
  SET_USER: '@AUTH/SET_USER',
};

/* ACTION CREATORS */
export const attemptToLogOut = (): BaseAction => ({
  type: AUTH_ACTIONS.ATTEMPT_TO_LOG_OUT,
});

export const attemptToSignIn = (payload: ISignInPayloadProps): BaseAction => ({
  type: AUTH_ACTIONS.ATTEMPT_TO_SIGN_IN,
  payload,
});

export const setUsrFailureAction = (payload: any): BaseAction => ({
  type: AUTH_ACTIONS.SET_USER_FAILURE,
  payload,
});

export const attemptToGetUserAction = (): BaseAction => ({
  type: AUTH_ACTIONS.ATTEMPT_TO_GET_USER,
});

export const setUserAction = (payload: ISetUserActionProps): BaseAction<ISetUserActionProps> => ({
  type: AUTH_ACTIONS.SET_USER,
  payload,
});

export const attemptToSignOut = (): BaseAction => ({
  type: AUTH_ACTIONS.SIGN_OUT_REQUEST,
});

export const setSignInSucceed = (payload: ISetSignInSuccessPayload): PayloadAction<ISetSignInSuccessPayload> => ({
  type: AUTH_ACTIONS.SET_SIGN_IN_SUCCEED,
  payload,
});

export const setSignInFailure = (payload: any): BaseAction => ({
  type: AUTH_ACTIONS.SET_SIGN_IN_FAILURE,
  payload,
});
