import type { SerializedStyles } from '@emotion/serialize';

export enum FontFamilyNames {
  CircularStdBook = 'Circular Std Book',
  CircularStdBlack900 = 'Circular Std Black',
  CircularStdBold700 = 'Circular Std Bold',
  CircularStdMedium500 = 'Circular Std Medium',
  RobotoBlack900 = 'Roboto Black',
  RobotoMedium500 = 'Roboto Medium',
  RobotoNormal400 = 'Roboto Normal',
  RobotoBold700 = 'Roboto Bold',
}

type FontFamily = typeof FontFamilyNames;

type Values = FontFamily[keyof FontFamily];

export interface ThemeFont {
  fontFamily: Values[];
  fontFaces: SerializedStyles;
}
