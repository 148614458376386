import { styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const StyledBox = styled(Box)(() => ({
  span: {
    fontSize: '25px',
  },

  p: {
    fontSize: '25px',
  },

}));

export const TitleTypography = styled(Typography)(() => ({
  fontSize: '30px',

}));

export const StyledListItemText = styled(ListItemText)(() => ({
  width: '200px',
  '.MuiTypography-root': {
    fontWeight: 800,
  },
}));

export const StyledListItemButton = styled(ListItemButton)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '.MuiTypography-root': {
    marginRight: '10px',
  },

}));

export const StyledListItem = styled(ListItem)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
}));
