import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  app: {
    height: '100%',
  },
  treeRoot: {
    height: '100%',
    padding: '10px',
  },
  draggingSource: {
    opacity: '0.3',
  },
  placeholderContainer: {
    position: 'relative',
  },
  dropTarget: {
    backgroundColor: '#e8f0fe',
  },
});
