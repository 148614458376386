import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { COLORS } from '../../../../../types/global/theme/colors';

export const StyledRowBox = styled(Box)(() => ({
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${COLORS.grey[800]}`,
  borderTop: `1px solid ${COLORS.grey[800]}`,

  '.MuiIconButton-root': {
    borderRadius: '0',
  },
}));

export const StyledCellBox = styled(Box)(() => ({
  width: '33%',
  display: 'flex',
  alignItemTypes: 'center',
  padding: '10px',
  alignItems: 'center',
  '&:first-of-type': {
    width: '50px',
  },
  '.MuiBox-root': {
    width: '100%',
  },
}));

export const StyledSubRowBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'space-between',

  '.MuiIconButton-root': {
    background: `${COLORS.blue[100]}`,
    borderRadius: '0',
  },
}));
export const StyledSubCellBox = styled(Box)(() => ({
  width: '33%',
  display: 'flex',
  alignItemTypes: 'center',
  padding: '10px',
  alignItems: 'center',
  borderBottom: `1px solid ${COLORS.grey[800]}`,
  background: `${COLORS.blue[100]}`,
  '&:first-of-type': {
    marginLeft: '48px',
  },

  '.MuiBox-root': {
    width: '100%',
  },
}));
