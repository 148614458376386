import * as Yup from 'yup';

import { CarouselBannerModel } from '../../../../../models/banner-card/carousel-card';
import { HomeAllBanner } from '../../../../../models/banner-card/home-all-model';
import { Translation } from '../../../../../types/category';
import { CardSize, CardTypes } from '../../../../../types/enums/banners';
import { LanguageEnum } from '../../../../../types/enums/translations';
import { Shape } from '../../../../../types/global/yup-object-shape';
import { replceSpacingWithUnderscore, sortedCollection } from '../../../../../utils/helpers';

export interface Title {
  size: number;
  text: string;
  textColor: string;
  title: string;
}

export interface SubTitle {
  size: number;
  text: string;
  textColor: string;
  title: string;
  link: string;
}

export interface Banner {
  image: string;
  link:string;
}

export interface FormValues {
  allBanners: {
    numberOfBanners: number;
    intervalInSlides: number;
    items: Banner[];
    translations: Translation[];
  };
}

export const initialTranslationsValue = [
  { lang: LanguageEnum.en, text: '', content: [] },
  { lang: LanguageEnum.hy, text: '', content: [] },
  { lang: LanguageEnum.ru, text: '', content: [] },
];

export const defaultValues: FormValues = {
  allBanners: {
    numberOfBanners: 0,
    intervalInSlides: 0,
    items: [],
    translations: initialTranslationsValue,
  },
};
export const sanitizeCarouselData = (data: FormValues) => {
  const { allBanners } = data;

  const sanitizeTranslations = allBanners.translations.map((item) => {
    const items = item.content.map((content: any, index: number) => {
      return {
        image: content.image,
        // TODO maybe used in future
        // title: { ...allBanners.items[index].title, ...content.title },
        // subTitle: { ...allBanners.items[index].subTitle, ...content.subTitle },
        link: allBanners.items[index].link,
      };
    });

    return {
      lang: item.lang,
      text: item.content[0].image,
      content: {
        intervalInSlides: allBanners.intervalInSlides,
        numberOfBanners: allBanners.numberOfBanners,
        items,
      },
    };
  });

  const result: CarouselBannerModel = {
    title: 'Caurusel',
    type: CardTypes.carousel,
    isActive: true,
    sort: -1,
    key: replceSpacingWithUnderscore(sanitizeTranslations[0].content.items[0].link),
    size: CardSize.row,
    translations: sanitizeTranslations,
  };

  return result;
};

export const makeTranslationsArray = (data: HomeAllBanner) => {
  const sortedTranslations = sortedCollection(data?.translations);

  return sortedTranslations?.map((translation) => {
    const content = translation.content.items.map((item: Banner) => {
      return {
        // TODO maybe used in future
        // title: { ...item.title },
        // subTitle: { ...item.subTitle },
        image: item.image,
        link: item.link,
      };
    });

    return {
      ...translation,
      content,
    };
  });
};

export const validationSchema = Yup.object<Shape<FormValues>>({
  allBanners: Yup.object().shape<Shape<FormValues['allBanners']>>({
    numberOfBanners: Yup.number()
      .typeError('Must be a number')
      .min(1, 'Must be more than 1')
      .max(5, 'Must be less or equal 5'),
    intervalInSlides: Yup.number()
      .typeError('Must be a number')
      .min(1, 'Must be more than 1')
      .max(9, 'Must be less or equal 9'),
    items: Yup.array().of(
      Yup.object({
        link: Yup.string().required('Required'),
      }),
    ),
  }),
});

export const MAX_BANNER_COUNT = 5;
export const MAX_INTERVAL_IN_SLIDES_VALUE = 5;

export const defaultBannerItem = {
  image: '',
  title: {
    size: 0,
    text: '',
    textColor: '',
    title: '',
  },
  subTitle: {
    size: 0,
    text: '',
    textColor: '',
    title: '',
    link: '',
  },
};

export enum IncreaseOrDecrese {
  Up,
  Down,
}
