import * as Yup from 'yup';

import { FullRowBannerModel } from '../../../../../models/banner-card/full-row-model';
import { Translation } from '../../../../../types/category';
import { CardSize, CardTypes } from '../../../../../types/enums/banners';
import { LanguageEnum } from '../../../../../types/enums/translations';
import { Shape } from '../../../../../types/global/yup-object-shape';
import { replceSpacingWithUnderscore } from '../../../../../utils/helpers';

export interface FormValues {
 leftSection: {
  image: string;
  link: string;
 };
 rightSection: {
  image: string;
  link: string;
 };
  translations: Translation[];
}

const initialProperties = {
  title: '',
  description: '',
};

const initialContent = {
  leftSection: initialProperties,
  rightSection: initialProperties,
};

export const defaultValues: FormValues = {
  leftSection: {
    image: '',
    link: '',
  },
  rightSection: {
    image: '',
    link: '',
  },
  translations: [
    { lang: LanguageEnum.en, text: '', content: initialContent },
    { lang: LanguageEnum.hy, text: '', content: initialContent },
    { lang: LanguageEnum.ru, text: '', content: initialContent },
  ],
};

export const sanitizeFullrowData = (data: FormValues) => {
  const { leftSection, rightSection, translations } = data;

  const sanitizeTranslations = translations.map((item) => {
    return {
      lang: item.lang,
      text: item.content.leftSection.title,
      content: {
        leftSection: { ...item.content.leftSection, ...leftSection },
        rightSection: { ...item.content.rightSection, ...rightSection },
      },
    };
  });

  const result: FullRowBannerModel = {
    isActive: true,
    size: CardSize.row,
    title: 'Fullrow banner',
    type: CardTypes.fullRow,
    key: replceSpacingWithUnderscore(sanitizeTranslations[0].text),
    translations: sanitizeTranslations,
    sort: -1,
  };

  return result;
};

export const validationSchema = Yup.object<Shape<FormValues>>({
  leftSection: Yup.object().shape<Shape<FormValues['leftSection']>>({
    image: Yup.string().required('Required'),
    link: Yup.string().url('Link must be a valid URL').required('Required'),
  }),
  rightSection: Yup.object().shape<Shape<FormValues['rightSection']>>({
    image: Yup.string().required('Required'),
    link: Yup.string().url('Link must be a valid URL').required('Required'),
  }),
  translations: Yup.array().of(
    Yup.object({
      content: Yup.object({
        leftSection: Yup.object({
          title: Yup.string().required('Required'),
          description: Yup.string().required('Required'),
        }),
        rightSection: Yup.object({
          title: Yup.string().required('Required'),
          description: Yup.string().required('Required'),
        }),
      }),
    }),
  ),
});
