export const addressInitialValue = {
  street: '',
  country: '',
  city: '',
  id: '',
  lat: '',
  userId: '',
  state: '',
  apartment: '',
  postalCode: '',
  isDefault: false,
  lot: '',
  createdAt: '',
  updatedAt: '',
};
