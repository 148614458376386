import { MouseEvent, memo } from 'react';

import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';

import { TableData, headCells } from '../helper';
import { Order } from '../../../../utils/types';
import { StyledHeadCell, StyledTableHead, TableSortedCell } from '../../../../common/components/Table/styled';

interface EnhancedTableProps {
  orderBy?: string;
  order: Order;
  onRequestSort?: (
    event: MouseEvent<unknown>,
    property: keyof TableData
  ) => void;
  isDialogContent: boolean;
}

const TableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort, isDialogContent } = props;

  const createSortHandler =
    (property: keyof TableData) => (event: MouseEvent<unknown>) => {
      onRequestSort?.(event, property);
    };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return isDialogContent && headCell.id === 'payment' ? null : (
            headCell.isSortabe && !isDialogContent ? (
              <TableSortedCell
                size="small"
                sx={{ ...headCell.styles }}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label(isDialogContent)}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={{ display: 'none' }}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableSortedCell>
            ) : (
              <StyledHeadCell
                size="small"
                sx={{ ...headCell.styles }}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label(isDialogContent)}
              </StyledHeadCell>
            ));
        })}

        {isDialogContent && (
        <StyledHeadCell
          size="small"
          align="left"
        >
          Returned
        </StyledHeadCell>
        )}
      </TableRow>
    </StyledTableHead>
  );
};

export default memo(TableHead);
