import { AxiosResponse } from 'axios';

import {
  PolicyPostType,
} from '../models/privacy-model';
import { http } from '../services/RequestService';
import { IPrivacyCore } from '../types/privacy';
import { ResponseData } from '../utils/types';

const prefix = '/policies';

export const getAllPolicyApi = async () => {
  const response = await http.get<ResponseData<IPrivacyCore[]>>(prefix);

  return response.data;
};

export const getPolicyByType = async (type: string) => {
  const response = await http.get<ResponseData<IPrivacyCore[]>>(`${prefix}/${type}`);

  return response.data;
};

export const postPolicyByTypeApi = async (data: PolicyPostType) => {
  const response = await http.post<
    PolicyPostType,
    AxiosResponse<IPrivacyCore>
    >(`${prefix}`, data);

  return response.data;
};

export const updatePolicyByIdApi = async (
  id: string,
  data: PolicyPostType,
) => {
  const response = await http.put<
    PolicyPostType,
    AxiosResponse<IPrivacyCore>
  >(`${prefix}/${id}`, data);

  return response.data;
};

export const policyDeleteApi = async (id: string) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};
