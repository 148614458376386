import { memo, useState } from 'react';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import {
  DragDropContext, Droppable,
  Draggable, DroppableProvided,
} from 'react-beautiful-dnd';

import { StyledFragment, StyledTitleBox } from './styled';
import {
  StyledTabBox,
  StyledTabsChip,
  StyledTabsContent,
} from '../../../components/StyledTabs';
import { CreateBannerLabelEnum, createBanners } from '../../helper';
import {
  selectAllHomeBanner,
  selectIsLoadingBanner,
} from '../../../../store/selectors/banners';
import useMount from '../../../../hooks/useMount';
import { attemptToGetAllBannersAction } from '../../../../store/actions/banners';
import {
  renderActiveCreateBanner,
  renderHomeCardsByType,
} from './helper';
import { homeBannerCardsSortApi } from '../../../../requests/home-banner-card';

const BannersHolder = () => {
  useMount(() => {
    dispatch(attemptToGetAllBannersAction());
  });

  const { allHomeBanneData } = useSelector(selectAllHomeBanner);
  const { isLoadingBanner } = useSelector(selectIsLoadingBanner);

  const dispatch = useDispatch();

  const [activeBanner, setActiveBanner] = useState<CreateBannerLabelEnum>();

  const handleClick = (title: CreateBannerLabelEnum) => {
    setActiveBanner(title);
  };

  const onDragEnd = (result:any) => {
    const [removed] = allHomeBanneData.splice(result.source.index, 1);

    allHomeBanneData.splice(result.destination.index, 0, removed);

    const sortedData = allHomeBanneData.map(({ id }, index) => ({ id, sort: index }));

    homeBannerCardsSortApi({ data: sortedData });
  };

  return (
    <>
      <StyledTitleBox>
        <Typography variant="h2" component="h2">
          Create
        </Typography>
      </StyledTitleBox>

      <StyledTabsContent>
        {createBanners.map(({ title }) => (
          <StyledTabsChip
            key={title}
            label={(
              <Typography variant="h2" component="span">
                {title}
              </Typography>
            )}
            className={activeBanner === title ? 'active' : ''}
            variant="outlined"
            onClick={() => handleClick(title)}
          />
        ))}
      </StyledTabsContent>

      <StyledTabBox>
        {renderActiveCreateBanner(activeBanner)}
      </StyledTabBox>

      <Stack spacing="30px">
        <Typography variant="h3" component="h2">
          Current
        </Typography>
        <Box>
          {isLoadingBanner ? (
            <CircularProgress />
          ) : (
            <>
              {allHomeBanneData && allHomeBanneData.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(providedDroppable:DroppableProvided) => (
                      <Box {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                        {allHomeBanneData.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(providedDraggable, snapshot) => (
                              <Box>
                                <StyledFragment
                                  key={item.id}
                                  ref={providedDraggable.innerRef}
                                  data-snapshot={snapshot}
                                  {...providedDraggable.draggableProps}
                                  {...providedDraggable.dragHandleProps}
                                >
                                  {renderHomeCardsByType({
                                    bannerData: item,
                                    bannerType: item.type,
                                  })}
                                </StyledFragment>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                      </Box>

                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <Typography variant="h5" component="h2">
                  Not found
                </Typography>
              )}

            </>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default memo(BannersHolder);
