import { memo, ReactNode } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect, {
  SelectProps,
  SelectChangeEvent,
} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { UseFormSetValue } from 'react-hook-form';

import ErrorMessage from '../ErrorMessage';

interface ISelectProps extends SelectProps {
  id: string;
  errors?: any;
  value: string;
  label?: string;
  options?: string[];
  customMessageText?: ReactNode;
  setValue?: UseFormSetValue<any>;
}

const Select = ({
  id,
  name,
  value,
  label,
  errors,
  options,
  setValue,
  onChange,
  customMessageText,
  ...restProps
}: ISelectProps) => {
  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const { value: targetValue } = event.target;

    if (name) {
      setValue?.(name, targetValue);
    }
  };

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        id={id}
        labelId={id}
        label={label}
        value={value}
        onChange={(e) => handleSelectChange(e)}
        {...restProps}
      >
        {options &&
          options.length > 0 &&
          options.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
      </MuiSelect>

      {errors && name && (
        <ErrorMessage
          errors={errors}
          name={name}
        />
      )}
    </FormControl>
  );
};

export default memo(Select);
