import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';

import { DepthEnum } from './DragAndDrop/helpers';

interface TreeListInterface {
    id: string | number;
    parentId: string | number | null;
}
export type AdditionalDataType= {
  depth: number;
  hasChild: boolean;
  isActive: boolean;
};

// Todo: with forEach doesn't work

// export const sortTreeView = (treeList: TreeListInterface[]): TreeListInterface[] => {
//   const newCategoriesList = treeList.filter((item) => !item.parentId);
//   const children = treeList.filter((item) => item.parentId);

//   newCategoriesList.forEach((category, idx) => {
//     const subCategories = children.filter((item) => item.parentId === category.id);

//     newCategoriesList.splice(idx + 1, 0, ...subCategories);
//   });

//   console.log('newCategoriesList', newCategoriesList);

//   return newCategoriesList;
// };

export const sortTreeViewList = (treeList: NodeModel<AdditionalDataType>[]): NodeModel<AdditionalDataType>[] => {
  const newCategoriesList = treeList.filter((item) => !item.parent);
  const children = treeList.filter((item) => item.parent);

  /* eslint-disable no-plusplus */
  for (let i = 0; i < newCategoriesList.length; i++) {
    const subCategories = children.filter((item) => item.parent === newCategoriesList[i].id);

    newCategoriesList.splice(i + 1, 0, ...subCategories);
  }

  return newCategoriesList;
};

export const filterTreeView = (
  treeData: NodeModel<AdditionalDataType>[],
  dropTargetId: NodeModel['id'],
): TreeListInterface[] => {
  const filteredParentsList = treeData.filter((tree) => tree.parent === dropTargetId);

  return filteredParentsList.map(({ parent, id }) => ({
    id,
    parentId: parent || null,
  }));
};

export const canDragAndCrop = ({ dragSource, dropTarget, dropTargetId }:DropOptions<AdditionalDataType>):boolean => {
  const dropTargetDepth = dropTarget?.data?.depth || 0;
  const dropTargetStatus = dropTarget?.data?.isActive;
  const dragSourceDepth = dragSource?.data?.depth;
  const dragSourceHasChild = dragSource?.data?.hasChild;
  const isEqualToParent = dropTargetId === dragSource?.parent;
  const isSecandLevel = dragSourceDepth === DepthEnum.level2 && dropTargetDepth !== DepthEnum.level1;
  const checkPosibleCases = !dropTarget || dragSourceDepth === DepthEnum.level1 || isSecandLevel;

  if ((!dropTargetStatus && dropTargetStatus !== undefined)
    || (dropTargetDepth === DepthEnum.level3)
    || (dragSourceDepth === DepthEnum.level3 && dropTargetDepth !== DepthEnum.level2)
    || (!isEqualToParent && dragSourceHasChild && checkPosibleCases)) {
    return false;
  }

  return true;
};
