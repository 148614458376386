import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledTreeView = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,

  li: {
    listStyleType: 'none',
  },
}));
