import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)`
  .tox {
    margin-bottom: 40px;
  }

  .tox-statusbar {
    display: none !important;
  }
  .tox-statusbar__branding {
    display: none !important;
  }
`;
