import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledPlaceholder = styled(Box)(() => ({
  background: '#1967d2',
  height: '2px',
  position: 'absolute',
  right: '0',
  top: '0',
  transform: 'translateY(-50%)',
}));
