import { memo, Fragment } from 'react';

import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { ReactComponent as AgoraLogo } from '../../../assets/svgs/agora-logo.svg';
import NavLink from '../NavLink';
import { PAGE_ROUTES_PRIVATE } from '../../../types/enums/routes';
import { StyledDraweContent } from './styled';

const routeData = [
  {
    to: PAGE_ROUTES_PRIVATE.HOME,
    text: 'Home',
    icon: <DashboardIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.USERS,
    text: 'Users',
    icon: <SettingsIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.SHOPS,
    text: 'Shops',
    icon: <FolderIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.ALL_PRODUCTS,
    text: 'All Products',
    icon: <FolderIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.ORDERS,
    text: 'Orders',
    icon: <FolderIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.CATEGORY,
    text: 'Category',
    icon: <FolderIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.PRIVACY_POLICY,
    text: 'Privacy Policy',
    icon: <FolderIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.BUSINESS_TYPES,
    text: 'Business Types',
    icon: <FolderIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.COLLECTION,
    text: 'Collection',
    icon: <FolderIcon />,
  },
  {
    to: PAGE_ROUTES_PRIVATE.RETURNS,
    text: 'Returns',
    icon: <FolderIcon />,
  },
];

interface IDrawerProps {
  drawerWidth: number;
  open: boolean;
}

const Drawer = ({ drawerWidth, open }: IDrawerProps) => {
  const theme = useTheme();

  return (
    <MuiDrawer
      variant="persistent"
      open={open}
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />

      <StyledDraweContent>
        <List>
          <ListItem key={0}>
            <ListItemIcon>
              <AgoraLogo fill={theme.palette.primary.main} />
            </ListItemIcon>
          </ListItem>

          <Divider />

          {routeData.map(({ text, to, icon }) => (
            <Fragment key={text}>
              <ListItem button component={NavLink} to={to}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>

        <Box>
          <Divider />

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            disableRipple
          >
            <LogoutIcon sx={{ marginRight: '34px' }} />

            <Typography variant="h5" color="text.primary">
              Log Out
            </Typography>
          </IconButton>
        </Box>

      </StyledDraweContent>
    </MuiDrawer>
  );
};

export default memo(Drawer);
