import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Dialog from '../../../common/components/Dialog';
import { COLORS } from '../../../types/global/theme/colors';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    maxWidth: '1600px !important',
    padding: '0 30px',
  },
}));

export const StyledDialogTop = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '32px',
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: '580px !important',
  background: theme.palette.grey['200'],
  border: `1px solid ${theme.palette.grey['500']}`,
  borderRadius: '4px',
  padding: '20px !important',
  marginRight: '30px',
}));

export const StyledContentPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `1px solid ${COLORS.grey['700']}`,
  borderRadius: '6px',
  padding: '20px !important',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '20px',
}));
