import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';

import { FontFamilyNames } from '../../../../../../types/global/theme/fonts';

export const StyledTableHead = styled(TableHead)`
  .MuiTableRow-root {
    .MuiTableCell-root {
      span {
        font-family: ${FontFamilyNames.RobotoMedium500};
        font-size: 14px;
      }
    }
  }
`;
