import * as yup from 'yup';

import { RuningLineModel } from '../../../../../models/banner-card/runing-line-model';
import { Translation } from '../../../../../types/category';
import { CardSize, CardTypes } from '../../../../../types/enums/banners';
import { replceSpacingWithUnderscore } from '../../../../../utils/helpers';
import { addTranslationsContent } from '../../../helper';

export type FormValues = {
  bgColor: string;
  textColor: string;
  translations: Translation[];
};

export const validationSchema = yup.object({
  bgColor: yup.string().required('Required'),
  textColor: yup.string().required('Required'),
  translations: yup.array().of(
    yup.object({
      text: yup.string().required('Required'),
    }),
  ),
});

export const sanitizeRuningLineData = (data: FormValues) => {
  const { bgColor, textColor, translations } = data;
  const content = {
    color: bgColor,
    textColor,
  };

  const payloadData: RuningLineModel = {
    type: CardTypes.runningLine,
    size: CardSize.row,
    isActive: true,
    key: replceSpacingWithUnderscore(translations[0].text),
    translations: addTranslationsContent(translations, content),
    sort: -1,
  };

  return payloadData;
};
