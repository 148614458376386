export enum PAGE_ROUTES_PRIVATE {
  HOME = '/home',
  ROOT_HOME = '/',
  USERS = '/users',
  ORDERS = '/orders',
  CATEGORY = '/category',
  ALL_PRODUCTS = '/products',
  SPECIFICATIONS= '/category/:id',
  PRIVACY_POLICY = '/privacy',
  BUSINESS_TYPES = '/business-types',
  COLLECTION = '/collection',
  NEW_COLLECTION = '/collection/new-collection',
  RETURNS = '/returns',
  SHOPS = '/shops'
}

export enum PAGE_ROUTES_PUBLIC {
  ROOT_SIGN_IN = '/',
  SIGN_IN = '/sign-in',
  FORGOT_PASSWORD = '/forgot-password',
}
