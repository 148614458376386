import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';

import Button from '../../../../common/components/Button';
import { COLORS } from '../../../../types/global/theme/colors';

export const StyledTableBody = styled(TableBody)(() => ({
  '.MuiTableCell-root': {
    fontSize: '14px',
  },

  '.MuiTableRow-root': {
    '&.isEditable': {
      background: COLORS.alpha.denim008,
    },
  },

  '.MuiTableRow-hover': {
    '&:hover ': {
      background: COLORS.alpha.denim008,
    },
  },
}));

export const MoreBox = styled(Box)(() => ({
  width: '185px',
  padding: '14px 16px',
  cursor: 'pointer',
  userSelect: 'none',
}));

export const StatusButton = styled(Button)<{text_color: string}>(({ text_color }) => ({
  color: COLORS.white,
  background: text_color,

  '&:hover': {
    background: text_color,
  },
}));

export const StatusChangerButton = styled(Box)(({ theme }) => ({
  padding: '14px 16px',
  cursor: 'pointer',

  // TODO: Delete in future
  '&:nth-of-type(4n)': {
    '.MuiTypography-root': {
      color: theme.palette.grey[50],
    },
  },

  '&:nth-of-type(3n)': {
    '.MuiTypography-root': {
      color: theme.palette.grey[50],
    },
  },
}));
