import {
  SpecificationGroupCreatePayload, SpecificationGroupUpdatePayload,
} from '../models/category-model';
import { http } from '../services/RequestService';
import { SortPayload } from '../utils/types';

const prefix = '/specificationGroups';

export const createSpecificationGroupApi = async (data: SpecificationGroupCreatePayload) => {
  const response = await http.post<
    SpecificationGroupCreatePayload
    >(`${prefix}`, data);

  return response.data;
};

export const updatSpecificationGroupApi = async (
  id: string,
  data: SpecificationGroupUpdatePayload,
) => {
  const response = await http.put<SpecificationGroupUpdatePayload>(`${prefix}/${id}`, data);

  return response.data;
};

export const specificationGroupDeleteApi = async (id: string | number) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const sortSpecificationGroupApi = async (data: SortPayload) => {
  const response = await http.patch<SortPayload>(`${prefix}/sort`, data);

  return response;
};
