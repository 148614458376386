import { memo, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Flag from 'react-world-flags';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';

import UploadContainer from '../../../../UploadContainer';
import BaseInput from '../../../../../../../common/components/Input/BaseInput';
import { FormValues } from '../../helper';

const RightSection = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();

  const rightPartImageValue = watch('rightSection.image');

  const [rightPartImagePreview, setRightPartImagePreview] =
    useState<string>(rightPartImageValue);

  return (
    <Box>
      <Typography component="p" variant="h5">
        Right Section
      </Typography>

      <Stack spacing="18px">
        <Stack spacing="8px">
          <UploadContainer
            label="Image"
            errors={errors}
            setValue={setValue}
            inputName="rightSection.image"
            id="right-part-full-row-banner"
            imagePreivew={rightPartImagePreview}
            setImagePreview={setRightPartImagePreview}
          />
        </Stack>

        <Stack spacing="8px">
          <BaseInput
            errors={errors}
            label="Title"
            inputProps={{ ...register(`translations.${0}.content.rightSection.title`) }}
            placeholder="Title"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="us" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${1}.content.rightSection.title`) }}
            placeholder="Title"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="am" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${2}.content.rightSection.title`) }}
            placeholder="Title"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="ru" height="22" width="22" />
              </InputAdornment>
          )}
          />
        </Stack>

        <Stack spacing="8px">
          <BaseInput
            errors={errors}
            label="Description"
            inputProps={{ ...register(`translations.${0}.content.rightSection.description`) }}
            placeholder="Description"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="us" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${1}.content.rightSection.description`) }}
            placeholder="Description"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="am" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${2}.content.rightSection.description`) }}
            placeholder="Description"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="ru" height="22" width="22" />
              </InputAdornment>
          )}
          />
        </Stack>

        <Stack spacing="8px">
          <BaseInput
            label="Link"
            errors={errors}
            inputProps={{
              placeholder: 'Link',
              ...register('rightSection.link'),
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(RightSection);
