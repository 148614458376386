import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';

import { COLORS } from '../../../../../types/global/theme/colors';

export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-root,
  .MuiTableCell-root {
    font-size: 14px;
  }

  .MuiTableRow-root {
    &.isEditable {
      background: ${COLORS.alpha.denim008};
    }
  }

  .MuiLink-root {
    text-decoration: none;
  }

  .MuiTableRow-hover {
    :hover {
      background: ${COLORS.alpha.denim008};
    }
  }
`;
