import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { DRAWER_WIDTH } from '../../utils/constants';

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const StyledOutletContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1152px',
  marginLeft: 'min(7vw, 128px)',
  marginRight: '20px',
  marginBottom: '20px',

  '&.home': { maxWidth: '1436px' },

  [theme.breakpoints.down('el')]: {
    marginLeft: '20px',
  },
}));
