import { FC } from 'react';

import { Tree, MultiBackend, getBackendOptions, NodeModel, DropOptions } from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import StylesProvider from '@mui/styles/StylesProvider';
import ThemeProvider from '@mui/styles/ThemeProvider';

import CustomNode from './CustomNode/CustomNode';
import Placeholder from './Placeholder/Placeholder';
import CustomDragPreview from './CustomDragPreview/CustomDragPreview';
import theme from '../../../styles/theme';
import { StyledTreeView } from './styled';
import { useStyles } from './classes';
import { AdditionalDataType } from '../helper';

interface DndComponentProps {
  treeData: NodeModel<AdditionalDataType>[];
  deleteHandler: () => void;
  handleDrop: (newTree:NodeModel<AdditionalDataType>[], options: DropOptions<AdditionalDataType>) => void;
  editHandler: () => void;
  addHandler:(parentId: string | number) => void;
}

const DndComponent: FC<DndComponentProps> = ({
  treeData,
  deleteHandler,
  handleDrop,
  editHandler,
  addHandler,
}) => {
  const classes = useStyles();

  return (
    <>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <StyledTreeView>
              <Tree
                tree={treeData}
                rootId={0}
                render={(node, { depth, onToggle, isOpen, hasChild }) => {
                  return (
                    <CustomNode
                      node={node}
                      depth={depth}
                      isOpen={isOpen}
                      onToggle={onToggle}
                      hasChild={hasChild}
                      deleteHandler={deleteHandler}
                      editHandler={editHandler}
                      addHandler={addHandler}
                    />
                  );
                }}
                dragPreviewRender={(monitorProps) => (
                  <CustomDragPreview monitorProps={monitorProps} />
                )}
                onDrop={handleDrop}
                classes={{
                  root: classes.treeRoot,
                  draggingSource: classes.draggingSource,
                  placeholder: classes.placeholderContainer,
                }}
                sort={false}
                canDrop={(_, { dragSource, dropTargetId }) => {
                  if (dragSource?.parent === dropTargetId) {
                    return true;
                  }
                }}
                dropTargetOffset={5}
                placeholderRender={(_, { depth }) => (
                  <Placeholder depth={depth} />
                )}
              />
            </StyledTreeView>
          </DndProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};

export default DndComponent;
