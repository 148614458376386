import { memo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useConfirm } from 'material-ui-confirm';

import { shippingRefundApi } from '../../../requests/orders';
import { StyledButton } from './styles';
import { confirmOptionsDialog } from '../../../common/components/Table/helper';

interface RefundShippingProps {
  id: string;
  shippingCost: number;
}

const RefundShipping = ({ id, shippingCost }: RefundShippingProps) => {
  const [cost, setCost] = useState(shippingCost);
  const confirm = useConfirm();
  const refundShipping = async () => {
    try {
      await confirm(confirmOptionsDialog({ questionText: 'Are you sure you want to refund shipping price?' }));
      await shippingRefundApi(id);
      setCost(0);
    } catch (e) {
      setCost(0);
      throw e;
    }
  };

  return (
    <Stack direction="column" mr={4}>
      <StyledButton variant="contained" color="warning" onClick={refundShipping}>
        Refund shipping
      </StyledButton>
      <Typography>
        {`Shipping cost: ${cost} AMD`}
      </Typography>
    </Stack>
  );
};

export default memo(RefundShipping);
