import { CSSProperties } from 'react';

export interface TableData {
  photo: string;
  id: string;
  name: string;
  date: string;
  price: number;
  shop: string;
  isActive: boolean;
  featured: string;
}

export interface TableHeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
  styles: CSSProperties;
  isSortable?: boolean;
}

export const headCells: readonly TableHeadCell[] = [
  {
    id: 'photo',
    numeric: false,
    disablePadding: true,
    label: 'Photo',
    styles: {
      width: '60px',
      paddingLeft: '16px',
    },
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
    styles: {
      width: '180px',
    },
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Item Name',
    styles: {
      width: '178px',
    },
    isSortable: true,
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
    styles: {
      width: '131px',
    },
    isSortable: true,
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date & Time',
    styles: {
      width: '91px',
    },
    isSortable: true,
  },
  {
    id: 'shop',
    numeric: true,
    disablePadding: false,
    label: 'Shop',
    styles: {
      width: '136px',
    },
    isSortable: true,
  },
  {
    id: 'featured',
    numeric: true,
    disablePadding: false,
    label: 'Featured',
    styles: {
      width: '40px',
    },
    isSortable: true,
  },
];
