import { Typography } from '@mui/material';

interface IPageTitleProps {
  title: string;
}

const PageTitle = ({ title }: IPageTitleProps) => {
  return (
    <Typography lineHeight="32px" variant="h4" component="h2">
      {title}
    </Typography>
  );
};

export default PageTitle;
