import { CSSProperties } from 'react';

import { IOrdersCore, ISingleOrderCore, ORDER_STATUS, PAYMENT_STATUS, Variant } from '../../../types/orders';
import { dateFormatter } from '../../../utils/helpers';

export interface TableData {
  id: string;
  photo: string;
  order: string;
  date: string;
  seller: string;
  buyer?: string;
  status: keyof typeof ORDER_STATUS;
  name: string;
  price: number;
  quantity: number;
  currency?: string;
  isReturned?: boolean;
  payment?: string;
  firstName: string;
  variant?: Variant;
  paymentStatus: keyof typeof PAYMENT_STATUS;
  listingId: string;
  returned?: string;
  isQtProblem?: boolean;
}

export interface TableHeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: ((isDialogContent: boolean) => string) | (() => string);
  styles: CSSProperties;
  isSortabe?: boolean;
}

export const headCells: readonly TableHeadCell[] = [
  {
    id: 'photo',
    disablePadding: true,
    label: () => 'Photo',
    styles: {
      width: '100px',
      paddingLeft: '16px',
    },
  },
  {
    id: 'order',
    disablePadding: false,
    label: () => 'Order',
    styles: {
      width: '135px',
    },
  },
  {
    id: 'date',
    disablePadding: false,
    label: () => 'Date and Time',
    styles: {
      width: '178px',
    },
    isSortabe: true,
  },
  {
    id: 'seller',
    disablePadding: false,
    label: () => 'Seller',
    styles: {
      width: '131px',
    },
    isSortabe: true,
  },
  {
    id: 'buyer',
    disablePadding: false,
    label: () => 'Buyer',
    styles: {
      width: '140px',
    },
    isSortabe: true,
  },
  {
    id: 'payment',
    disablePadding: false,
    label: () => 'Payment',
    styles: {
      width: '140px',
    },
    isSortabe: true,
  },
  {
    id: 'status',
    disablePadding: false,
    label: () => 'Status',
    styles: {
      width: '140px',
    },
    isSortabe: true,
  },
  {
    id: 'name',
    disablePadding: false,
    label: (isDialogContent) => (isDialogContent ? 'Name & Variant' : 'Name'),
    styles: {
      width: '180px',
    },
  },
  {
    id: 'price',
    disablePadding: false,
    label: (isDialogContent) => (isDialogContent ? 'Price' : 'Total price'),
    styles: {
      width: '136px',
    },
  },
  {
    id: 'quantity',
    disablePadding: false,
    label: () => 'Quantity',
    styles: {
      width: '136px',
    },
  },
];

export const sanitizeAllOrders = (data: IOrdersCore[]): TableData[] => {
  return data.map(({ id, buyer, status, total, items, createdAt, paymentStatus }) => {
    const totalQuantity = items.reduce((sum, item) => sum + item.quantity, 0);

    return {
      id,
      firstName: buyer.firstName,
      photo: items.length === 1 ? items[0]?.image : '',
      order: id,
      date: dateFormatter(createdAt),
      seller: items.length === 1 ? items[0]?.shop?.name : '',
      status,
      name: items.length === 1 ? items[0]?.name : '',
      price: total,
      quantity: totalQuantity,
      listing: items[0]?.listing,
      currency: items[0]?.currency,
      paymentStatus,
      listingId: items.length === 1 ? items[0]?.listingId : '',
    };
  });
};

export const sanitizeSingleOrder = (data: ISingleOrderCore):TableData[] => {
  const { buyer, items, paymentStatus } = data;

  return items.map(({
    id, createdAt, price, image, quantity, currency, name, isReturned, status, shop, variant, listingId, isQtProblem,
  }) => ({
    id,
    firstName: buyer.firstName,
    photo: image,
    date: dateFormatter(createdAt),
    seller: shop.name,
    name,
    price,
    isReturned,
    status,
    order: id,
    quantity,
    currency,
    variant,
    paymentStatus,
    listingId,
    isQtProblem,
  }));
};
