import { memo } from 'react';

interface MapProps {
    lat: string;
    lot: string;
}

const Map = ({ lat, lot }: MapProps) => (
  <iframe
    title="address"
    width="315"
    height="315"
    loading="lazy"
    allowFullScreen
    src={`//maps.google.com/maps?q=${lat},${lot}&z=17&output=embed`}
  />
);

export default memo(Map);
