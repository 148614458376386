import {
  memo,
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
} from 'react';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import PageTitle from '../../common/components/PageTitle';
import FilterMenu from './components/FilterMenu';
import SearchField from '../../common/components/SearchField';
import {
  userFilterApi,
  updateUserByIdApi,
  userDeleteApi,
  searchUsersApi,
} from '../../requests/user';
import Table from './components/Table/index';
import { IUserCore, UserFilterMenuEnum } from '../../types/users/index';
import Loader from '../../common/components/Loader/index';
import { Order } from '../../utils/types';
import { TableData, UpdateType } from './components/Table/helper';
import { confirmOptionsDialog } from '../../common/components/Table/helper';
import { StyledContainer } from '../components/StyledContainer';
import { UserFilterPayload, UserSearchPayload } from '../../models/user-model';
import { filterByRole } from './helpers';

const Users = () => {
  const [users, setUsers] = useState<IUserCore[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>();

  const [search, setSearch] = useState('');
  const { page: queryOffset } = queryString.parse(window.location.search);

  const [offset, setOffset] = useState(queryOffset ? +queryOffset : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isEqualQuery = offset === (queryOffset && +queryOffset);
  const [activeFilter, setActiveFilter] = useState<number>(
    UserFilterMenuEnum.ALL,
  );

  const navigate = useNavigate();
  const confirm = useConfirm();

  const getFilteredUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearch('');

      const offsetOrder = offset * rowsPerPage;
      const offSetValue = (offsetOrder <= totalUsers || isEqualQuery) ? offsetOrder : totalUsers;

      const payloadData: UserFilterPayload = {
        limit: rowsPerPage,
        offset: offSetValue,
      };

      if (orderBy) {
        payloadData.orderBy = order.toUpperCase();
        payloadData.sortBy = orderBy;
      }

      const role = filterByRole(activeFilter);

      if (role) {
        payloadData.role = role;
      }

      const { data, meta: { pagination: { total } } } = await userFilterApi(payloadData);

      setUsers(data);
      setTotalUsers(total);
      navigate({
        search: offset ? `?page=${offset}` : '',
      });
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter, offset, rowsPerPage, order, orderBy, navigate, totalUsers]);

  const handleActivateRequest = async ({ id, phone, email }: UpdateType, isActive: boolean) => {
    try {
      await updateUserByIdApi(id, {
        isActive,
        phone: phone || '',
        email,
      });
      getFilteredUsers();
    } catch (e) {
      throw e;
    }
  };

  const handleDeleteRequest = useCallback(
    async (userId: string) => {
      await confirm(confirmOptionsDialog({ questionText: 'Are you sure you want to delete the user?' }));

      const result = await userDeleteApi(userId);

      if (!result) {
        getFilteredUsers();
      }
    },
    [confirm, getFilteredUsers],
  );

  const handleUpdateRequest = useCallback(() => {
    getFilteredUsers();
  }, [getFilteredUsers]);

  const handleSortRequest = useCallback(
    async (ascOrDesc: Order, sort: keyof TableData) => {
      try {
        setIsLoading(true);
        setSearch('');

        const { data, meta: { pagination: { total } } } = await userFilterApi({
          orderBy: ascOrDesc.toUpperCase(),
          sortBy: sort,
          offset: 0,
          limit: rowsPerPage,
        });

        setOffset(0);
        setOrder(ascOrDesc);
        setOrderBy(sort);
        setUsers(data);
        setTotalUsers(total);
      } finally {
        setIsLoading(false);
      }
    },
    [rowsPerPage],
  );

  useEffect(() => {
    if (search) {
      const searchUsers = async () => {
        try {
          const body = {
            searchKey: search,
            limit: rowsPerPage,
            offset: queryOffset ? 0 : offset * rowsPerPage,
          } as UserSearchPayload;

          const role = filterByRole(activeFilter);

          if (role) {
            body.role = role;
          }

          const { data, meta: { pagination: { total } } } = await searchUsersApi(body);

          setUsers(data);
          setTotalUsers(total);
          queryOffset && setOffset(0);
          navigate({ search: '' });
        } catch (error) {
          throw error;
        }
      };

      searchUsers();
    } else {
      getFilteredUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, search, navigate, activeFilter]);

  useEffect(() => {
    setSearch('');
  }, [activeFilter]);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = debounce(
    (event) => {
      setSearch(event.target.value);
    },
    1000,
  );

  if (isLoading) {
    return <Loader sx={{ justifyContent: 'center', padding: '10px' }} />;
  }

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Users" />
          <Breadcrumbs />
        </Box>

        <Box>
          <FilterMenu
            setActiveFilter={setActiveFilter}
            activeFilter={activeFilter}
          />
        </Box>

        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            onChange={handleSearchChange}
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
          />
        </Box>
      </StyledContainer>

      {users?.length ? (
        <Table
          order={order}
          users={users}
          offset={offset}
          orderBy={orderBy}
          setOffset={setOffset}
          totalUsers={totalUsers}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleSortRequest={handleSortRequest}
          handleDeleteRequest={handleDeleteRequest}
          handleUpdateRequest={handleUpdateRequest}
          handleActivateRequest={handleActivateRequest}
        />
      ) : (
        <Typography>No user data!</Typography>
      )}
    </>
  );
};

export default memo(Users);
