import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { FontFamilyNames } from '../../../types/global/theme/fonts';
import { COLORS } from '../../../types/global/theme/colors';

export const StyledButton = styled(Button)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: COLORS.black,
  borderColor: COLORS.grey['500'],
  borderRadius: 6,
  fontFamily: FontFamilyNames.CircularStdBook,
  color: COLORS.white,
  padding: '15px',
  '&:hover': {
    backgroundColor: COLORS.black,
  },
  '&:disabled': {
    backgroundColor: alpha(COLORS.black, 0.1),
    cursor: 'not-allowed',
  },
}));
