import { useCallback, useEffect, useState, ChangeEventHandler, memo } from 'react';

import debounce from 'lodash/debounce';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import PageTitle from '../../common/components/PageTitle';
import { StyledContainer } from '../components/StyledContainer';
import SearchField from '../../common/components/SearchField';
import Table from './Table';
import { IAllShopsCore } from '../../types/shops';
import Loader from '../../common/components/Loader';
import { ShopsSearchPayload } from '../../models/shops-model';
import {
  deactivateShopApi,
  deleteShopApi,
  filterShopsApi, searchShopsApi,
} from '../../requests/shops';
import { TableData } from './Table/helper';
import { Order } from '../../utils/types';
import ModalContent from './ModalContent';
import { StyledDialog } from './styled';
import { confirmOptionsDialog } from '../../common/components/Table/helper';

const Shops = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [shops, setShops] = useState<IAllShopsCore[]>([]);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [totalShops, setTotalShops] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>();
  const { page: queryOffset } = queryString.parse(window.location.search);

  const [offset, setOffset] = useState(queryOffset ? +queryOffset : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isEqualQuery = offset === (queryOffset && +queryOffset);
  const confirm = useConfirm();
  const getShops = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearch('');

      const offsetOrder = offset * rowsPerPage;

      const offSetValue = (offsetOrder <= totalShops || isEqualQuery) ? offsetOrder : totalShops;

      const payloadData: ShopsSearchPayload = {
        limit: rowsPerPage,
        offset: offSetValue,
      };

      if (orderBy) {
        payloadData.orderBy = order.toUpperCase();
        payloadData.sortBy = orderBy;
      }

      const { data, meta: { pagination: { total } } } = await searchShopsApi(payloadData);

      setShops(data);
      setTotalShops(total);

      navigate({
        search: offset ? `?page=${offset}` : '',
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, orderBy, order, navigate, search]);

  const handleClose = () => {
    setOpen(false);
  };

  const openModal = (id: string) => {
    if (id) {
      setCurrentId(id);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (search) {
      const searchShops = async () => {
        try {
          const { data, meta: { pagination: { total } } } = await searchShopsApi({
            searchKey: search,
            limit: rowsPerPage,
            offset: queryOffset ? 0 : offset * rowsPerPage,
          });

          setShops(data);
          setTotalShops(total);
          queryOffset && setOffset(0);
          navigate({ search: '' });
        } catch (error) {
          throw error;
        }
      };

      searchShops();
    } else {
      getShops();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, search, navigate]);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = debounce(
    (event) => {
      setSearch(event.target.value);
    },
    1000,
  );

  const handleSortRequest = useCallback(
    async (ascOrDesc: Order, sort: keyof TableData) => {
      try {
        setIsLoading(true);
        setSearch('');

        const { data, meta: { pagination: { total } } } = await filterShopsApi({
          orderBy: ascOrDesc.toUpperCase(),
          sortBy: sort,
          offset: 0,
          limit: rowsPerPage,
        });

        setOffset(0);
        setOrder(ascOrDesc);
        setOrderBy(sort);
        setShops(data);
        setTotalShops(total);
      } finally {
        setIsLoading(false);
      }
    },
    [rowsPerPage],
  );

  const handleActivateRequest = useCallback(async (shopId: string, isActive: boolean) => {
    try {
      await deactivateShopApi(shopId, { isActive });
      getShops();
    } catch (e) {
      throw e;
    }
  }, [getShops]);

  const handleDeleteRequest = async (id: string) => {
    try {
      await confirm(confirmOptionsDialog({ questionText: 'Are you sure you want to delete the shop?' }));

      await deleteShopApi(id);
      getShops();
    } catch (e) {
      throw e;
    }
  };

  if (isLoading) {
    return <Loader sx={{ justifyContent: 'center', padding: '10px' }} />;
  }

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Shops" />
          <Breadcrumbs />
        </Box>
        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            onChange={handleSearchChange}
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
          />
        </Box>

      </StyledContainer>
      <Box>
        {shops?.length ? (
          <Table
            handleDeleteRequest={handleDeleteRequest}
            shops={shops}
            offset={offset}
            order={order}
            orderBy={orderBy}
            setOffset={setOffset}
            totalShops={totalShops}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleActivateRequest={handleActivateRequest}
            getShops={getShops}
            handleSortRequest={handleSortRequest}
            openModal={(id: string) => openModal(id)}
          />
        ) : (
          <Typography>Still there is no shop</Typography>
        )}
      </Box>
      <StyledDialog
        open={open}
        handleClose={handleClose}
        fullWidth
      >
        <ModalContent id={currentId} />
      </StyledDialog>

    </>
  );
};

export default memo(Shops);
