import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';

import { COLORS } from '../../../../types/global/theme/colors';

export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-root,
  .MuiTableCell-root {
    font-size: 14px;
    padding: 16px;
  }
  
  .MuiLink-root {
    text-decoration: none;
  }

  .MuiTableRow-root {
    &.isEditable {
      background: ${COLORS.alpha.denim008};
    }
  }

  .MuiTableRow-hover {
    :hover {
      background: ${COLORS.alpha.denim008};
    }
  }
`;

export const StyledRedText = styled(Typography)(() => ({
  color: COLORS.red.main,
}));
