import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';

import { FontFamilyNames } from '../../../types/global/theme/fonts';

export const StyledHeadCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
  height: '64px',
  paddingLeft: '16px',
  fontWeight: 500,
  fontFamily: 'Roboto Medium',
  fontSize: '14px',
}));

export const StyledTableHead = styled(TableHead)(() => ({
  '.MuiTableRow-root': {
    '.MuiTableCell-root': {
      span: {
        fontFamily: FontFamilyNames.RobotoMedium500,
        fontSize: '14px',
      },
    },
  },
}));

export const TableSortedCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
  height: '64px',
  paddingLeft: '16px',
}));
