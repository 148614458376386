import { AxiosResponse } from 'axios';

import {
  OrderFilterPayload,
  OrderSearchPayload,
  UpdateUserPayloadType,
  OrderUpdateResponseType,
  ChangeStatusPayload,
} from '../models/order-model';
import { http } from '../services/RequestService';
import { IOrdersCore, ISingleOrderCore } from '../types/orders/index';
import { ResponseData } from '../utils/types';

const prefix = 'orders';

export const getAllOrdersApi = async () => {
  const response = await http.get<ResponseData<IOrdersCore[]>>(prefix);

  return response.data;
};

export const getOrderByIdApi = async (id: string) => {
  const response = await http.get<AxiosResponse<ISingleOrderCore>>(`${prefix}/${id}/info`);

  return response.data;
};

export const openOrderByIdApi = async (id: string) => {
  const response = await http.put<AxiosResponse<ISingleOrderCore>>(`${prefix}/${id}/open`);

  return response.data;
};

export const changeStatusOpenOrderApi = async (id: string, body: ChangeStatusPayload) => {
  const response = await http.put<ChangeStatusPayload>(`${prefix}/orderItem/${id}/info`, body);

  return response.data;
};

export const changeStatusCloseOrderApi = async (id: string, body: ChangeStatusPayload) => {
  const response = await http.put<ChangeStatusPayload>(`${prefix}/${id}/info`, body);

  return response.data;
};

export const shippingRefundApi = async (id: string) => {
  const response = await http.put<ChangeStatusPayload>(`${prefix}/${id}/shippingRefund`);

  return response.data;
};

export const updateOrderByIdApi = async (
  id: string,
  data: UpdateUserPayloadType,
) => {
  const response = await http.put<
    UpdateUserPayloadType,
    OrderUpdateResponseType
  >(`${prefix}/${id}`, data);

  return response.data;
};

export const orderDeleteApi = async (id: string) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const searchOrdersApi = async (params:OrderSearchPayload) => {
  const response = await http.get<OrderSearchPayload, AxiosResponse<ResponseData<IOrdersCore[]>>>(`${prefix}`, {
    params,
  });

  return response.data;
};

export const ordersFilterApi = async (params:OrderFilterPayload) => {
  const response = await http.get<OrderFilterPayload, AxiosResponse<ResponseData<IOrdersCore[]>>>(`${prefix}`, {
    params,
  });

  return response.data;
};

export const ordersExportApi = async (id: string) => {
  const response = await http.get(`${prefix}/${id}/export/info`);

  return response.data;
};
