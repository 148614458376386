import { FC, memo, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Switch from '@mui/material/Switch';

import Dialog from '../../../common/components/Dialog';
import SaveButton from '../../../common/components/Button';
import {
  updateCategoryByIdApi,
} from '../../../requests/category';
import { Translation } from '../../../types/category';
import { initialTranlationsState, sortedCollection } from '../../../utils/helpers';
import InputsWithFlag from '../../../common/components/InputsWithFlag';

export type EditingType = {
  translations: Translation[];
  id: string;
  key: string;
  isActive: boolean;
  parentId:string;
}
interface EditCategoryModalProps {
  open: boolean;
  handleClose: () => void;
  editHandler: () => void;
  info: EditingType;
}

interface FormValues {
  translations: Translation[];
  key: string;
  isActive: boolean;
  parentId:string;
}

const EditCategoryModal: FC<EditCategoryModalProps> = ({
  open,
  handleClose,
  editHandler,
  info: {
    translations,
    id,
    key,
    isActive,
    parentId,
  },
}) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      key,
      translations: initialTranlationsState,
      isActive,
      parentId,
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
  } = methods;

  useEffect(() => {
    if (translations) {
      reset({ translations: sortedCollection(translations), key, isActive, parentId });
    }
  }, [translations, reset, key, isActive, parentId]);

  const onSubmit = (data: FormValues) => {
    const editCategory = async () => {
      try {
        await updateCategoryByIdApi(id, data);
        handleClose();
        reset();
        editHandler();
      } catch (e) {
        throw e;
      }
    };

    editCategory();
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <FormProvider {...methods}>
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
          sx={{ width: '100%', padding: '0 20px 20px' }}
        >
          <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
            Edit category
          </Typography>
          <InputsWithFlag />
          <Controller
            name="isActive"
            control={control}
            render={({ field }) => (
              <Switch
                color="secondary"
                inputProps={{ ...register('isActive') }}
                onChange={(e) => field.onChange(e)}
                checked={field.value}
              />
            )}
          />

          <SaveButton type="submit" title="Save" />
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default memo(EditCategoryModal);
