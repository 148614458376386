import { FC, memo } from 'react';

import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

interface IMorePopoverProps {}

const MorePopover: FC<IMorePopoverProps> = ({ children }) => {
  return (
    <PopupState variant="popover" popupId="more-popup-popover">
      {(popupState: any) => (
        <div>
          <IconButton
            aria-label="more tooltip"
            component="span"
            {...bindTrigger(popupState)}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {children}
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default memo(MorePopover);
