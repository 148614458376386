import { memo, SyntheticEvent, useState } from 'react';

import Box from '@mui/material/Box';
import { FieldArrayWithId } from 'react-hook-form';

import Banner from './components/Banner';
import { StyledTab, StyledTabs } from './styled';
import TabPanel from './components/TabPanel';
import { FormValues } from '../helper';

interface ITabsProps {
  bannerItems: FieldArrayWithId<FormValues, 'allBanners.items', 'id'>[];
}

const Tabs = ({ bannerItems }: ITabsProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isBannerExist =
    bannerItems && bannerItems.length > 0;

  return (
    <>
      {isBannerExist && (
        <Box sx={{ width: '100%' }}>
          <Box>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {bannerItems.map((bannerItem, idx) => (
                <StyledTab
                  key={bannerItem.id}
                  // disabled={bannerItem.disabled}
                  label={`Banner 0${idx + 1}`}
                />
              ))}
            </StyledTabs>
          </Box>

          {bannerItems.map((bannerItem, idx) => (
            <TabPanel key={bannerItem.id} value={value} index={idx}>
              <Banner bannerIndex={idx} />
            </TabPanel>
          ))}
        </Box>
      )}
    </>
  );
};

export default memo(Tabs);
