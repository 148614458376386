import { FC, memo, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from 'react-hook-form';

import Dialog from '../../../../common/components/Dialog';
import SaveButton from '../../../../common/components/Button';
import { Translation } from '../../../../types/category';
import { initialTranlationsState, sortedCollection } from '../../../../utils/helpers';
import { updateBusinessTypeByIdApi } from '../../../../requests/business-types';
import InputsWithFlag from '../../../../common/components/InputsWithFlag';

export type EditingType = {
  translations: Translation[];
  id: string;
  key: string;
}
interface EditBusinessModalProps {
  open: boolean;
  handleClose: () => void;
  editHandler: () => void;
  info: EditingType;
}

interface FormValues {
  translations: Translation[];
  key: string;
}

const EditBusinessTypeModal: FC<EditBusinessModalProps> = ({
  open,
  handleClose,
  editHandler,
  info: {
    translations,
    id,
    key,
  },
}) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      key,
      translations: initialTranlationsState,
    },
  });

  const {
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    if (translations) {
      reset({ translations: sortedCollection(translations), key });
    }
  }, [translations, reset, key]);

  const onSubmit = (data: FormValues) => {
    const editBusinessType = async () => {
      const response = await updateBusinessTypeByIdApi(id, data);

      if (!response) {
        handleClose();
        reset();
        editHandler();
      }
    };

    editBusinessType();
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <FormProvider {...methods}>
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
          sx={{ width: '100%', padding: '0 20px 20px' }}
        >
          <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
            Edit Business Type
          </Typography>
          <InputsWithFlag />
          <SaveButton type="submit" title="Save" />
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default memo(EditBusinessTypeModal);
