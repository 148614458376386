import {
  BANNER_ACTIONS,
  ISetBannerLoading,
  ISetAllHomeBannerData,
  IAttemptToCreateBannerCard,
  IAttemptToDisconnectBanner,
  IAttemptToUpdateBannerCard,
} from '../types/banners';

export const setAllHomeBannerDataAction = (
  payload: ISetAllHomeBannerData['payload'],
) => ({
  type: BANNER_ACTIONS.SET_ALL_HOME_BANNER_DATA,
  payload,
});

export const setBannerLoadingAction = (
  payload: ISetBannerLoading['payload'],
) => ({
  type: BANNER_ACTIONS.SET_BANNER_LOADING,
  payload,
});

export const attemptToCrateBannerAction = (
  payload: IAttemptToCreateBannerCard['payload'],
) => ({
  type: BANNER_ACTIONS.ATTEMPT_TO_CREATE_BANNER_CARD,
  payload,
});

export const attemptToUpdateBannerAction = (
  payload: IAttemptToUpdateBannerCard['payload'],
) => ({
  type: BANNER_ACTIONS.ATTEMPT_TO_UPDATE_BANNER_CARD,
  payload,
});

export const attemptToGetAllBannersAction = () => ({
  type: BANNER_ACTIONS.ATTEMPT_TO_GET_ALL_BANNERS,
});

export const attemptToDisconnectBannerAction = (
  payload: IAttemptToDisconnectBanner['payload'],
) => ({
  type: BANNER_ACTIONS.ATTEMPT_TO_DISCONNECT_BANNER,
  payload,
});
