import { memo, useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import PageTitle from '../../common/components/PageTitle';
import { StyledContainer } from '../components/StyledContainer';
import { StyledTabBox, StyledTabsChip, StyledTabsContent } from '../components/StyledTabs';
import { CreateTabLabelEnum, createTabs, renderActiveTabBox } from './helpers';
import SectionTitle from '../../common/components/SectionTitle/SectionTitle';

const Privacy = () => {
  const [activeTab, setActiveTab] = useState<CreateTabLabelEnum>(createTabs[0].title);

  const handleClick = (title: CreateTabLabelEnum) => {
    setActiveTab(title);
  };

  return (
    <>

      <StyledContainer>
        <Box>
          <PageTitle title="Privacy Policy" />
          <Breadcrumbs />
        </Box>
      </StyledContainer>
      <Box>
        <SectionTitle title="Edit or Update" />
        <StyledTabsContent>
          {createTabs.map(({ title }) => (
            <StyledTabsChip
              key={title}
              label={(
                <Typography variant="h2" component="span">
                  {title}
                </Typography>
              )}
              className={activeTab === title ? 'active' : ''}
              variant="outlined"
              onClick={() => handleClick(title)}
            />
          ))}
        </StyledTabsContent>

        <StyledTabBox>
          {renderActiveTabBox(activeTab)}
        </StyledTabBox>
      </Box>
    </>
  );
};

export default memo(Privacy);
