import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { FontFamilyNames } from '../../../../../types/global/theme/fonts';
import { COLORS } from '../../../../../types/global/theme/colors';

export const StyledInputBox = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  backgroundColor: theme.palette.common.white,
  boxShadow: `0 0 0 1px ${COLORS.grey['500']}`,
  fontSize: 14,
  width: '100%',
  padding: '12px 15px',
  letterSpacing: '-0.03em',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  fontFamily: FontFamilyNames.CircularStdBook,
  fontWeight: '450',
  display: 'flex',
  alignItems: 'center',

  '.MuiBox-root': {
    flexBasis: '50px',

    '.MuiFormControl-root': {
      padding: 0,

      '.MuiInputBase-root': {
        padding: '0',
        input: {
          padding: '3px 21px',
        },
      },
    },
  },
}));

export const StyledIntervalInSlidesBox = styled(StyledInputBox)(() => ({
  '.MuiBox-root': {
    flexBasis: 'auto',

    '.MuiFormControl-root': {
      padding: '3px 18px',

      '.MuiInputBase-root': {
        padding: '0',
        width: 'max-content',
        input: {
          padding: '0',
        },
      },
    },
  },
}));

export const StyledInputBoxTitle = styled(Box)(() => ({
  flex: 1,
}));

export const NumberOfBannersEndAdornment = styled(Stack)(() => ({
  '.MuiIconButton-root': {
    padding: 0,
  },
}));
