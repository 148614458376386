import { combineReducers } from 'redux';

import { AppState, StoreReducersEnum } from '../types';
import auth, { initialState as initialAuthState } from './auth';
import banners, { initialState as initialBannersState } from './banners';

const rootReducer = combineReducers<AppState>({ auth, banners });

export const initialAppState: AppState = {
  [StoreReducersEnum.AUTH]: initialAuthState(),
  [StoreReducersEnum.BANNERS]: initialBannersState(),
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
