import { memo } from 'react';

import { PRIVACY_ENUM } from '../../../../models/privacy-model';
import Policy from './Policy';

const TermPolicy = () => {
  return (
    <div><Policy type={PRIVACY_ENUM.REFUND} /></div>
  );
};

export default memo(TermPolicy);
