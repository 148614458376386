import { memo, MutableRefObject, useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import Paper from '@mui/material/Paper';
import Flag from 'react-world-flags';

import Button from '../../../../common/components/Button';
import {
  getPolicyByType,
  postPolicyByTypeApi,
  updatePolicyByIdApi,
} from '../../../../requests/privacy';
import useMount from '../../../../hooks/useMount';
import { Translation } from '../../../../types/category';
import { PRIVACY_ENUM } from '../../../../models/privacy-model';
import { apiKey, EditorInitValue, flagsList, initialPolicyState, toolbars } from './helpers';
import { LANGUAGES_ENUM_LIST, sortedCollection } from '../../../../utils/helpers';
import { StyledBox } from './styled';
import InputLabel from '../../../../common/components/InputLabel/InputLabel';

interface PolicyProps {
type: PRIVACY_ENUM;
}

const Policy = ({ type }:PolicyProps) => {
  const [updatedId, setUpdatedId] = useState('');
  const [editorValue, setEitorValue] = useState<Translation[]>(initialPolicyState);

  const gettingData = async () => {
    try {
      const data = await getPolicyByType(type);
      const { id, translations } = data.data[0];

      const sortedTranslations = sortedCollection(translations);

      setUpdatedId(id);

      const newData: Translation[] = sortedTranslations.map((trans) => ({
        ...trans, text: trans.text,
      }));

      setEitorValue(newData);
    } catch (error) {
      throw error;
    }
  };

  useMount(() => {
    gettingData();
  });

  const editorRefs = [
    useRef(null) as MutableRefObject<any | null>,
    useRef(null) as MutableRefObject<any | null>,
    useRef(null) as MutableRefObject<any | null>,
  ];

  const saveHandler = () => {
    const translationsList = editorRefs.map((editor, idx) => ({
      lang: LANGUAGES_ENUM_LIST[idx],
      text: editor.current.getContent(),
      paragraph: 'paragraph',
    }));

    const body = {
      type,
      translations: translationsList,
    };

    if (updatedId) {
      const updatePolicy = async () => {
        const response = await updatePolicyByIdApi(updatedId, body);

        if (!response) {
          gettingData();
        }
      };

      updatePolicy();
    } else {
      const createPolicy = async () => {
        const response = await postPolicyByTypeApi(body);

        if (!response) {
          gettingData();
        }
      };

      createPolicy();
    }
  };

  return (
    <>
      <Paper sx={{ padding: '20px' }}>
        <StyledBox>
          {
            editorRefs.map((item, idx) => {
              const { text, code } = flagsList[idx];

              return (
                <>
                  <InputLabel title={text} flag={<Flag code={code} height="22" width="22" />} />
                  <Editor
                    apiKey={apiKey}
                    onInit={(_, editor) => item.current = editor}
                    initialValue={editorValue?.[idx]?.text}
                    toolbar={toolbars}
                    init={EditorInitValue}
                  />
                </>
              );
            })
          }
        </StyledBox>
        <Button title="Save" sx={{ width: '356px' }} onClick={saveHandler} />
      </Paper>
    </>
  );
};

export default memo(Policy);
