import { FC, memo, useState } from 'react';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Outlet, useLocation } from 'react-router-dom';

import AppBar from '../../common/components/AppBar';
import Drawer from '../../common/components/Drawer';
import { DRAWER_WIDTH } from '../../utils/constants';
import { Main, StyledOutletContainer } from './styled';

interface ILayoutProps {}

const Layout: FC<ILayoutProps> = () => {
  const { pathname } = useLocation();

  const pathClassName = pathname.split('/').filter(Boolean)[0];

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />

      <Drawer open={open} drawerWidth={DRAWER_WIDTH} />

      <Main open={open}>
        <Toolbar />
        <StyledOutletContainer className={pathClassName}>
          <Outlet />
        </StyledOutletContainer>
      </Main>
    </Box>
  );
};

export default memo(Layout);
