import { Translation } from '../../types/category';

export enum CreateBannerLabelEnum {
  RunningLineBanner = 'Running Line Banner',
  ImageCarousel = 'Image Carousel',
  ADBanner = 'AD Banner',
  ProductsBanner = 'Products Banner',
  FullRowBanner = 'Full Row Banner',
}

export interface CreateBanners {
  title: CreateBannerLabelEnum;
}

export const createBanners: CreateBanners[] = [
  {
    title: CreateBannerLabelEnum.RunningLineBanner,
  },
  {
    title: CreateBannerLabelEnum.ImageCarousel,
  },
  {
    title: CreateBannerLabelEnum.ADBanner,
  },
  {
    title: CreateBannerLabelEnum.ProductsBanner,
  },
  {
    title: CreateBannerLabelEnum.FullRowBanner,
  },
];

export const addTranslationsContent = (translations: Translation[], content: Translation['content']): Translation[] => {
  return translations.map((translation) => {
    return { ...translation, content };
  });
};
