import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

export const StyledTabsContent = styled(Box)(() => ({
  display: 'flex',
  gridGap: '8px',
  maxWidth: '855px',
}));

export const StyledTabsChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey['700']}`,
  background: theme.palette.common.white,

  '&.active': {
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,

    '&.MuiChip-clickable:hover': {
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },

    '&.MuiChip-root .MuiChip-label': {
      '.MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
  },

  '&.MuiChip-clickable:hover': {
    background: 'white',
    color: theme.palette.primary.main,
  },

  '&.MuiChip-root ': {
    '.MuiChip-label': {
      '.MuiTypography-root': {
        lineHeight: '20.8px',
        letterSpacing: '-0.04em',
        color: theme.palette.common.black,
      },
    },
  },
}));

export const StyledTabBox = styled(Box)(() => ({
  margin: '50px 0',
}));
