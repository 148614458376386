import { css } from '@mui/material/styles';

import RobotoBlack900Ttf from './Roboto-Black.ttf';
import RobotoBlack900Woff from './Roboto-Black.woff';
import RobotoMedium500Ttf from './Roboto-Medium.ttf';
import RobotoMedium500Woff from './Roboto-Medium.woff';
import RobotoNormal400Ttf from './Roboto-Regular.ttf';
import RobotoNormal400Woff from './Roboto-Regular.woff';
import RobotoBold700Ttf from './Roboto-Bold.ttf';
import RobotoBold700Woff from './Roboto-Bold.woff';
import { FontFamilyNames, ThemeFont } from '../../../types/global/theme/fonts';

const RobotoFont: ThemeFont = {
  fontFamily: [
    FontFamilyNames.RobotoBlack900,
    FontFamilyNames.RobotoBold700,
    FontFamilyNames.RobotoMedium500,
    FontFamilyNames.RobotoNormal400,
  ],
  fontFaces: css`
    @font-face {
      font-family: ${FontFamilyNames.RobotoNormal400};
      font-style: normal;
      font-display: swap;
      src: local(${FontFamilyNames.RobotoNormal400}), local("Roboto-Regular"),
        url(${RobotoNormal400Ttf}) format("truetype"),
        url(${RobotoNormal400Woff}) format("woff");
    }

    @font-face {
      font-family: ${FontFamilyNames.RobotoMedium500};
      font-style: normal;
      font-display: swap;
      src: local(${FontFamilyNames.RobotoMedium500}), local("Roboto-Medium"),
        url(${RobotoMedium500Ttf}) format("truetype"),
        /* Safari, Android, iOS */ url(${RobotoMedium500Woff}) format("woff"); /* Pretty Modern Browsers */
    }

    @font-face {
      font-family: ${FontFamilyNames.RobotoBold700};
      font-style: normal;
      font-display: swap;
      src: local(${FontFamilyNames.RobotoBold700}), local("Roboto-Bold"),
        url(${RobotoBold700Ttf}) format("truetype"),
        url(${RobotoBold700Woff}) format("woff"); /* Pretty Modern Browsers */
    }

    @font-face {
      font-family: ${FontFamilyNames.RobotoBlack900};
      font-style: normal;
      font-display: swap;
      src: local(${FontFamilyNames.RobotoBlack900}), local("Roboto-Black"),
        url(${RobotoBlack900Ttf}) format("truetype"),
        /* Safari, Android, iOS */ url(${RobotoBlack900Woff}) format("woff"); /* Pretty Modern Browsers */
    }
  `,
};

export default RobotoFont;
