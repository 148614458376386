import { memo } from 'react';

import Typography from '@mui/material/Typography';

import { StyledTitleBox } from './StyledTitle';

interface SectionTitleProps {
    title: string;
}

const SectionTitle = ({ title }:SectionTitleProps) => {
  return (
    <StyledTitleBox>
      <Typography variant="h2" component="h2">
        {title}
      </Typography>
    </StyledTitleBox>
  );
};

export default memo(SectionTitle);
