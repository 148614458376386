import { memo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import BannerCardPaper from '../../BannerCardPaper';
import { SectionContainer } from './styled';
import Button from '../../../../../common/components/Button';
import LeftSection from './components/LeftSection';
import RightSection from './components/RightSection';
import {
  FormValues,
  defaultValues,
  validationSchema,
  sanitizeFullrowData,
} from './helper';
import { attemptToCrateBannerAction, attemptToUpdateBannerAction } from '../../../../../store/actions/banners';
import { CardTypes } from '../../../../../types/enums/banners';
import { HomeAllBanner } from '../../../../../models/banner-card/home-all-model';
import BannerHeaderStatus from '../BannerHeaderStatus';
import { sortedCollection } from '../../../../../utils/helpers';

interface IFullRowBannerProps {
  isNew?: boolean;
  bannerData?: HomeAllBanner | null;
}

const FullRowBanner = ({ isNew, bannerData }: IFullRowBannerProps) => {
  const sortedTranslations = sortedCollection(bannerData?.translations);
  const initialValues = {
    leftSection: {
      image: bannerData?.translation?.content?.leftSection?.image,
      link: bannerData?.translation?.content?.leftSection?.link,
    },
    rightSection: {
      image: bannerData?.translation?.content?.rightSection.image,
      link: bannerData?.translation?.content?.rightSection.link,
    },
    translations: sortedTranslations,
  };

  const dispatch = useDispatch();
  const methods = useForm<FormValues>({
    defaultValues: !isNew && bannerData ? initialValues : defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const leftImage = methods.watch('leftSection.image');
  const rightImage = methods.watch('rightSection.image');

  const onSubmit = (data: FormValues) => {
    const sanitizedData = sanitizeFullrowData(data);
    const action = {
      bannerCardType: CardTypes.fullRow,
      bannerData: sanitizedData,
    };

    dispatch(
      isNew
        ? attemptToCrateBannerAction(action)
        : attemptToUpdateBannerAction({
          ...action,
          cardId: bannerData?.id,
        }),
    );
    methods.reset();
  };

  const { handleSubmit, formState: { isSubmitting, isDirty } } = methods;

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <BannerCardPaper title="Full Row Banner">
          {!isNew && <BannerHeaderStatus bannerId={bannerData?.id} />}

          <Stack direction="column" spacing="20px">
            <SectionContainer>
              <LeftSection />
              <RightSection />
            </SectionContainer>

            <Button
              disabled={!isSubmitting && !isDirty &&
              leftImage === initialValues.leftSection.image && rightImage === initialValues.rightSection.image}
              type="submit"
              title={isNew ? 'Save' : 'Update'}
            />
          </Stack>
        </BannerCardPaper>
      </Box>
    </FormProvider>
  );
};

export default memo(FullRowBanner);
