import { memo } from 'react';

import Typography from '@mui/material/Typography';

import { StyledPaper, StyledContentPaper, StyledBox } from '../styled';
import { IShopInfo } from '../helpers';

interface OrderNotesProps {
  info: IShopInfo;
}

const Contact = ({ info: { contactFirstName, contactLastName, contactPhone, email, balances: {
  balanceHistory, passiveBalance,
} } }: OrderNotesProps) => {
  return (
    <StyledPaper>
      <StyledContentPaper>
        <StyledBox>
          <Typography color="blue">
            Contact Person
          </Typography>
          <Typography>
            {` ${contactFirstName} ${contactLastName}`}
          </Typography>
          <Typography color="blue">
            Phone Number
          </Typography>
          <Typography>
            {contactPhone}
          </Typography>
          <Typography color="blue">
            Email
          </Typography>
          <Typography>
            {email}
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography color="blue">
            Total sales
          </Typography>
          <Typography>
            {balanceHistory}
          </Typography>
          <Typography color="blue">
            Revenue
          </Typography>
          <Typography>
            {passiveBalance}
          </Typography>
        </StyledBox>
      </StyledContentPaper>
    </StyledPaper>
  );
};

export default memo(Contact);
