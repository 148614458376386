import { memo, useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Snackbar } from '@mui/material';
import Divider from '@mui/material/Divider';

import { StyledTableBody } from './styled';
import { IAllShopsCore } from '../../../../types/shops';
import { dateFormatter, getCDNImagePath } from '../../../../utils/helpers';
import MorePopover from '../../../../common/components/Table/MorePopover';
import { STAGING_URL } from '../../../../utils/constants';
import Alert from '../../../../common/components/Alert ';

interface ITableBodyProps {
  rows: IAllShopsCore[];
  handleActivateRequest: (shopId: string, isActive: boolean) => Promise<void>;
  getShops: () => void;
  openModal: (id: string) => void;
  handleDeleteRequest: (shopId: string) => Promise<void>;
}

const TableBody = ({
  rows,
  handleActivateRequest,
  handleDeleteRequest,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getShops,
  openModal,
}: ITableBodyProps) => {
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (err) {
      const interval = setInterval(() => {
        setErr(false);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [err]);

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  return (
    <>
      { err && (
      <Snackbar
        open={err}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginTop: '40px !important' }}
      >
        <Alert severity="error">
          You can not make featured more than 4 items!
        </Alert>
      </Snackbar>
      )}
      <StyledTableBody>
        {rows.map(({
          id, name, createdAt, image, isActive, address, contactPhone,
        }) => (
          <TableRow
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            style={{ height: 64, opacity: isActive ? 1 : 0.4 }}
            onClick={() => openModal(id)}
          >
            <TableCell onClick={(e: any) => stopPropagation(e)}>
              <Link href={`${STAGING_URL}/other-user-profile/${id}`} target="_blank" color="inherit">
                {name}
              </Link>
            </TableCell>
            <TableCell align="left" onClick={(e: any) => stopPropagation(e)}>
              <Link href={`${STAGING_URL}/other-user-profile/${id}`} target="_blank" color="inherit">
                <Avatar
                  src={image ? getCDNImagePath(image) : ''}
                  sx={{ width: 24, height: 24 }}
                />
              </Link>
            </TableCell>
            <TableCell>
              {`${address?.street}, ${address?.apartment ? `${address?.apartment},` : ''} 
            ${address?.city}, ${address?.country}`}
            </TableCell>
            <TableCell>{dateFormatter(createdAt)}</TableCell>
            <TableCell>
              {contactPhone}
            </TableCell>
            {/** ACTIONS */}
            <TableCell sx={{ width: '10px', padding: 0 }} align="right" onClick={(e: any) => stopPropagation(e)}>
              <MorePopover>
                <Box
                  onClick={() => handleActivateRequest(id, !isActive)}
                  sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                >
                  <Typography variant="body1">
                    {isActive ? 'Deactivate' : 'Activate'}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  onClick={() => handleDeleteRequest(id)}
                  sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                >
                  <Typography variant="body1">
                    Delete
                  </Typography>
                </Box>
              </MorePopover>
            </TableCell>
          </TableRow>
        ))}
      </StyledTableBody>
    </>
  );
};

export default memo(TableBody);
