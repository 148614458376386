import Typography from '@mui/material/Typography';
import { ConfirmOptions } from 'material-ui-confirm';

interface IconfirmOptionsDialog {
  questionText: string;
}
export const confirmOptionsDialog = ({ questionText }: IconfirmOptionsDialog): ConfirmOptions => {
  return {
    title: '',
    cancellationText: 'NO',
    cancellationButtonProps: {
      variant: 'contained',
      color: 'primary',
      sx: { borderRadius: '4px' },
    },
    content: (
      <Typography lineHeight="33px" align="center" variant="h3">
        {questionText}
      </Typography>
    ),
    dialogProps: {
      maxWidth: 'xs',
      fullWidth: true,
      PaperProps: {
        sx: {
          maxWidth: '545px',
          '.MuiDialogContent-root': {
            padding: '0',
            paddingBottom: '32px',
          },
          '.MuiDialogActions-root': {
            display: 'flex',
            padding: '0',
            justifyContent: 'center',

            button: {
              fontSize: '15px',
              lineHeight: '28px',
            },
          },
          padding: '20px',
        },
      },
    },
    confirmationText: 'YES',
    confirmationButtonProps: {
      variant: 'contained',
      color: 'error',
      sx: { borderRadius: '4px' },
    },
  };
};

// TODO: find and replace all deleteConfirmOption with confirmOptionsDialog
export const deleteConfirmOption: ConfirmOptions = {
  title: '',
  cancellationText: 'NO',
  cancellationButtonProps: {
    variant: 'contained',
    color: 'primary',
    sx: { borderRadius: '4px' },
  },
  content: (
    <Typography lineHeight="33px" align="center" variant="h3">
      Are you sure you want to delete the selected item?
    </Typography>
  ),
  dialogProps: {
    maxWidth: 'xs',
    fullWidth: true,
    PaperProps: {
      sx: {
        maxWidth: '545px',
        '.MuiDialogContent-root': {
          padding: '0',
          paddingBottom: '32px',
        },
        '.MuiDialogActions-root': {
          display: 'flex',
          padding: '0',
          justifyContent: 'center',

          button: {
            fontSize: '15px',
            lineHeight: '28px',
          },
        },
        padding: '20px 0 10px 0',
      },
    },
  },
  confirmationText: 'YES',
  confirmationButtonProps: {
    variant: 'contained',
    color: 'error',
    sx: { borderRadius: '4px' },
  },
};
