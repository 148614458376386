import { Dispatch, SetStateAction, memo } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { StyledChip } from './styled';
import { UserFilterMenuEnum } from '../../../../types/users';

interface IFilterMenuProps{
  activeFilter: UserFilterMenuEnum;
  setActiveFilter: Dispatch<SetStateAction<UserFilterMenuEnum>>;
}

const FilterMenu = ({ activeFilter, setActiveFilter }: IFilterMenuProps) => {
  const handleClick = (filterType: UserFilterMenuEnum) => {
    setActiveFilter(filterType);
  };

  const renderDoneIcon = (type: UserFilterMenuEnum) => {
    return type === activeFilter ? <DoneIcon fontSize="small" /> : undefined;
  };

  return (
    <Stack direction="row" spacing="14px">
      <StyledChip
        clickable
        icon={renderDoneIcon(UserFilterMenuEnum.ALL)}
        onClick={() => handleClick(UserFilterMenuEnum.ALL)}
        label={(
          <Typography variant="subtitle1" component="span">
            All
          </Typography>
        )}
      />
      <StyledChip
        clickable
        icon={renderDoneIcon(UserFilterMenuEnum.ALL_BUYERS)}
        onClick={() => handleClick(UserFilterMenuEnum.ALL_BUYERS)}
        label={(
          <Typography variant="subtitle1" component="span">
            All Buyers
          </Typography>
        )}
      />
      <StyledChip
        clickable
        icon={renderDoneIcon(UserFilterMenuEnum.ALL_SELLERS)}
        onClick={() => handleClick(UserFilterMenuEnum.ALL_SELLERS)}
        label={(
          <Typography variant="subtitle1" component="span">
            All Sellers
          </Typography>
        )}
      />
      <StyledChip
        clickable
        icon={renderDoneIcon(UserFilterMenuEnum.ADMIN)}
        onClick={() => handleClick(UserFilterMenuEnum.ADMIN)}
        label={(
          <Typography variant="subtitle1" component="span">
            Admins
          </Typography>
        )}
      />
    </Stack>
  );
};

export default memo(FilterMenu);
