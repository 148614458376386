import * as yup from 'yup';

import { AddBannerModel } from '../../../../../models/banner-card/add-model';
import { Translation } from '../../../../../types/category';
import { CardSize, CardTypes } from '../../../../../types/enums/banners';
import { Shape } from '../../../../../types/global/yup-object-shape';
import { initialTranlationsState, replceSpacingWithUnderscore } from '../../../../../utils/helpers';

export type ImageDataType = {
  en: string; hy: string; ru: string;
}

export interface FormValues {
  id?: string;
  key?: string;
  link: string;
  image: ImageDataType;
  translations: Translation[];
}

export const initialImageValue = { en: '', hy: '', ru: '' };
export const defaultValues: FormValues = {
  link: '',
  image: { en: '', hy: '', ru: '' },
  translations: initialTranlationsState,
};

export const validationSchema = yup.object<Shape<FormValues>>({
  image: yup.object({
    en: yup.string().required('Required'),
    hy: yup.string().required('Required'),
    ru: yup.string().required('Required'),
  }),
});

export const addTranslationsImagesContent = (
  translations: Translation[],
  link: string,
  imagesList: ImageDataType,
): Translation[] => {
  return translations.map((translation) => {
    const image = (imagesList as Record<string, string>)[translation.lang];

    return { ...translation, content: { image, link } };
  });
};

export const sanitizeAdData = (data: FormValues, imagesList: ImageDataType) => {
  const { link, translations } = data;
  const result: AddBannerModel = {
    type: CardTypes.ad,
    isActive: true,
    key: replceSpacingWithUnderscore(translations[0].text),
    size: CardSize.column,
    translations: addTranslationsImagesContent(translations, link, imagesList),
    sort: -1,
  };

  return result;
};
