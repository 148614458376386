import { memo, useCallback, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { TableData } from '../../helper';
import { StyledTableBody } from './styled';
import EditBusinessTypeModal, { EditingType } from '../../../EditBusinessType/EditBusinessTypeModal';
import { getBusinessTypesByIdApi } from '../../../../../../requests/business-types';
import { sortedCollection } from '../../../../../../utils/helpers';

interface ITableBodyProps {
  page: number;
  rows: TableData[];
  rowsPerPage: number;
  editHandler:() => void;
}

const TableBody = ({
  rows,
  page,
  rowsPerPage,
  editHandler,
}: ITableBodyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const slicedAndSorted = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [editingInfo, setEditingInfo] = useState<EditingType>({
    id: '',
    key: '',
    translations: [],
  });

  const handleOpen = useCallback((id: string) => {
    const GettingBusinessTypeInfo = async () => {
      const info = await getBusinessTypesByIdApi(id);

      if (info.translations) {
        const { key, translations } = info;
        const sanitizedBusinessTypes = { id, key, translations: sortedCollection(translations) };

        setEditingInfo(sanitizedBusinessTypes);
        setOpen(true);
      }
    };

    GettingBusinessTypeInfo();
  }, []);

  return (
    <>
      <StyledTableBody>
        {rows.map((row) => (
          <TableRow
            hover
            key={row.id}
            tabIndex={-1}
            role="checkbox"
            style={{ height: 64 }}
            onClick={() => handleOpen(row.id)}
          >
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.key}</TableCell>
            <TableCell>{row.text}</TableCell>
          </TableRow>
        ))}
      </StyledTableBody>

      <EditBusinessTypeModal
        open={open}
        handleClose={handleClose}
        editHandler={editHandler}
        info={editingInfo}
      />
    </>
  );
};

export default memo(TableBody);
