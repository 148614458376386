import { memo } from 'react';

import { PRIVACY_ENUM } from '../../../../models/privacy-model';
import Policy from './Policy';

const ReturnPolicy = () => {
  return (
    <div><Policy type={PRIVACY_ENUM.RETURN} /></div>
  );
};

export default memo(ReturnPolicy);
