import { memo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import PageTitle from '../../common/components/PageTitle';
import { StyledContainer } from '../components/StyledContainer';
import SearchField from '../../common/components/SearchField';
import Button from '../../common/components/Button';
import BaseInput from '../../common/components/Input/BaseInput';
import CollectionBreadcrumbs from './CollectionBreadcrumbs';

const NewCollection = () => {
  const handleSearchChange = () => {
    console.log(handleSearchChange);
  };

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Collection" />
          <CollectionBreadcrumbs />
        </Box>

        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            onChange={handleSearchChange}
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
          />
        </Box>
      </StyledContainer>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Box sx={{ width: 350, marginTop: '13px', marginRight: '15px' }}>
          <Paper sx={{ padding: '20px' }}>
            <Stack
              component="form"
              spacing="18px"
              direction="column"
            >
              <BaseInput placeholder="Product Link" />
              <Button type="submit" title="Create" />
            </Stack>
          </Paper>
        </Box>
      </Box>

    </>
  );
};

export default memo(NewCollection);
