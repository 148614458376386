import { FC, memo } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from 'react-hook-form';

import SaveButton from '../../../../common/components/Button';
import { CategoryCreatePayload } from '../../../../models/category-model';
import {
  initialTranlationsState,
  replceSpacingWithUnderscore,
} from '../../../../utils/helpers';
import Dialog from '../../../../common/components/Dialog';
import { createBusinessTypeApi } from '../../../../requests/business-types';
import InputsWithFlag from '../../../../common/components/InputsWithFlag';

interface CreateBusinessTypeProps {
  open: boolean;
  handleClose: () => void;
  createHandler: () => void;
}

const CreateBusinessType: FC<CreateBusinessTypeProps> = ({
  open,
  handleClose,
  createHandler,
}) => {
  const methods = useForm<CategoryCreatePayload>({
    defaultValues: {
      translations: initialTranlationsState,
    },
  });

  const {
    handleSubmit,
    reset,
  } = methods;

  const onSubmit = (data: CategoryCreatePayload) => {
    const key = replceSpacingWithUnderscore(data.translations[0].text);
    const createBusinessType = async () => {
      try {
        await createBusinessTypeApi({ ...data, key });
        reset();
        createHandler();
      } catch (e) {
        throw e;
      }
    };

    createBusinessType();
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <FormProvider {...methods}>
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
          sx={{ width: '100%', padding: '0 20px 20px' }}
        >
          <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
            Add Business Type
          </Typography>
          <InputsWithFlag />
          <SaveButton type="submit" title="Create" />
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default memo(CreateBusinessType);
