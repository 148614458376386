import { AxiosResponse } from 'axios';

import { ISignInPayloadProps, ISignInResponseType } from '../models/auth-model';
import { http } from '../services/RequestService';

const prefix = '/auth';

export const signInApi = async (payload: ISignInPayloadProps) => {
  const response = await http.post<ISignInPayloadProps, AxiosResponse<ISignInResponseType>>(
    `${prefix}/login`,
    payload,
  );

  return response.data;
};
