import { styled } from '@mui/material/styles';

import { COLORS } from '../../../types/global/theme/colors';

export const StyledRouterLink = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMatch',
})<{ isMatch: boolean }>(({ theme, isMatch }) => ({
  background: isMatch ? COLORS.alpha.denim008 : 'unset',

  a: {
    svg: {
      fill: isMatch ? theme.palette.primary.main : COLORS.alpha.black054,
    },
    span: {
      ...theme.typography.h5,
      color: isMatch ? COLORS.alpha.black087 : 'initial',
    },
  },
}));
