import { memo, useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Collapse from '@mui/material/Collapse';
import { useForm } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DragDropContext, Droppable, Draggable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { useConfirm } from 'material-ui-confirm';

import { Specification } from '../../../../../models/category-specifications-model';
import { initialTranlationsState, sortedCollection } from '../../../../../utils/helpers';
import BaseInput from '../../../../../common/components/Input/BaseInput';
import Button from '../../../../../common/components/Button';
import {
  sortSpecificationsApi, specificationDeleteApi,
  updatSpecificationApi,
} from '../../../../../requests/specification';
import { validationSchema } from '../../../helper';
import { StyledSubCellBox, StyledSubRowBox } from './styled';
import { deleteConfirmOption } from '../../../../../common/components/Table/helper';
import { FormData, RowProps, sanitizeSpecifications } from './helpers';

const SpecificationsRow = ({ row, open, getCategoryInfo, toggleCollapse }: RowProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [specificationId, setSpecificationId] = useState('');
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      translations: initialTranlationsState,
      specification: {},
    },
    resolver: yupResolver(validationSchema),
  });

  const formValues = getValues();

  const onSubmit = (data: FormData) => {
    if (row) {
      const { key, id, isActive } = formValues.specification;
      const body = { key, id, isActive, translations: data.translations };

      const updatSpecificationGroup = async () => {
        const response = await updatSpecificationApi(id, body);

        if (!response) {
          setIsFocused(false);
          getCategoryInfo();
        }
      };

      updatSpecificationGroup();
    }
  };

  const handleFocus = (specification: Specification) => {
    setIsFocused(true);
    if (specification?.translations) {
      const sortedTranslations = sortedCollection(specification.translations);

      setValue('translations', [...sortedTranslations]);
      setValue('specification', specification);
      setSpecificationId(specification.id);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (row && result.destination) {
      const [removed] = row.splice(result.source.index, 1);

      row.splice(result.destination.index, 0, removed);

      const sortSpecifications = async () => {
        const data = sanitizeSpecifications(row || []);

        const response = await sortSpecificationsApi({ data });

        if (response) {
          getCategoryInfo();
          toggleCollapse();
        }
      };

      sortSpecifications();
    }
  };

  const confirm = useConfirm();
  const deleteHandler = useCallback((id: string) => {
    const deleteFunction = async () => {
      await confirm(deleteConfirmOption);

      const response = await specificationDeleteApi(id);

      if (!response) {
        getCategoryInfo();
      }
    };

    deleteFunction();
  }, [confirm, getCategoryInfo]);

  return (
    <Collapse in={open} timeout="auto">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableSubRow">
          {(providedDroppable: DroppableProvided) => (
            <Box {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {row?.map((specification, index) => {
                const sortedTranslations = sortedCollection(specification.translations);

                return !isFocused || specification.id !== specificationId ? (
                  <Draggable key={specification.id} draggableId={specification.id} index={index}>
                    {(providedDraggable, snapshot) => (
                      <StyledSubRowBox
                        ref={providedDraggable.innerRef}
                        data-snapshot={snapshot}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        {[0, 1, 2].map((idx) => (
                          <StyledSubCellBox key={idx} onClick={() => handleFocus(specification)}>
                            {sortedTranslations?.[idx].text}
                          </StyledSubCellBox>
                        ))}
                        <IconButton onClick={() => deleteHandler(specification.id)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </StyledSubRowBox>
                    )}
                  </Draggable>
                ) : (
                  <StyledSubRowBox key={specification.id}>
                    {[0, 1, 2].map((idx) => (
                      <StyledSubCellBox key={idx}>
                        <BaseInput errors={errors} inputProps={{ ...register(`translations.${idx}.text`) }} />
                      </StyledSubCellBox>
                    ))}
                    <StyledSubCellBox sx={{ width: '150px' }}>
                      <IconButton
                        onClick={() => setIsFocused(false)}
                        size="small"
                        sx={{ width: '10px', height: '10px', marginRight: '15px' }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Button
                        sx={{ fontSize: '14px' }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save
                      </Button>
                    </StyledSubCellBox>
                  </StyledSubRowBox>
                );
              })}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Collapse>
  );
};

export default memo(SpecificationsRow);
