import { memo } from 'react';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
  StyledleftPartBox,
} from './styled';

interface ILeftPartProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const LeftPart = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
}: ILeftPartProps) => {
  return (
    <StyledleftPartBox>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>

      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
    </StyledleftPartBox>
  );
};

export default memo(LeftPart);
