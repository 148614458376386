import { memo, ReactChild } from 'react';

import Typography from '@mui/material/Typography';

import { StyledLabel } from './styled';

interface InputLabelInterface {
    title: string;
    flag?: ReactChild;
}

const InputLabel = ({ title, flag }: InputLabelInterface) => {
  return (
    <StyledLabel>
      <Typography>{title}</Typography>
      <Typography>{flag}</Typography>
    </StyledLabel>

  );
};

export default memo(InputLabel);
