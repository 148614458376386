import { AxiosResponse } from 'axios';

import {
  BusinessTypeCreatePayload, BusinessTypeFilterPayload, BusinessTypeUpdateType,
} from '../models/business-types-model';
import { http } from '../services/RequestService';
import { IBusinessTypeCore } from '../types/businessTypes';
import { ICategoryCore } from '../types/category';
import { ResponsePagination } from '../utils/types';

const prefix = '/businessTypes';

export const getBusinessTypesListApi = async () => {
  const response = await http.get<AxiosResponse<ICategoryCore[]>>(`${prefix}/list`);

  return response.data.data;
};

export const getBusinessTypesByIdApi = async (id: string | number) => {
  const response = await http.get <AxiosResponse<ICategoryCore>>(`${prefix}/${id}/info`);

  return response.data.data;
};

export const createBusinessTypeApi = async (data: BusinessTypeCreatePayload) => {
  const response = await http.post<
    BusinessTypeCreatePayload,
    AxiosResponse<ICategoryCore>
    >(`${prefix}`, data);

  return response.data;
};

export const updateBusinessTypeByIdApi = async (
  id: string,
  data: BusinessTypeUpdateType,
) => {
  const response = await http.put<
    BusinessTypeUpdateType,
    AxiosResponse<{
        dataValues: ICategoryCore;
    }>
  >(`${prefix}/${id}`, data);

  return response.data.dataValues;
};

export const deleteBusinessTypeApi = async (id: string | number) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const businessTypeFilterApi = async (params: BusinessTypeFilterPayload) => {
  const response = await http.get<ResponsePagination<IBusinessTypeCore[]>>(`${prefix}`, {
    params,
  });

  return response.data;
};
