import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { Provider } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import theme from './styles/theme';
import PageLayout from './containers/PageLayout';
import store from './store';

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ConfirmProvider>
            <CssBaseline />
            <PageLayout />
          </ConfirmProvider>
        </LocalizationProvider>
      </MuiThemeProvider>
    </Provider>
  </BrowserRouter>
);

export default App;
