import Typography from '@mui/material/Typography';
import { ConfirmOptions } from 'material-ui-confirm';

import { COLORS } from '../../../../types/global/theme/colors';
import { PAYMENT_STATUS } from '../../../../types/orders';

export const paymentStatusColors = (paymentStatus: keyof typeof PAYMENT_STATUS | string) => {
  const obj:Record<string, string> = {
    not_finished: COLORS.red.main,
    payment_declined: COLORS.red.main,
    payment_approved: COLORS.green.main,
  };

  return obj[paymentStatus] || COLORS.blue.main;
};

export const getPaymentStatus = (status: keyof typeof PAYMENT_STATUS) => {
  return PAYMENT_STATUS[status];
};

export const deleteConfirmOption: ConfirmOptions = {
  title: '',
  cancellationText: 'NO',
  cancellationButtonProps: {
    variant: 'contained',
    color: 'primary',
    sx: { borderRadius: '4px' },
  },
  content: (
    <Typography lineHeight="33px" align="center" variant="h3">
      Are you sure you want to cancel this order?
    </Typography>
  ),
  dialogProps: {
    maxWidth: 'xs',
    fullWidth: true,
    PaperProps: {
      sx: {
        maxWidth: '545px',
        '.MuiDialogContent-root': {
          padding: '0',
          paddingBottom: '32px',
        },
        '.MuiDialogActions-root': {
          display: 'flex',
          padding: '0',
          justifyContent: 'center',

          button: {
            fontSize: '15px',
            lineHeight: '28px',
          },
        },
        padding: '20px',
      },
    },
  },
  confirmationText: 'YES',
  confirmationButtonProps: {
    variant: 'contained',
    color: 'error',
    sx: { borderRadius: '4px' },
  },
};
