import { AxiosResponse } from 'axios';

import { ReturnedOrderFilterPayload, ReturnedOrderSearchPayload } from '../models/return-model';
import { http } from '../services/RequestService';
import { ReturnedOrderCore } from '../types/return';
import { ResponseData } from '../utils/types';

const prefix = 'orders/return';

export const getReturnedOrdersApi = async () => {
  const response = await http.get<ResponseData<ReturnedOrderCore[]>>(prefix);

  return response.data;
};

export const searchReturnedOrdersApi = async (params: ReturnedOrderSearchPayload) => {
  const response = await http.get<
    ReturnedOrderSearchPayload, AxiosResponse<ResponseData<ReturnedOrderCore[]>>>(`${prefix}`, {
      params,
    });

  return response.data;
};

export const acceptReturnedOrderByIdApi = async (id: string) => {
  const response = await http.put(`${prefix}/accept/${id}`);

  return response.data;
};

export const declineReturnedOrderByIdApi = async (id: string) => {
  const response = await http.put(`${prefix}/decline/${id}`);

  return response.data;
};

export const filterReturnedOrdersApi = async (params: ReturnedOrderFilterPayload) => {
  const response = await http.get(`${prefix}`, { params });

  return response.data;
};
