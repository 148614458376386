import {
  memo,
  Dispatch,
  ChangeEvent,
  SetStateAction,
} from 'react';

import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import { TableData } from './helper';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody/index';

interface ITableProps {
  offset: number;
  totalBusinessTypes: number;
  rowsPerPage: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  rows: TableData[];
  editHandler:()=> void;
}

const Table = ({
  rows,
  offset,
  setOffset,
  totalBusinessTypes,
  rowsPerPage,
  setRowsPerPage,
  editHandler,
}: ITableProps) => {
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none', borderRadius: '0 0 6px 6px' }}>
        <TableContainer>
          <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead />
            <TableBody
              editHandler={editHandler}
              rows={rows}
              page={offset}
              rowsPerPage={rowsPerPage}
            />
          </MuiTable>
        </TableContainer>
        <TablePagination
          page={offset}
          component="div"
          count={totalBusinessTypes}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, newPage: number) => setOffset(newPage)}
        />
      </Paper>
    </Box>
  );
};

export default memo(Table);
