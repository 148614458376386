import { memo } from 'react';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import PageTitle from '../../common/components/PageTitle';
import BannersHolder from './components/BannersHolder';
import {
  StyledContainer,
} from './styled';

const Home = () => {
  return (
    <StyledContainer>
      <PageTitle title="Home" />

      <Breadcrumbs />

      <BannersHolder />
    </StyledContainer>
  );
};

export default memo(Home);
