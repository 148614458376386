import { styled } from '@mui/material/styles';

import Dialog from '../../common/components/Dialog';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    maxWidth: '1600px !important',
    padding: '0 30px',
  },
}));
