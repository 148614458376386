import { FC, memo } from 'react';

import { StyledPlaceholder } from './styled';

interface PlaceholderProps {
  depth: number;
}

const Placeholder: FC<PlaceholderProps> = ({ depth }) => {
  const left = depth * 24;

  return (
    <StyledPlaceholder style={{ left }} />
  );
};

export default memo(Placeholder);
