import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { COLORS } from '../../../../types/global/theme/colors';
import { FontFamilyNames } from '../../../../types/global/theme/fonts';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.grey['200'],
  border: `1px solid ${theme.palette.grey['500']}`,
  borderRadius: '4px',
  padding: '20px',
}));

export const StyledContentPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `1px solid ${COLORS.grey['700']}`,
  borderRadius: '6px',
  padding: '20px',
}));

export const StyledTitle = styled(Typography)<{component?: string}>(() => ({
  marginBottom: '10px',
  fontFamily: FontFamilyNames.CircularStdBold700,
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '22.4px',
}));
