import { ChangeEvent, memo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useForm, Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import Flag from 'react-world-flags';
import InputAdornment from '@mui/material/InputAdornment';

import Button from '../../../../../common/components/Button';
import BaseInput from '../../../../../common/components/Input/BaseInput';
import BannerCardPaper from '../../BannerCardPaper';
import {
  FormValues,
  defaultValues,
  validationSchema,
  productBannerSelect,
  sanitizeProductData,
  gettingInitialValue,
} from './helper';
import Select from '../../../../../common/components/Select';
import { attemptToCrateBannerAction, attemptToUpdateBannerAction } from '../../../../../store/actions/banners';
import { CardTypes } from '../../../../../types/enums/banners';
import { HomeAllBanner } from '../../../../../models/banner-card/home-all-model';
import BannerHeaderStatus from '../BannerHeaderStatus';

interface IProductsBannerProps {
  isNew: boolean;
  bannerData?: HomeAllBanner | null;
}

const ProductsBanner = ({ isNew, bannerData }: IProductsBannerProps) => {
  const initialValues = gettingInitialValue(bannerData || null);

  const dispatch = useDispatch();

  const {
    watch,
    control,
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormValues>({
    defaultValues: !isNew && bannerData ? initialValues : defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: FormValues) => {
    const sanitizedData = sanitizeProductData(data);

    const action = {
      bannerCardType: CardTypes.filtered,
      bannerData: sanitizedData,
    };

    dispatch(
      isNew
        ? attemptToCrateBannerAction(action)
        : attemptToUpdateBannerAction({
          ...action,
          cardId: bannerData?.id,
        }),
    );

    reset();
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('viewAll', event.target.checked);
  };

  const [limit, size, filter, viewAll] = watch(['limit', 'size', 'filter', 'viewAll']);

  return (
    <Box sx={{ maxWidth: 396 }}>
      <BannerCardPaper title="Product Banner">
        {!isNew && <BannerHeaderStatus bannerId={bannerData?.id} />}

        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
        >
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${0}.text`) }}
            label="Title Banner"
            placeholder="Text"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="us" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${1}.text`) }}
            placeholder="Text"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="am" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${2}.text`) }}
            placeholder="Text"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="ru" height="22" width="22" />
              </InputAdornment>
          )}
          />

          {productBannerSelect.map(({ name, label, options }) => (
            <Select
              key={name}
              name={name}
              label={label}
              errors={errors}
              options={options}
              setValue={setValue}
              value={watch(name)}
              id={`${name}-product-banner-select`}
            />
          ))}
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <Controller
                name="viewAll"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    key="viewAll"
                    label="View All"
                    control={(
                      <Checkbox
                        value={field.value}
                        checked={!!field.value}
                        onChange={handleCheckboxChange}
                      />
                    )}
                  />
                )}
              />
            </FormGroup>
          </FormControl>

          <Button
            disabled={
              !isSubmitting && !isDirty &&
              limit === initialValues.limit &&
               size === initialValues.size &&
              filter === initialValues.filter &&
              viewAll === initialValues.viewAll
          }
            type="submit"
            title={isNew ? 'Save' : 'Update'}
          />
        </Stack>
      </BannerCardPaper>
    </Box>
  );
};

export default memo(ProductsBanner);
