import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import { COLORS } from '../../../../types/global/theme/colors';
import { FontFamilyNames } from '../../../../types/global/theme/fonts';

export const StyledBaseInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: 16,
  fontWeight: '450',
  letterSpacing: '-0.04em',
  fontFamily: FontFamilyNames.CircularStdBook,
  transform: 'unset',
  marginBottom: '8px',

  '&.Mui-focused': {
    color: theme.palette.common.black,
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  borderRadius: 6,
  backgroundColor: theme.palette.common.white,
  boxShadow: `0 0 0 1px ${COLORS.grey['500']}`,
  fontSize: 14,
  padding: '15px',
  letterSpacing: '-0.03em',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  fontFamily: FontFamilyNames.CircularStdBook,
  fontWeight: '450',
}));

export const StyledInputBox = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  backgroundColor: theme.palette.common.white,
  boxShadow: `0 0 0 1px ${COLORS.grey['500']}`,
  fontSize: 14,
  width: '100%',
  padding: '12px 15px',
  letterSpacing: '-0.03em',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  fontFamily: FontFamilyNames.CircularStdBook,
  fontWeight: '450',
  display: 'flex',
  alignItems: 'center',

  '.MuiBox-root': {
    flexBasis: '50px',

    '.MuiFormControl-root': {
      padding: 0,

      '.MuiInputBase-root': {
        padding: '0',
        input: {
          padding: '3px 21px',
        },
      },
    },
  },
}));

export const StyledInputBoxTitle = styled(Box)(() => ({
  flex: 1,
}));
