import { memo } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import Flag from 'react-world-flags';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import BannerCardPaper from '../../BannerCardPaper';
import Button from '../../../../../common/components/Button';
import ColorPickerInput from '../../../../../common/components/ColorPickerInput';
import { FormValues, sanitizeRuningLineData, validationSchema } from './helper';
import {
  attemptToCrateBannerAction,
  attemptToUpdateBannerAction,
} from '../../../../../store/actions/banners';
import { CardTypes } from '../../../../../types/enums/banners';
import { HomeAllBanner } from '../../../../../models/banner-card/home-all-model';
import BannerHeaderStatus from '../BannerHeaderStatus';
import { initialTranlationsState, sortedCollection } from '../../../../../utils/helpers';
import BaseInput from '../../../../../common/components/Input/BaseInput';

interface IRunningLineBannerProps {
  isNew: boolean;
  bannerData?: HomeAllBanner | null;
}

const defaultValues: FormValues = {
  bgColor: '', textColor: '', translations: initialTranlationsState,
};

const RunningLineBanner = ({ isNew, bannerData }: IRunningLineBannerProps) => {
  const dispatch = useDispatch();
  const sortedTranslations = sortedCollection(bannerData?.translations);
  const initialValues = {
    bgColor: bannerData?.translation?.content?.color,
    textColor: bannerData?.translation?.content?.textColor,
    translations: sortedTranslations,
  };

  const {
    register,
    setValue,
    getValues,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormValues>({
    defaultValues: !isNew && bannerData ? initialValues : defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: FormValues) => {
    const sanitizedData = sanitizeRuningLineData(data);

    const action = {
      bannerCardType: CardTypes.runningLine,
      bannerData: sanitizedData,
    };

    dispatch(
      isNew
        ? attemptToCrateBannerAction(action)
        : attemptToUpdateBannerAction({
          ...action,
          cardId: bannerData?.id,
        }),
    );

    reset();
  };

  const bgColor = watch('bgColor');
  const textColor = watch('textColor');

  return (
    <Box sx={{ maxWidth: 396 }}>
      <BannerCardPaper title="Running Line Banner">
        {!isNew && <BannerHeaderStatus bannerId={bannerData?.id} />}

        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
        >
          <ColorPickerInput
            errors={errors}
            setValue={setValue}
            label="Background color"
            filledColorValue={getValues('bgColor')}
            inputProps={{ ...register('bgColor') }}
            id="running-line-bg-color-picker"
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${0}.text`) }}
            label="Text"
            placeholder="Text"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="us" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${1}.text`) }}
            placeholder="Text"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="am" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${2}.text`) }}
            placeholder="Text"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="ru" height="22" width="22" />
              </InputAdornment>
          )}
          />

          <ColorPickerInput
            errors={errors}
            label="Text color"
            setValue={setValue}
            filledColorValue={getValues('textColor')}
            inputProps={{ ...register('textColor') }}
            id="running-line-text-color-picker"
          />

          <Button
            disabled={
              bgColor === initialValues.bgColor && textColor === initialValues.textColor && !isDirty && !isSubmitting
            }
            type="submit"
            title={isNew ? 'Save' : 'Update'}
          />
        </Stack>
      </BannerCardPaper>
    </Box>
  );
};

export default memo(RunningLineBanner);
