import { ACCESS_TOKEN_KEY } from './constants';
import StorageManager from './storage-manager';

const getAccessToken = (): string | null => {
  const token = StorageManager.getItem(ACCESS_TOKEN_KEY);

  return token || null;
};

export default getAccessToken;
