import { memo, useEffect, useState } from 'react';

import UploadContainer from '../../UploadContainer';

interface ImageUploaderProps {
  setImageValue: (img: string, lang: string) => void;
  initialValue: string;
  id: string | undefined;
  lang: string;
  errors: any;
}

const ImageUploader = ({ setImageValue, initialValue, id, lang, errors }: ImageUploaderProps) => {
  const [image, setImage] = useState(initialValue);

  useEffect(() => {
    setImageValue(image, lang);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    setImage(initialValue);
  }, [initialValue]);

  return (
    <UploadContainer
      errors={errors}
      id={id ? `add-banner-card-update-${lang}-${id}` : `add-banner-card-${lang}`}
      imagePreivew={image}
      setImagePreview={setImage}
      inputName={`image.${lang}`}
    />
  );
};

export default memo(ImageUploader);
