import { memo } from 'react';

import Typography from '@mui/material/Typography';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';

import { PAGE_ROUTES_PRIVATE } from '../../../types/enums/routes';
import LinkRouter from '../LinkRouter';

const breadcrumbNameMap: Record<string, string> = {
  [PAGE_ROUTES_PRIVATE.HOME]: 'Home Page',
  [PAGE_ROUTES_PRIVATE.USERS]: 'Users',
  [PAGE_ROUTES_PRIVATE.ORDERS]: 'Orders',
  [PAGE_ROUTES_PRIVATE.CATEGORY]: 'Category',
  [PAGE_ROUTES_PRIVATE.ALL_PRODUCTS]: 'All Products',
  [PAGE_ROUTES_PRIVATE.PRIVACY_POLICY]: 'Privacy Policy',
  [PAGE_ROUTES_PRIVATE.BUSINESS_TYPES]: 'Business Types',
  [PAGE_ROUTES_PRIVATE.COLLECTION]: 'Collection',
  [PAGE_ROUTES_PRIVATE.RETURNS]: 'Returns',
  [PAGE_ROUTES_PRIVATE.SHOPS]: 'Shops',
};

const Breadcrumbs = () => {
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((x) => x);

  return (
    <div role="presentation">
      <MuiBreadcrumbs aria-label="breadcrumb">
        <LinkRouter variant="h5" underline="hover" color="inherit" to="/">
          Home
        </LinkRouter>
        {pathNames.map((_, index) => {
          const last = index === pathNames.length - 1;
          const to = `/${pathNames.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography variant="h5" color="text.primary" key={to}>
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <LinkRouter variant="h5" underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          );
        })}
      </MuiBreadcrumbs>
    </div>
  );
};

export default memo(Breadcrumbs);
