import { CSSProperties } from 'react';

import { Address } from '../../../../types/users';
import { Order } from '../../../../utils/types';

export interface TableData {
  name: string;
  image: string;
  address: Address;
  createdAt: string | null;
  email: string;
  phone: string;
  id: string;
  isEditMode?: boolean;
  isActive?: boolean;
}

export interface TableHeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
  styles: CSSProperties;
  isSortabe?: boolean;
}

export type UpdateType = {
  id: string; phone: string; email: string;
}

export const headCells: readonly TableHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    styles: {
      width: '230px',
      paddingLeft: '16px',
    },
    isSortabe: true,
  },
  {
    id: 'image',
    numeric: true,
    disablePadding: false,
    label: 'Avatar',
    styles: {
      width: '100px',
    },
  },
  {
    id: 'address',
    numeric: true,
    disablePadding: false,
    label: 'Location',
    styles: {
      width: '260px',
    },
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Registered Date',
    styles: {
      width: '131px',
    },
    isSortabe: true,
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Contact',
    styles: {
      width: '91px',
    },
    isSortabe: true,
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone Number',
    styles: {
      width: '136px',
    },
    isSortabe: true,
  },
];

export const createData = ({
  name,
  image,
  address,
  createdAt,
  email,
  phone,
  id,
}: TableData): TableData => {
  return {
    name,
    image,
    address,
    createdAt,
    email,
    phone,
    id,
  };
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

// TODO: this may need in future
// # client side ordering (sorting)
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
