import { FC, memo } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from 'react-hook-form';

import Dialog from '../../../common/components/Dialog';
import SaveButton from '../../../common/components/Button';
import {
  createCategoryApi,
} from '../../../requests/category';
import { CategoryCreatePayload } from '../../../models/category-model';
import { initialTranlationsState, replceSpacingWithUnderscore } from '../../../utils/helpers';
import InputsWithFlag from '../../../common/components/InputsWithFlag';

interface CreateCategoryModalProps {
  open: boolean;
  handleClose: () => void;
  createHandler: () => void;
  parentId?: string | number;
}

const CreateCategoryModal: FC<CreateCategoryModalProps> = ({
  open,
  handleClose,
  parentId,
  createHandler,
}) => {
  const methods = useForm<CategoryCreatePayload>({
    defaultValues: {
      isActive: false,
      translations: initialTranlationsState,
    },
  });

  const {
    handleSubmit,
    reset,
  } = methods;

  const onSubmit = (data: CategoryCreatePayload) => {
    const createCategory = async () => {
      try {
        if (parentId) {
          data.parentId = parentId;
        }

        const key = replceSpacingWithUnderscore(data.translations[0].text);

        await createCategoryApi({ ...data, key });
        reset();
        createHandler();
      } catch (e) {
        throw e;
      }
    };

    createCategory();
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <FormProvider {...methods}>
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
          sx={{ width: '100%', padding: '0 20px 20px' }}
        >

          <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
            {`Add ${parentId ? 'subcategory' : 'category'}`}
          </Typography>
          <InputsWithFlag />
          <SaveButton type="submit" title="Create" />
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default memo(CreateCategoryModal);
