import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';

import { StyledPreview } from './styled';

interface CustomDragPreviewProps {
  monitorProps: DragLayerMonitorProps<unknown>;
}

const CustomDragPreview = ({ monitorProps }:CustomDragPreviewProps) => {
  const { item } = monitorProps;

  return (
    <StyledPreview>
      <Typography>{item.text}</Typography>
    </StyledPreview>
  );
};

export default memo(CustomDragPreview);
