import { memo } from 'react';

import Typography from '@mui/material/Typography';

import { ISingleOrderCore } from '../../../types/orders';
import { StyledPaper, StyledContentPaper, StyledBox, StyledBoxTitle } from '../styled';
import { addressInitialValue } from './helpers';

const BuyerInfo = ({
  buyer: { firstName = '', email = '', phone = '' },
  shippingAddress,
  shippingMethod = '',
}: ISingleOrderCore) => {
  const { street, country, city, postalCode, apartment } = shippingAddress ?? addressInitialValue;

  return (
    <StyledPaper>
      <StyledContentPaper>
        <StyledBox>
          <StyledBoxTitle>Customer</StyledBoxTitle>
          <Typography>{firstName}</Typography>
          <Typography>{email}</Typography>
          <Typography>{phone}</Typography>
        </StyledBox>
        <StyledBox>
          <StyledBoxTitle>Shipping Address</StyledBoxTitle>
          <Typography>{`${street}, apt ${apartment}`}</Typography>
          <Typography>{`${city}, ${country}`}</Typography>
          <Typography>{`Postal Code: ${postalCode}`}</Typography>
        </StyledBox>
        <StyledBox>
          <StyledBoxTitle> Shipping Method</StyledBoxTitle>
          <Typography>{shippingMethod}</Typography>
        </StyledBox>
      </StyledContentPaper>
    </StyledPaper>
  );
};

export default memo(BuyerInfo);
