import { memo, useState } from 'react';

import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import PageTitle from '../../common/components/PageTitle';
import { StyledContainer } from '../components/StyledContainer';
import CategoryBreadcrumbs from './components/CategoryBreadcrumbs';
import Specifications from './components/Specifications';
import CollapsibleTable from './components/Table';
import SpecificationsGroup from './components/SpecificationsGroup';
import { getSpecificationByCategoryId } from '../../requests/category';
import useMount from '../../hooks/useMount';
import { SpecificationGroupCore } from '../../models/category-specifications-model';

const SpecificationsPage = () => {
  const { id: categoryId } = useParams();
  const [currentCategory, setCurrentCategory] = useState<SpecificationGroupCore | null>(null);
  const getCategoryInfo = async () => {
    if (categoryId) {
      const response = await getSpecificationByCategoryId(categoryId);

      if (response) {
        setCurrentCategory(response);
      }
    }
  };

  useMount(() => {
    getCategoryInfo();
  });

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Product Categories" />
          <CategoryBreadcrumbs />
        </Box>
      </StyledContainer>
      <SpecificationsGroup
        currentCategory={currentCategory}
        creatHandler={getCategoryInfo}
      />
      {
        currentCategory?.specificationGroups?.length
          ? (
            <>
              <Specifications currentCategory={currentCategory} getCategoryInfo={getCategoryInfo} />
              <CollapsibleTable currentCategory={currentCategory} getCategoryInfo={getCategoryInfo} />
            </>
          )
          : <Typography>Still no have specification group</Typography>
      }

    </>
  );
};

export default memo(SpecificationsPage);
