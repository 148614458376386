import { IAuthState } from '../types/auth';
import { BaseAction } from '../../utils/types';
import { ACTION_STATUSES } from '../../utils/constants';
import { AUTH_ACTIONS } from '../actions/auth';
import getAccessToken from '../../utils/access-token';
import { ISetSignInSuccessPayload } from '../../models/auth-model';

export const initialState = (): IAuthState => ({
  loading: false,
  status: ACTION_STATUSES.HOLD,
  isAuth: !!getAccessToken(),
  error: '',
  user: null,
});

const ATTEMPT_TO_SIGN_IN = (state: IAuthState) => ({
  ...state,
  loading: true,
  status: ACTION_STATUSES.PENDING,
});

const SET_USER = (state: IAuthState, action: BaseAction) => ({
  ...state,
  user: action.payload,
});

const SET_USER_FAILURE = (state: IAuthState, { payload }: BaseAction) => ({
  ...state,
  loading: false,
  status: ACTION_STATUSES.FAILED,
  error: payload,
});

const SET_SIGN_IN_SUCCEED = (
  state: IAuthState,
  action : BaseAction<ISetSignInSuccessPayload>,
) => {
  const { accessToken } = action.payload as ISetSignInSuccessPayload;

  return ({
    ...state,
    loading: false,
    status: ACTION_STATUSES.SUCCEED,
    isAuth: !!accessToken,
    error: '',
  });
};

const SET_SIGN_IN_FAILURE = (state: IAuthState, { payload }: BaseAction) => ({
  ...state,
  loading: false,
  status: ACTION_STATUSES.FAILED,
  error: payload,
  user: null,
});

const SIGN_OUT_SUCCESS = (state: IAuthState) => ({
  ...state,
  loading: false,
  status: ACTION_STATUSES.SUCCEED,
  isAuth: false,
  user: null,
});

const SIGN_OUT_FAILURE = (state: IAuthState, action: BaseAction) => ({
  ...state,
  loading: false,
  status: ACTION_STATUSES.FAILED,
  error: action.payload,
});

export default (
  state: IAuthState = initialState(),
  action: BaseAction,
): IAuthState => {
  switch (action.type) {
    case AUTH_ACTIONS.ATTEMPT_TO_SIGN_IN:
      return ATTEMPT_TO_SIGN_IN(state);
    case AUTH_ACTIONS.SET_SIGN_IN_SUCCEED:
      return SET_SIGN_IN_SUCCEED(state, action);
    case AUTH_ACTIONS.SET_SIGN_IN_FAILURE:
      return SET_SIGN_IN_FAILURE(state, action);
    case AUTH_ACTIONS.SIGN_OUT_SUCCESS:
      return SIGN_OUT_SUCCESS(state);
    case AUTH_ACTIONS.SIGN_OUT_FAILURE:
      return SIGN_OUT_FAILURE(state, action);
    case AUTH_ACTIONS.SET_USER:
      return SET_USER(state, action);
    case AUTH_ACTIONS.SET_USER_FAILURE:
      return SET_USER_FAILURE(state, action);

    default:
      return state;
  }
};
