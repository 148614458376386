import { memo } from 'react';

import Typography from '@mui/material/Typography';

import { StyledPaper, StyledContentPaper, StyledBox } from '../styled';
import { IShopInfo } from '../helpers';

interface OrderNotesProps {
  info: IShopInfo;
}

const Business = ({ info: {
  bankAccountNumber, registrationNumber, registrationName, businessTypeName,
} }: OrderNotesProps) => {
  return (
    <StyledPaper>
      <StyledContentPaper>
        <StyledBox>
          <Typography color="blue">
            Business Type
          </Typography>
          <Typography>
            {businessTypeName}
          </Typography>
          <Typography color="blue">
            Business Name
          </Typography>
          <Typography>
            {registrationName}
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography color="blue">
            Registration Number
          </Typography>
          <Typography>
            {registrationNumber}
          </Typography>
          <Typography color="blue">
            Bank Account Number
          </Typography>
          <Typography>
            {bankAccountNumber}
          </Typography>
        </StyledBox>
      </StyledContentPaper>
    </StyledPaper>
  );
};

export default memo(Business);
