import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

import { COLORS } from '../../../types/global/theme/colors';
import { FontFamilyNames } from '../../../types/global/theme/fonts';

export const StyledInput = styled('input')({
  border: '0',
  padding: '0',
  width: '200%',
  height: '200%',
  cursor: 'pointer',
  transform: 'translate(-25%, -25%)',
});

export const StyledInputBox = styled(Box)({
  width: '20px',
  height: '20px',
  borderRadius: '50px',
  overflow: 'hidden',
  position: 'absolute',
  right: '15px',
  zIndex: '9',
  transform: 'translateY(-50%)',
  top: '50%',
  border: `1px solid ${COLORS.grey['50']}`,
});

export const StyledColorPickerContent = styled(Box)({
  position: 'relative',
});

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: 16,
  fontWeight: '450',
  letterSpacing: '-0.04em',
  fontFamily: FontFamilyNames.CircularStdBook,
  transform: 'unset',
  marginBottom: '8px',

  '&.Mui-focused': {
    color: theme.palette.common.black,
  },
}));
