import { memo, useEffect, useState } from 'react';

import Flag from 'react-world-flags';
import Stack from '@mui/material/Stack';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { yupResolver } from '@hookform/resolvers/yup';

import InputLabel from '../../../../common/components/InputLabel/InputLabel';
import BaseInput from '../../../../common/components/Input/BaseInput';
import { Translation } from '../../../../types/category';
import SectionTitle from '../../../../common/components/SectionTitle/SectionTitle';
import SaveButton from '../../../../common/components/Button';
import { createSpecificationApi } from '../../../../requests/specification';
import { initialTranlationsState, replceSpacingWithUnderscore, sortedCollection } from '../../../../utils/helpers';
import { SpecificationGroupCore } from '../../../../models/category-specifications-model';
import { validationSchema } from '../../helper';
import { FLAGS_LANGUAGES } from '../../../../utils/constants';

interface FormData {
  translations: Translation[];
}

interface SpecificationsProps {
  currentCategory: SpecificationGroupCore | null;
  getCategoryInfo:()=> void;
}

const Specifications = ({ currentCategory, getCategoryInfo }: SpecificationsProps) => {
  const [isShow, setIsShow] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      translations: initialTranlationsState,
    },
    resolver: yupResolver(validationSchema),
  });

  const [groupId, setGroupId] = useState<string>('');
  const { translations } = getValues();

  useEffect(() => {
    setGroupId((prev) => groupId || currentCategory?.specificationGroups[0]?.id || prev);
  }, [currentCategory, groupId]);

  const onSubmit = () => {
    const createSpecification = async () => {
      const key = replceSpacingWithUnderscore(translations[0].text);
      const body = {
        translations,
        groupId,
        isActive: true,
        key,
      };

      const response = await createSpecificationApi(body);

      if (!response) {
        reset();
        getCategoryInfo();
      }
    };

    createSpecification();
  };

  const handleRemove = () => {
    setIsShow(false);
  };

  return (
    <>
      <Box onClick={() => setIsShow(true)}>
        <SectionTitle title="+ New Value" />
      </Box>
      { isShow && (
      <Paper
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={{ width: '100%', padding: '20px', marginBottom: '20px' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <RemoveCircleOutlineIcon onClick={handleRemove} />
        </Box>
        <InputLabel title="Selected category" />
        <TextField
          size="small"
          sx={{ width: '100%', margin: '10px 0' }}
          select
          value={groupId}
          onChange={(e) => setGroupId(e.target.value)}
        >
          {currentCategory?.specificationGroups.map((specificationGroup) => {
            const sortedTranslations = sortedCollection(specificationGroup?.translations);

            return (
              <MenuItem key={specificationGroup.id} value={specificationGroup.id}>
                {sortedTranslations[1]?.text}
              </MenuItem>
            );
          })}
        </TextField>
        <Stack
          direction="row"
          sx={{ width: '100%', padding: '20px 0', justifyContent: 'space-between' }}
        >
          {FLAGS_LANGUAGES.map((item, idx) => (
            <BaseInput
              key={item}
              label={<InputLabel title="Value name" flag={<Flag code={item} height="22" width="22" />} />}
              errors={errors}
              inputProps={{ ...register(`translations.${idx}.text`) }}
              placeholder="Input Name"
              sx={{ minWidth: '230px' }}
            />
          ))}
        </Stack>
        <SaveButton type="submit" title="Create" sx={{ width: '100%' }} />
      </Paper>
      )}
    </>
  );
};

export default memo(Specifications);
