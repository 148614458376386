import { memo } from 'react';

import Flag from 'react-world-flags';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';

import { FLAGS_LANGUAGES } from '../../../utils/constants';

interface LanguageTextFieldProps {
  item: string;
  idx: number;
  placeholder: string;
}

const LanguageTextField = ({ item, idx, placeholder }: LanguageTextFieldProps) => {
  const { register } = useFormContext();

  return (
    <TextField
      key={item}
      sx={{ width: '100%', marginTop: '18px' }}
      InputProps={{
        placeholder,
        ...register(`translations.${idx}.text`, { required: true }),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small">
              <Flag code={item} height="22" width="22" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const InputsWithFlag = () => {
  const renderLanguageTextFields = () => {
    return FLAGS_LANGUAGES.map((item, idx) => (
      <LanguageTextField
        key={item}
        item={item}
        idx={idx}
        placeholder="Business Type Name"
      />
    ));
  };

  return (
    <div>
      {renderLanguageTextFields()}
    </div>
  );
};

export default memo(InputsWithFlag);
