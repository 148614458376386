import { memo } from 'react';

import { useDispatch } from 'react-redux';

import { attemptToDisconnectBannerAction } from '../../../../../store/actions/banners';
import { StyledContainer, StyledButton } from './styled';

interface IBannerHeaderStatusProps {
  bannerId?: string;
}

const BannerHeaderStatus = ({ bannerId }: IBannerHeaderStatusProps) => {
  const dispatch = useDispatch();
  const handleDeleteClick = () => {
    dispatch(attemptToDisconnectBannerAction({ bannerId }));
  };

  return (
    <StyledContainer>
      <StyledButton onClick={handleDeleteClick} title="Delete" color="info" variant="outlined">Delete</StyledButton>
    </StyledContainer>
  );
};

export default memo(BannerHeaderStatus);
