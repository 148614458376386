import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledTitleBox = styled(Box)(() => ({
  h2: {
    fontFamily: 'Circular Std',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '33px',
    letterSpacing: '-0.04em',
    color: '#000000',
    marginBottom: '25px',
  },
}));
