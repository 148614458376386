import { memo } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import AccountCircle from '@mui/icons-material/AccountCircle';

interface ILeftPartProps {
  menuId: string;
  mobileMenuId: string;
  handleMobileMenuOpen: any;
  handleProfileMenuOpen: any;
}

const RightPart = ({
  menuId,
  mobileMenuId,
  handleMobileMenuOpen,
  handleProfileMenuOpen,
}: ILeftPartProps) => {
  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <IconButton
          edge="end"
          size="large"
          color="inherit"
          aria-haspopup="true"
          aria-controls={menuId}
          onClick={handleProfileMenuOpen}
          aria-label="account of current user"
        >
          <AccountCircle />
        </IconButton>
      </Box>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          color="inherit"
          aria-haspopup="true"
          aria-label="show more"
          aria-controls={mobileMenuId}
          onClick={handleMobileMenuOpen}
        >
          <MoreIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default memo(RightPart);
