import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { COLORS } from '../../../../types/global/theme/colors';

export const StyledPreview = styled(Box)(() => ({
  alignItems: 'center',
  background: COLORS.white,
  borderRadius: '4px',
  display: 'inline-grid',
  fontSize: '14px',
  gap: '8px',
  gridTemplateColumns: 'auto auto',
  padding: '4px 8px',
  pointerEvents: 'none',
  boxShadow: '0 12px 24px -6px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.08)',

}));
