import { Specification, SpecificationGroup } from '../../../../../models/category-specifications-model';
import { Translation } from '../../../../../types/category';

export const sanitizeSpecificationGroup = (data: SpecificationGroup[]) => {
  return data.map(({ id }, index) => ({ id, sort: index }));
};

export const sanitizeSpecifications = (data: Specification[]) => {
  return data.map(({ id }, index) => ({ id, sort: index }));
};

export interface RowProps {
  row: Specification[] | null;
  getCategoryInfo: () => void;
  toggleCollapse:() => void;
  open: boolean;
}

export interface FormData {
  specification: Specification;
  translations: Translation[];
}
