export interface Location {
  zip: string;
  city: string;
  state: string;
  street: string;
  address: string;
  country: string;
}

export interface AdditionalInfo {
  location: Location;
  lookingFor: string;
  socialLinks: any;
  specialties: string;
  profileLinks: any;
}

export type Address = {
  id: string;
  userId: string;
  countryCode?: string;
  country: string;
  state: string;
  city: string;
  street: string;
  apartment: string;
  postalCode: string;
  isDefault: boolean;
  lat: string;
  lot?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}
export interface IUserCore {
  id: string;
  username: string;
  lastName: string;
  email: string;
  firstName: string;
  isSeller: boolean;
  isActive: boolean;
  bio: string;
  continentId?: any;
  isVerified: boolean;
  role: string;
  image?: any;
  address: Address;
  phone: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
}

export enum UserFilterMenuEnum {
  'ALL' = 1,
  'ALL_BUYERS' = 2,
  'ALL_SELLERS' = 3,
  'ADMIN' = 4,
}
