import { ChangeEventHandler, memo, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../../common/components/PageTitle';
import { StyledContainer } from '../components/StyledContainer';
import SearchField from '../../common/components/SearchField';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import Table from './Table';
import { ProductsSearchPayload } from '../../models/product-model';
import Loader from '../../common/components/Loader';
import { filterReturnedOrdersApi, searchReturnedOrdersApi } from '../../requests/returns';
import { ReturnedOrderCore } from '../../types/return';
import { Order } from '../../utils/types';
import { TableData } from './Table/helper';

const Returns = () => {
  const [orders, setOrders] = useState<ReturnedOrderCore[]>([]);
  const [totalOrders, setTotalOrders] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>();
  const { page: queryOffset } = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const [offset, setOffset] = useState(queryOffset ? +queryOffset : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isEqualQuery = offset === (queryOffset && +queryOffset);

  const getReturnedOrders = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearch('');

      const offsetOrder = offset * rowsPerPage;

      const offSetValue = (offsetOrder <= totalOrders || isEqualQuery) ? offsetOrder : totalOrders;
      const payloadData: ProductsSearchPayload = {
        limit: rowsPerPage,
        offset: offSetValue,
      };

      if (orderBy) {
        payloadData.orderBy = order.toUpperCase();
        payloadData.sortBy = orderBy;
      }

      const { data, meta: { pagination: { total } } } = await searchReturnedOrdersApi(payloadData);

      setOrders(data);
      setTotalOrders(total);

      navigate({
        search: offset ? `?page=${offset}` : '',
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, orderBy, order, navigate, totalOrders]);

  useEffect(() => {
    if (search) {
      const searchProducts = async () => {
        try {
          const { data, meta: { pagination: { total } } } = await searchReturnedOrdersApi({
            searchKey: search,
            limit: rowsPerPage,
            offset: queryOffset ? 0 : offset * rowsPerPage,
          });

          setOrders(data);
          setTotalOrders(total);
          queryOffset && setOffset(0);
          navigate({ search: '' });
        } catch (error) {
          throw error;
        }
      };

      searchProducts();
    } else {
      getReturnedOrders();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, search, navigate]);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = debounce(
    (event) => {
      setSearch(event.target.value);
    },
    1000,
  );

  const handleSortRequest = useCallback(
    async (ascOrDesc: Order, sort: keyof TableData) => {
      try {
        setIsLoading(true);
        setSearch('');

        const { data, meta: { pagination: { total } } } = await filterReturnedOrdersApi({
          orderBy: ascOrDesc.toUpperCase(),
          sortBy: sort,
          offset: 0,
          limit: rowsPerPage,
        });

        setOffset(0);
        setOrder(ascOrDesc);
        setOrderBy(sort);
        setOrders(data);
        setTotalOrders(total);
      } finally {
        setIsLoading(false);
      }
    },
    [rowsPerPage],
  );

  if (isLoading) {
    return <Loader sx={{ justifyContent: 'center', padding: '10px' }} />;
  }

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Returns" />
          <Breadcrumbs />
        </Box>

        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
            onChange={handleSearchChange}
          />
        </Box>
      </StyledContainer>
      {orders.length
        ? (
          <Paper>
            <Box>
              <Table
                order={order}
                orders={orders}
                offset={offset}
                orderBy={orderBy}
                setOffset={setOffset}
                totalOrders={totalOrders}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                getReturnedOrders={getReturnedOrders}
                handleSortRequest={handleSortRequest}
              />

            </Box>
          </Paper>
        )
        : (
          <Typography>Returned order is not found!</Typography>
        )}
    </>
  );
};

export default memo(Returns);
