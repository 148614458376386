import { AxiosResponse } from 'axios';

import {
  ShopsSearchPayload,
  UpdateShopsPayloadType,
  ChangeFeaturedPayload,
  ShopsFilterPayload,
  ShopsFilterResponseType,
} from '../models/shops-model';
import { http } from '../services/RequestService';
import { IAllShopsCore } from '../types/shops';
import { ResponseData } from '../utils/types';

const prefix = '/shops';

export const getAllShopsApi = async () => {
  const response = await http.get<ResponseData<IAllShopsCore[]>>(prefix);

  return response.data;
};

export const getShopByIdApi = async (id: string) => {
  const response = await http.get(`${prefix}/${id}/info`);

  return response.data;
};

export const updateShopProductByIdApi = async (
  id: string,
  data: UpdateShopsPayloadType,
) => {
  const response = await http.put<
    UpdateShopsPayloadType,
    ShopsFilterResponseType
  >(`${prefix}/${id}`, data);

  return response.data;
};

export const deactivateShopApi = async (
  id: string,
  data: UpdateShopsPayloadType,
) => {
  const response = await http.patch<
    UpdateShopsPayloadType,
    ShopsFilterResponseType
  >(`${prefix}/activate/${id}`, data);

  return response.data;
};

export const deleteShopApi = async (
  id: string,
) => {
  const response = await http.delete<
    UpdateShopsPayloadType,
    ShopsFilterResponseType
  >(`${prefix}/${id}`);

  return response.data;
};

export const shopDeleteApi = async (id: string) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const changeFeaturedApi = async (id: string, data: ChangeFeaturedPayload) => {
  const response = await http.patch<ChangeFeaturedPayload>(`${prefix}/featured/${id}`, data);

  return response.data;
};

export const searchShopsApi = async (params: ShopsSearchPayload) => {
  const response = await http.get<
    ShopsSearchPayload, AxiosResponse<ResponseData<IAllShopsCore[]>>>(`${prefix}`, {
      params,
    });

  return response.data;
};

export const filterShopsApi = async (params: ShopsFilterPayload) => {
  const response = await http.get<
    ShopsFilterPayload,
    AxiosResponse<ShopsFilterResponseType>
  >(`${prefix}`, { params });

  return response.data;
};
