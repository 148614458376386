import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { COLORS } from '../../../types/global/theme/colors';

export const StyledSearchField = styled(TextField)(({ theme }) => ({
  '& label': {
    color: COLORS.blue[50],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.light,
      borderWidth: '2px',
    },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: theme.palette.primary.light,
    },
  },
}));
