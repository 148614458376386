import { AxiosResponse } from 'axios';

import { HomeAllBanner } from '../models/banner-card/home-all-model';
import { http } from '../services/RequestService';
import { SortPayload } from '../utils/types';

const prefix = '/cards';

export const getAllBannersApi = async () => {
  const response = await http.get<AxiosResponse<HomeAllBanner[]>>(`${prefix}?translations=true`);

  return response.data.data;
};

export const createHomeBannerConntectApi = async ({
  cardId,
}: { cardId: string }) => {
  const response = await http.post(prefix, { cardId });

  return response;
};

export const createHomeBannerDisconntectApi = async ({
  bannerId,
}: { bannerId?: string }) => {
  const response = await http.delete(`${prefix}/${bannerId}`);

  return response;
};

export const homeBannerCardsSortApi = async (data: SortPayload) => {
  const response = await http.patch<SortPayload>(`${prefix}/sort`, data);

  return response;
};
