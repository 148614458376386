import {
  memo,
  useState,
  useCallback,
  useEffect,
} from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import Table from './components/Table/index';
import Loader from '../../common/components/Loader/index';
import { sanitizeBusinessTypes, TableData } from './components/Table/helper';
import { StyledContainer } from '../components/StyledContainer';
import PageTitle from '../../common/components/PageTitle';
import { businessTypeFilterApi } from '../../requests/business-types';
import { BusinessTypeFilterPayload } from '../../models/business-types-model';
import CreateBusinessTypeModal from './components/CreateBusinessType/CreateBusinessTypeModal';
import Button from '../../common/components/Button';

const BusinessTypes = () => {
  const navigate = useNavigate();
  const [businessTypes, setBusinessTypes] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalBusinessTypes, setTotalBusinessTypes] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { page: queryOffset } = queryString.parse(window.location.search);
  const [offset, setOffset] = useState(queryOffset ? +queryOffset : 0);
  const isEqualQuery = offset === (queryOffset && +queryOffset);

  const getBusinessTypes = useCallback(async () => {
    try {
      setIsLoading(true);

      const offsetOrder = offset * rowsPerPage;
      const offSetValue = (offsetOrder <= totalBusinessTypes || isEqualQuery) ? offsetOrder : totalBusinessTypes;

      const payloadData: BusinessTypeFilterPayload = {
        limit: rowsPerPage,
        offset: offSetValue,
      };

      const { data, meta: { pagination } } = await businessTypeFilterApi(payloadData);

      setBusinessTypes(sanitizeBusinessTypes(data));
      setTotalBusinessTypes(pagination.total);

      navigate({
        search: offset ? `?page=${offset}` : '',
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, totalBusinessTypes, navigate]);

  const [open, setOpen] = useState(false);

  const createHandler = () => {
    setOpen(false);
    getBusinessTypes();
  };

  useEffect(() => {
    getBusinessTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage]);

  if (isLoading) {
    return <Loader sx={{ justifyContent: 'center', padding: '10px' }} />;
  }

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Business Types" />
          <Breadcrumbs />
        </Box>
      </StyledContainer>
      <Paper sx={{ borderRadius: '6px 6px 0 0', boxShadow: 'none' }}>
        <Box sx={{ padding: '10px', display: 'flex', justifyContent: 'end' }}>
          <Button
            sx={{ height: '45px' }}
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        </Box>
      </Paper>
      {businessTypes?.length ? (
        <Table
          editHandler={getBusinessTypes}
          rows={businessTypes}
          offset={offset}
          setOffset={setOffset}
          totalBusinessTypes={totalBusinessTypes}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : (
        <Typography>Still there is no business type</Typography>
      )}

      <CreateBusinessTypeModal
        open={open}
        handleClose={() => setOpen(false)}
        createHandler={createHandler}
      />
    </>
  );
};

export default memo(BusinessTypes);
