import {
  memo,
  useState,
  Dispatch,
  useEffect,
  ChangeEvent,
  SetStateAction,
  MouseEvent,
} from 'react';

import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import { TableData } from './helper';
import TableHead from './TableHead';
import TableBody from './TableBody/index';
import { Order } from '../../../utils/types';
import { AllProductsCore } from '../../../types/allProducts';

interface ITableProps {
  order: Order;
  offset: number;
  products: AllProductsCore[];
  totalProducts: number;
  rowsPerPage: number;
  orderBy?: keyof TableData;
  setOffset: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  handleDeleteRequest: (userId: string) => Promise<void>;
  handleActivateRequest: (productId: string, isActive: boolean) => Promise<void>;
  getAllProducts: () => void;
  handleSortRequest: (order: Order, sort: keyof TableData) => Promise<void>;
}

const Table = ({
  order,
  products,
  offset,
  orderBy,
  setOffset,
  totalProducts,
  rowsPerPage,
  setRowsPerPage,
  handleDeleteRequest,
  handleActivateRequest,
  getAllProducts,
  handleSortRequest,
}: ITableProps) => {
  const [rows, setRows] = useState<AllProductsCore[]>([]);

  useEffect(() => {
    setRows(products);
  }, [products]);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    handleSortRequest(isAsc ? 'desc' : 'asc', property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody
              rows={rows}
              page={offset}
              rowsPerPage={rowsPerPage}
              handleDeleteRequest={handleDeleteRequest}
              handleActivateRequest={handleActivateRequest}
              getAllProducts={getAllProducts}
            />
          </MuiTable>
        </TableContainer>

        <TablePagination
          page={offset}
          component="div"
          count={totalProducts}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, newPage: number) => setOffset(newPage)}
        />
      </Paper>
    </Box>
  );
};

export default memo(Table);
