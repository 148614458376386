import { memo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Flag from 'react-world-flags';

import PageTitle from '../../common/components/PageTitle';
import { StyledContainer } from '../components/StyledContainer';
import SearchField from '../../common/components/SearchField';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import { StyledTabsChip } from '../components/StyledTabs';
import Button from '../../common/components/Button';
import { StyledButton, StyledDeleteContainer } from './styled';
import BaseInput from '../../common/components/Input/BaseInput';
import LinkRouter from '../../common/components/LinkRouter';

const Collection = () => {
  const handleDeleteClick = () => {
    console.log('handleDeleteClick');
  };

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Collection" />
          <Breadcrumbs />
        </Box>

        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
          />
        </Box>
      </StyledContainer>
      <LinkRouter to="/collection/new-collection" color="inherit" sx={{ textDecoration: 'none' }}>
        <StyledTabsChip
          label={<Typography variant="h2" component="span">Create +</Typography>}
          variant="outlined"
        />
      </LinkRouter>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Box sx={{ width: 350, marginTop: '13px', marginRight: '15px' }}>
          <Paper sx={{ padding: '20px' }}>
            <StyledDeleteContainer>
              <Typography>Collection Name</Typography>
              <StyledButton
                onClick={handleDeleteClick}
                title="Delete"
                color="info"
                variant="outlined"
              >
                Delete
              </StyledButton>
            </StyledDeleteContainer>
            <Stack
              component="form"
              spacing="18px"
              direction="column"
            >
              <BaseInput
                placeholder="Collection name"
                endAdornment={(
                  <InputAdornment position="end">
                    <Flag code="us" height="22" width="22" />
                  </InputAdornment>
          )}
              />
              <BaseInput
                sx={{ paddingLeft: '30%' }}
                placeholder="Collection name"
                value="+ Add Products"
              />
              <Button type="submit" title="Save" />
            </Stack>
          </Paper>
        </Box>
        <Box sx={{ width: 350, marginTop: '13px', marginRight: '15px' }}>
          <Paper sx={{ padding: '20px' }}>
            <StyledDeleteContainer>
              <Typography>Collection Name</Typography>
              <StyledButton
                onClick={handleDeleteClick}
                title="Delete"
                color="info"
                variant="outlined"
              >
                Delete
              </StyledButton>
            </StyledDeleteContainer>
            <Stack
              component="form"
              spacing="18px"
              direction="column"
            >
              <BaseInput
                placeholder="Collection name"
                endAdornment={(
                  <InputAdornment position="end">
                    <Flag code="us" height="22" width="22" />
                  </InputAdornment>
          )}
              />
              <BaseInput
                sx={{ paddingLeft: '30%' }}
                placeholder="Collection name"
                value="+ Add Products"
              />
              <Button type="submit" title="Save" />
            </Stack>
          </Paper>
        </Box>
      </Box>

    </>
  );
};

export default memo(Collection);
