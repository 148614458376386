import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';

import { FontFamilyNames } from '../../../../types/global/theme/fonts';

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '356px',
  objectFit: 'contain',
}));

export const StyledLabel = styled(InputLabel)(() => ({
  fontSize: 16,
  fontWeight: '450',
  letterSpacing: '-0.04em',
  fontFamily: FontFamilyNames.CircularStdBook,
  transform: 'unset',
  marginBottom: '8px',
}));
