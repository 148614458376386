import { AxiosResponse } from 'axios';

import {
  ProductsSearchPayload,
  UpdateProductsPayloadType,
  ProductUpdateResponseType,
  ChangeFeaturedPayload,
  ProductFilterPayload,
  ProductFilterResponseType,
} from '../models/product-model';
import { http } from '../services/RequestService';
import { AllProductsCore } from '../types/allProducts';
import { ResponseData } from '../utils/types';

const prefix = 'listings';

export const getAllProductsApi = async () => {
  const response = await http.get<ResponseData<AllProductsCore[]>>(prefix);

  return response.data;
};

export const getProductByIdApi = async (id: string) => {
  const response = await http.get(`${prefix}/${id}`);

  return response.data;
};

export const updateProductByIdApi = async (
  id: string,
  data: UpdateProductsPayloadType,
) => {
  const response = await http.put<
    UpdateProductsPayloadType,
    ProductUpdateResponseType
  >(`${prefix}/${id}`, data);

  return response.data;
};

export const deactivateProductApi = async (
  id: string,
  data: UpdateProductsPayloadType,
) => {
  const response = await http.patch<
    UpdateProductsPayloadType,
    ProductUpdateResponseType
  >(`${prefix}/admin/activate/${id}`, data);

  return response.data;
};

export const productDeleteApi = async (id: string) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const changeFeaturedApi = async (id: string, data: ChangeFeaturedPayload) => {
  const response = await http.patch<ChangeFeaturedPayload>(`${prefix}/featured/${id}`, data);

  return response.data;
};

export const searchProductsApi = async (params: ProductsSearchPayload) => {
  const response = await http.get<
    ProductsSearchPayload, AxiosResponse<ResponseData<AllProductsCore[]>>>(`${prefix}`, {
      params,
    });

  return response.data;
};

export const filterProductsApi = async (params: ProductFilterPayload) => {
  const response = await http.get<
    ProductFilterPayload,
    AxiosResponse<ProductFilterResponseType>
  >(`${prefix}`, { params });

  return response.data;
};
