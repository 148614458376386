import { memo } from 'react';

import isEqual from 'lodash/isEqual';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import BannerCardPaper from '../../BannerCardPaper';
import BaseInput from '../../../../../common/components/Input/BaseInput/index';
import Tabs from './Tabs/index';
import {
  NumberOfBannersEndAdornment,
  StyledInputBoxTitle,
  StyledIntervalInSlidesBox,
} from './styled';
import Button from '../../../../../common/components/Button';
import {
  FormValues,
  defaultValues,
  validationSchema,
  sanitizeCarouselData,
  defaultBannerItem,
  MAX_BANNER_COUNT,
  IncreaseOrDecrese,
  makeTranslationsArray,
  initialTranslationsValue,
} from './helper';
import { CardTypes } from '../../../../../types/enums/banners';
import {
  attemptToCrateBannerAction,
  attemptToUpdateBannerAction,
} from '../../../../../store/actions/banners';
import { HomeAllBanner } from '../../../../../models/banner-card/home-all-model';
import BannerHeaderStatus from '../BannerHeaderStatus';
import ErrorMessage from '../../../../../common/components/ErrorMessage';

interface IImageCarouselBannerProps {
  isNew?: boolean;
  bannerData?: HomeAllBanner | null;
}

const ImageCarouselBanner = ({
  isNew,
  bannerData,
}: IImageCarouselBannerProps) => {
  const initialValues = {
    allBanners: {
      numberOfBanners: bannerData?.translation?.content?.numberOfBanners,
      intervalInSlides: bannerData?.translation?.content?.intervalInSlides,
      items: bannerData?.translation?.content?.items,
      translations: bannerData ? makeTranslationsArray(bannerData) : initialTranslationsValue,
    },
  };

  const dispatch = useDispatch();

  const methods = useForm<FormValues>({
    defaultValues: !isNew && bannerData
      ? initialValues
      : defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    watch,
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const numberOfBannersValue = watch('allBanners.numberOfBanners');
  const intervalInSlidesValue = watch('allBanners.intervalInSlides');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allBanners.items',
  });

  const onSubmit = (data: FormValues) => {
    const sanitizedData = sanitizeCarouselData(data);

    const action = {
      bannerCardType: CardTypes.carousel,
      bannerData: sanitizedData,
    };

    dispatch(
      isNew
        ? attemptToCrateBannerAction(action)
        : attemptToUpdateBannerAction({
          ...action,
          cardId: bannerData?.id,
        }),
    );
    reset();
  };

  const handleInputButtonClick = (type: IncreaseOrDecrese) => {
    const isAllowedLimit = numberOfBannersValue <= MAX_BANNER_COUNT;

    if (type === IncreaseOrDecrese.Up && isAllowedLimit) {
      append(defaultBannerItem);
      setValue('allBanners.numberOfBanners', numberOfBannersValue + 1);

      return;
    }

    if (numberOfBannersValue >= 1) {
      setValue('allBanners.numberOfBanners', numberOfBannersValue - 1);
      remove(fields.length - 1);
    }
  };

  const handleOnInputNumberOfBanners = (e: any) => {
    const { value: targetValuue } = e.target;
    const value = Number(targetValuue);

    const isAllowedLimit = value <= MAX_BANNER_COUNT;

    if (!value) {
      reset({
        allBanners: {
          items: [],
        },
      });

      return;
    }

    if (value && !Number.isNaN(value) && isAllowedLimit) {
      setValue('allBanners.numberOfBanners', value);

      Array(value)
        .fill(1)
        .forEach(() => {
          append(defaultBannerItem);
        });
    }
  };

  const numberOfBannersEndAdornment = (
    <NumberOfBannersEndAdornment>
      <IconButton
        onClick={() => handleInputButtonClick(IncreaseOrDecrese.Up)}
        color="primary"
      >
        <ArrowDropUpIcon fontSize="small" />
      </IconButton>

      <IconButton
        onClick={() => handleInputButtonClick(IncreaseOrDecrese.Down)}
        color="primary"
      >
        <ArrowDropDownIcon fontSize="small" />
      </IconButton>
    </NumberOfBannersEndAdornment>
  );

  return (
    <FormProvider {...methods}>
      <BannerCardPaper title="Image Carousel">
        {!isNew && <BannerHeaderStatus bannerId={bannerData?.id} />}

        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
        >
          <BaseInput
            onInput={handleOnInputNumberOfBanners}
            sx={{ padding: '3px 21px' }}
            type="text"
            placeholder="0"
            inputProps={{
              minLength: '0',
              maxLength: '1',
              ...register('allBanners.numberOfBanners'),
            }}
            withStyledInputBox
            calculateInputWidth
            errors={errors}
            inputBoxTitle="Number of banners"
            endAdornment={numberOfBannersEndAdornment}
          />

          <StyledIntervalInSlidesBox>
            <StyledInputBoxTitle>Interval in slides</StyledInputBoxTitle>
            <BaseInput
              sx={{
                padding: '3px 21px',
                input: {
                  width: `${`${intervalInSlidesValue || 1}`.length || 1}ch`,
                },
              }}
              type="text"
              placeholder="0s"
              endAdornment={<Typography>s</Typography>}
              inputProps={{
                minLength: '0',
                maxLength: '4',
                ...register('allBanners.intervalInSlides'),
              }}
            />
          </StyledIntervalInSlidesBox>
          <ErrorMessage
            errors={errors}
            name="allBanners.intervalInSlides"
          />

          <Tabs bannerItems={fields} />
          <Button
            disabled={isEqual(initialValues, watch())}
            type="submit"
            title={isNew ? 'Save' : 'Update'}
          />
        </Stack>
      </BannerCardPaper>
    </FormProvider>
  );
};

export default memo(ImageCarouselBanner);
