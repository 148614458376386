import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import Button from '@mui/material/Button';

import { COLORS } from '../../../../../types/global/theme/colors';
import { FontFamilyNames } from '../../../../../types/global/theme/fonts';

export const StyledButton = styled(Button)<{component?: string}>({
  background: COLORS.white,
  border: `1px solid ${COLORS.grey['500']}`,
  borderRadius: '6px',
  padding: '15px',
  width: '100%',
  fontSize: 14,
  fontFamily: FontFamilyNames.CircularStdBook,
  fontWeight: 450,
  letterSpacing: '-0.03em',
  color: alpha(COLORS.black, 0.3),
});
