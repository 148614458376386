import { CSSProperties } from 'react';

export interface TableData {
  logo: string;
  name: string;
  address: string;
  createdAt: string;
  contact: string;
  phone: string;
}

export interface TableHeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
  styles: CSSProperties;
  isSortable?: boolean;
}

export const headCells: readonly TableHeadCell[] = [
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Name',
    styles: {
      width: '300px',
    },
  },
  {
    id: 'logo',
    numeric: false,
    disablePadding: true,
    label: 'Logo',
    styles: {
      width: '100px',
    },
  },
  {
    id: 'address',
    numeric: true,
    disablePadding: false,
    label: 'Address',
    styles: {
      width: '250px',
    },
    isSortable: true,
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Registered Date',
    styles: {
      width: '100px',
    },
    isSortable: true,
  },
  // TODO: should be returned
  // {
  //   id: 'contact',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Contact',
  //   styles: {
  //     width: '100px',
  //   },
  // },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone Number',
    styles: {
      width: '100px',
    },
  },
];
