import { memo } from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import LinkRouter from '../../../../common/components/LinkRouter';

const CategoryBreadcrumbs = () => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter variant="h5" underline="hover" color="inherit" to="/">
        Home
      </LinkRouter>
      <LinkRouter variant="h5" underline="hover" color="inherit" to="/category">
        Category
      </LinkRouter>
      <Typography variant="h5" color="text.primary">
        Specifications
      </Typography>
    </Breadcrumbs>
  );
};

export default memo(CategoryBreadcrumbs);
