import { CSSProperties } from 'react';

export interface TableData {
  photo: string;
  id: string;
  name: string;
  date: string;
  price: number;
  seller: string;
  buyer: string;
  reason: string;
  action: string;
}

export interface TableHeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
  styles: CSSProperties;
  isSortable?: boolean;
}

export const headCells: readonly TableHeadCell[] = [
  {
    id: 'photo',
    numeric: false,
    disablePadding: true,
    label: 'Photo',
    styles: {
      width: '60px',
      paddingLeft: '16px',
    },
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Order Item Id',
    styles: {
      width: '180px',
    },
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Item Name',
    styles: {
      width: '178px',
    },
    isSortable: true,
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
    styles: {
      width: '131px',
    },
    isSortable: true,
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Purchase Date',
    styles: {
      width: '91px',
    },
    isSortable: true,
  },
  {
    id: 'seller',
    numeric: true,
    disablePadding: false,
    label: 'Seller',
    styles: {
      width: '136px',
    },
    isSortable: true,
  },
  {
    id: 'buyer',
    numeric: true,
    disablePadding: false,
    label: 'Buyer',
    styles: {
      width: '136px',
    },
    isSortable: true,
  },
  {
    id: 'reason',
    numeric: true,
    disablePadding: false,
    label: 'Reason',
    styles: {
      width: '136px',
    },
    isSortable: true,
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
    styles: {
      width: '136px',
    },
    isSortable: true,
  },
];
