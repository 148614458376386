export enum HomeBannerCardTypeEnum {
  runningLine = 'RUNNING_LINE',
  carousel = 'CAROUSEL',
  ad = 'AD',
  filtered = 'FILTERED',
  fullRow = 'FULL_ROW',
}

// TODO: this data is temproary solution
export const optionsOfBanners: (keyof typeof HomeBannerCardTypeEnum)[] = [
  'ad',
  'fullRow',
  'carousel',
  'filtered',
  'runningLine',
];

export enum CardTypes {
  runningLine = 'RUNNING_LINE',
  carousel = 'CAROUSEL',
  ad = 'AD',
  filtered = 'FILTERED',
  fullRow = 'FULL_ROW',
}

export enum CardSize {
  row = 'ROW',
  column = 'COLUMN',
}
