import { ResponseData } from '../utils/types';
import { IPrivacyCore } from '../types/privacy';
import { Translation } from '../types/category';

export type UserUpdateResponseType = ResponseData<{ privacy: IPrivacyCore }>;

export enum PRIVACY_ENUM {
  PRIVACY = 'PRIVACY',
  REFUND = 'REFUND',
  RETURN = 'RETURN'
}
export type PolicyPostType = {
  type: PRIVACY_ENUM;
  translations: Translation[];
}
