import { memo, useCallback } from 'react';

import Flag from 'react-world-flags';
import isEqual from 'lodash/isEqual';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';

import BaseInput from '../../../../../common/components/Input/BaseInput';
import BannerCardPaper from '../../BannerCardPaper';
import Button from '../../../../../common/components/Button/index';
import {
  FormValues, validationSchema,
  defaultValues, sanitizeAdData, ImageDataType, initialImageValue,
} from './helper';
import { attemptToCrateBannerAction, attemptToUpdateBannerAction } from '../../../../../store/actions/banners';
import { CardTypes } from '../../../../../types/enums/banners';
import { HomeAllBanner } from '../../../../../models/banner-card/home-all-model';
import BannerHeaderStatus from '../BannerHeaderStatus';
import { changeLanguageCode, sortedCollection } from '../../../../../utils/helpers';
import ImageUploader from './ImageUploader';
import InputLabel from '../../../../../common/components/InputLabel/InputLabel';
import { LanguageEnum } from '../../../../../types/enums/translations';
import { Translation } from '../../../../../types/category';
import { FLAGS_LANGUAGES } from '../../../../../utils/constants';

interface IAddBannerCardProps {
  isNew?: boolean;
  bannerData?: HomeAllBanner | null;
}

const AddBannerCard = ({ isNew, bannerData }: IAddBannerCardProps) => {
  const sortedTranslations:Translation[] = sortedCollection(bannerData?.translations);

  const imagesList = initialImageValue as ImageDataType;

  !isNew && sortedTranslations?.forEach(({ lang, content: { image } }) => {
    (imagesList as Record<string, string>)[lang] = image;
  });

  const initialValues = {
    link: bannerData?.translation?.content?.link,
    translations: sortedTranslations,
    image: imagesList,
  };

  const dispatch = useDispatch();

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormValues>({
    defaultValues: !isNew && bannerData
      ? initialValues
      : defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const images = watch('image');

  const setImageValue = useCallback((img: string, lang: string) => {
    setValue('image', { ...images, [lang]: img });
  }, [images, setValue]);

  const onSubmit = (data: FormValues) => {
    const sanitizedData = sanitizeAdData(data, images);

    const action = {
      bannerCardType: CardTypes.ad,
      bannerData: sanitizedData,
    };

    dispatch(
      isNew
        ? attemptToCrateBannerAction(action)
        : attemptToUpdateBannerAction({
          ...action,
          cardId: bannerData?.id,
        }),
    );
    reset();
  };

  return (
    <Box sx={{ maxWidth: 396 }}>
      <BannerCardPaper title="AD Banner">
        {!isNew && <BannerHeaderStatus bannerId={bannerData?.id} />}

        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
        >
          <BaseInput
            errors={errors}
            placeholder="Add URL Link"
            label="Link"
            inputProps={{
              ...register('link'),
            }}
          />
          {
            [LanguageEnum.en, LanguageEnum.hy, LanguageEnum.ru].map((lang) => (
              <Box key={lang} sx={{ fontSize: '8x' }}>
                <InputLabel title="Image" flag={<Flag code={changeLanguageCode(lang)} height="22" width="22" />} />
                <ImageUploader
                  errors={errors}
                  initialValue={(images as Record<string, string>)[lang]}
                  setImageValue={setImageValue}
                  id={bannerData?.id}
                  lang={lang}
                />
              </Box>
            ))
          }
          {
            FLAGS_LANGUAGES.map((lang, idx) => (
              <BaseInput
                key={lang}
                label={idx === 0 && 'Text'}
                errors={errors}
                inputProps={{ ...register(`translations.${idx}.text`) }}
                placeholder="Text"
                endAdornment={(
                  <InputAdornment position="end">
                    <Flag code={lang} height="22" width="22" />
                  </InputAdornment>
          )}
              />
            ))
          }
          <Button
            type="submit"
            title={isNew ? 'Save' : 'Update'}
            disabled={!isSubmitting && !isDirty && isEqual(images, imagesList)}
          />
        </Stack>
      </BannerCardPaper>
    </Box>
  );
};

export default memo(AddBannerCard);
