import {
  ChangeEvent,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { UseFormSetValue } from 'react-hook-form';

import UploadButton from '../../../../common/components/Upload/components/UploadButton';
import Upload from '../../../../common/components/Upload/index';
import { getUploadUrl, uploadFile } from '../../../../requests/upload';
import { getCDNImagePath } from '../../../../utils/helpers';
import { StyledImage, StyledLabel } from './styled';
import ErrorMessage from '../../../../common/components/ErrorMessage';

type FileStateType = FileList | File | null;

interface IUploadContainerProps {
  id: string;
  imagePreivew: string;
  label?: ReactNode;
  setImagePreview: Dispatch<SetStateAction<string>>;
  setValue?: UseFormSetValue<any>;
  inputName?: string;
  errors?: any;
}

const UploadContainer = ({
  id,
  imagePreivew,
  setValue,
  label,
  errors,
  inputName = '',
  setImagePreview,
}: IUploadContainerProps) => {
  const [fileData, setFileData] = useState<FileStateType>(null);
  const [isUploadeing, setIsUploading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentFiles = e.target.files;

    if (currentFiles) {
      setFileData(currentFiles[0]);
    }
  };

  useEffect(() => {
    if (inputName && imagePreivew) {
      setValue?.(inputName, imagePreivew);
    }
  }, [inputName, imagePreivew, setValue]);

  const uploadToS3 = useCallback(
    async (file: FileStateType) => {
      setIsUploading(true);
      try {
        const { img, url } = await getUploadUrl();

        await uploadFile({ file: file as File, url });

        setImagePreview(img);
      } finally {
        setIsUploading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setImagePreview],
  );

  useEffect(() => {
    if (fileData) {
      uploadToS3(fileData);
    }
  }, [fileData, uploadToS3]);

  if (isUploadeing) {
    return <CircularProgress size="20px" color="primary" />;
  }

  return (
    <Box>
      {label && (
      <StyledLabel htmlFor={id}>
        {label}
      </StyledLabel>
      )}
      <Upload name={inputName} onChange={handleChange} id={id}>
        {imagePreivew ? (
          <StyledImage
            src={getCDNImagePath(imagePreivew)}
            alt="add-banner-card"
          />
        ) : (
          <UploadButton />
        )}
      </Upload>

      {(errors && inputName) && (
      <ErrorMessage
        errors={errors}
        name={inputName}
      />
      )}
    </Box>
  );
};

export default memo(UploadContainer);
