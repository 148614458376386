import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledTitleBox = styled(Box)(() => ({
  margin: '58px 0 25px 0',
}));

export const StyledFragment = styled(Box)(() => ({
  margin: '20px 0',
}));
