import type { RouteObject } from 'react-router-dom';

import SignIn from '../containers/SignIn';
import Home from '../containers/Home';
import Users from '../containers/Users';
import Category from '../containers/Category';
import NotFound from '../containers/NotFound';
import Layout from '../containers/Layout';
import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '../types/enums/routes';
import Specifications from '../containers/Specifications';
import AllProducts from '../containers/AllProducts';
import Orders from '../containers/Orders';
import Privacy from '../containers/Privacy';
import BusinessTypes from '../containers/BusinessTypes';
import Collection from '../containers/Collection';
import NewCollection from '../containers/NewCollection';
import Returns from '../containers/Returns';
import Shops from '../containers/Shops';

export const PUBLIC_ROUTES: RouteObject[] = [
  {
    path: PAGE_ROUTES_PUBLIC.ROOT_SIGN_IN,
    element: <SignIn />,
  },
  {
    path: PAGE_ROUTES_PUBLIC.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: PAGE_ROUTES_PUBLIC.FORGOT_PASSWORD,
    element: <div>Forgot password</div>,
  },
  {
    path: '*',
    element: <div>Not found</div>,
  },
];

export const PRIVATE_ROUTES: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PAGE_ROUTES_PRIVATE.ROOT_HOME,
        element: <Home />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.HOME,
        element: <Home />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.USERS,
        element: <Users />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.ALL_PRODUCTS,
        element: <AllProducts />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.SPECIFICATIONS,
        element: <Specifications />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.ORDERS,
        element: <Orders />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.CATEGORY,
        element: <Category />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.PRIVACY_POLICY,
        element: <Privacy />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.BUSINESS_TYPES,
        element: <BusinessTypes />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.COLLECTION,
        element: <Collection />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.NEW_COLLECTION,
        element: <NewCollection />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.RETURNS,
        element: <Returns />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.SHOPS,
        element: <Shops />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];
