import { ReactNode, memo } from 'react';

import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material';

import { top100Films } from '../../../fake';

type Multiple = true;
type DisableClearable = true;
type FreeSolo = true;
interface IAutocompleteProps
  extends MuiAutocompleteProps<any, Multiple, DisableClearable, FreeSolo> {
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
}

const Autocomplete = ({ renderInput, options = top100Films, ...restProps }: IAutocompleteProps) => {
  return (
    <MuiAutocomplete
      {...restProps}
      id="free-solo-2-demo"
      options={options}
      renderInput={renderInput}
    />
  );
};

export default memo(Autocomplete);
