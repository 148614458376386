import { memo } from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cls from 'classnames';

import { TableData, UpdateType } from '../helper';
import { COLORS } from '../../../../../types/global/theme/colors';
import CustomTableCell from './CustomTableCell';
import { StyledTableBody } from './styled';
import MorePopover from '../../../../../common/components/Table/MorePopover';
import { STAGING_URL } from '../../../../../utils/constants';
import { getCDNImagePath } from '../../../../../utils/helpers';

interface ITableBodyProps {
  page: number;
  rows: TableData[];
  rowsPerPage: number;
  isEqualPrevAndRowData: boolean;
  onSave: (rowId: string) => void;
  onRevert: (rowId: string) => void;
  onChange: (e: any, rowData: any) => void;
  onToggleEditMode: (rowId: string) => void;
  handleDeleteRequest: (userId: string) => Promise<void>;
  handleActivateRequest: (userInfo: UpdateType, isActive: boolean) => Promise<void>;
}

const TableBody = ({
  rows,
  page,
  onSave,
  onChange,
  onRevert,
  rowsPerPage,
  onToggleEditMode,
  handleDeleteRequest,
  isEqualPrevAndRowData,
  handleActivateRequest,
}: ITableBodyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const slicedAndSorted = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // TODO: this may need in future - client side sorting
    // .sort(getComparator(order, orderBy) as any);

  return (
    <>
      <StyledTableBody>
        {rows.map((row) => {
          const { id, address, name, image, createdAt, isEditMode, email, phone, isActive } = row;

          return (
            <TableRow
              hover
              key={id}
              tabIndex={-1}
              role="checkbox"
              style={{ height: 64, opacity: isActive ? 1 : 0.4 }}
              className={cls({ isEditable: isEditMode })}
            >
              <TableCell sx={{ paddingLeft: '16px' }}>
                <Link href={`${STAGING_URL}/other-user-profile/${id}`} target="_blank" color="inherit">
                  {name}
                </Link>
              </TableCell>

              <TableCell sx={{ paddingLeft: '16px' }} align="left">
                <Avatar
                  alt="avatar"
                  src={image && getCDNImagePath(image)}
                  sx={{ width: 24, height: 24 }}
                />
              </TableCell>

              <TableCell sx={{ paddingLeft: '16px' }}>
                {address ? `${address?.street}, ${address?.apartment ? `apt ${address?.apartment},` : ''} 
                 ${address?.city}, ${address?.country}` : ''}
              </TableCell>

              <TableCell sx={{ paddingLeft: '16px' }}>{createdAt}</TableCell>

              <CustomTableCell
                row={row}
                align="left"
                name="email"
                cellType="contact"
                onChange={onChange}
                sx={{ padding: '0 10px 0' }}
              >
                <Link href={`mailto:${email}`} color="inherit">
                  <Tooltip title={email}>
                    <IconButton>
                      <CopyToClipboard text={email}>
                        <EmailIcon sx={{ color: COLORS.black }} />
                      </CopyToClipboard>
                    </IconButton>
                  </Tooltip>
                </Link>

              </CustomTableCell>

              <CustomTableCell
                row={row}
                name="phone"
                onChange={onChange}
                sx={{ paddingLeft: '16px' }}
              />
              {/** ACTIONS */}
              <TableCell sx={{ width: '100px', padding: 0 }} align="right">
                <MorePopover>
                  <Box sx={{ width: '230px' }}>
                    {isEditMode ? (
                      <>
                        {!isEqualPrevAndRowData ? (
                          <Typography
                            sx={{ padding: '14px 16px', cursor: 'pointer' }}
                            variant="body1"
                            component="div"
                            onClick={() => onSave(id)}
                          >
                            Save
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ padding: '14px 16px' }}
                            variant="body1"
                            onClick={() => onRevert(id)}
                          >
                            Revert
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Typography
                        sx={{ padding: '14px 16px', cursor: 'pointer' }}
                        variant="body1"
                        onClick={() => onToggleEditMode(id)}
                      >
                        Edit
                      </Typography>
                    )}
                  </Box>
                  <Divider />

                  <Box
                    onClick={() => handleActivateRequest({ phone, id, email }, !isActive)}
                    sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                  >
                    <Typography variant="body1">
                      {isActive ? 'Deactivate' : 'Activate'}
                    </Typography>
                  </Box>
                  <Divider />

                  <Box
                    onClick={() => handleDeleteRequest(id)}
                    sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                  >
                    <Typography variant="body1">Delete</Typography>
                  </Box>
                </MorePopover>
              </TableCell>
            </TableRow>
          );
        })}
      </StyledTableBody>
    </>
  );
};

export default memo(TableBody);
