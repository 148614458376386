import { HomeAllBanner } from '../../models/banner-card/home-all-model';
import { CardTypes } from '../../types/enums/banners';
import { IActionCreator } from '../../types/global/action';

export enum BANNER_ACTIONS {
  ATTEMPT_TO_CREATE_BANNER_CARD = '@banner/ATTEMPT_TO_CREATE_BANNER_CARD',
  ATTEMPT_TO_UPDATE_BANNER_CARD = '@banner/ATTEMPT_TO_UPDATE_BANNER_CARD',
  ATTEMPT_TO_GET_ALL_BANNERS = '@banner/ATTEMPT_TO_GET_ALL_BANNERS',
  ATTEMPT_TO_DISCONNECT_BANNER = '@banner/ATTEMPT_TO_DISCONNECT_BANNER',
  SET_ALL_HOME_BANNER_DATA = '@AUTH/SET_BANNER_DATA',
  SET_BANNER_FAILURE = '@AUTH/SET_USER_FAILURE',
  SET_BANNER_SUCCESS = '@AUTH/SET_BANNER_SUCCESS',
  SET_BANNER_LOADING = '@AUTH/SET_BANNER_LOADING',
}

export interface IBannerState {
  data: HomeAllBanner[];
  loading: boolean;
  error: any;
}

type BannerCardType = { bannerCardType: CardTypes; bannerData: any };
export interface IAttemptToCreateBannerCard
  extends IActionCreator<BannerCardType> {
  type: BANNER_ACTIONS.ATTEMPT_TO_CREATE_BANNER_CARD;
}

type UpdateBannerCardType = { bannerCardType: CardTypes; bannerData: any; cardId?: string };

export interface IAttemptToUpdateBannerCard
  extends IActionCreator<UpdateBannerCardType> {
  type: BANNER_ACTIONS.ATTEMPT_TO_UPDATE_BANNER_CARD;
}

export interface IAttemptToGetAllBanners extends IActionCreator<any> {
  type: BANNER_ACTIONS.ATTEMPT_TO_GET_ALL_BANNERS;
}

type DisconnectBannerPayloadType = {
  bannerId?: string;
}
export interface IAttemptToDisconnectBanner extends IActionCreator<DisconnectBannerPayloadType> {
  type: BANNER_ACTIONS.ATTEMPT_TO_DISCONNECT_BANNER;
}

export interface ISetAllHomeBannerData extends IActionCreator<HomeAllBanner[]> {
  type: BANNER_ACTIONS.SET_ALL_HOME_BANNER_DATA;
}

export interface ISetBannerLoading extends IActionCreator<boolean> {
  type: BANNER_ACTIONS.SET_BANNER_LOADING;
}

export type BannerActionPayloadType =
  | IAttemptToCreateBannerCard
  | IAttemptToGetAllBanners
  | ISetAllHomeBannerData
  | ISetBannerLoading;
