// TODO: All comments should be returned

import {
  // useCallback, useEffect, useState,
  memo, useState,
} from 'react';

import Box from '@mui/material/Box';

// import Table from './Table';
// import { IAllShopsCore } from '../../../types/shops';
// import Loader from '../../../common/components/Loader';
// import { ShopsSearchPayload } from '../../../models/shops-model';
// import {
//   filterShopsApi, searchShopsApi,
// } from '../../../requests/shops';
// import { TableData } from './Table/helper';
// import { Order } from '../../../utils/types';
import Contact from './Contact';
import { StyledDialogTop } from './styled';
import Business from './Business';
import { getShopByIdApi } from '../../../requests/shops';
import useMount from '../../../hooks/useMount';
import { IShopInfo } from './helpers';

interface ModalProps{
  id: string | null;
}

const Modal = ({ id }: ModalProps) => {
  const [info, setInfo] = useState<null | IShopInfo>(null);

  const getShopById = async () => {
    try {
      const { data } = id && await getShopByIdApi(id);

      setInfo(data);
    } catch (e) {
      throw e;
    }
  };

  useMount(() => {
    getShopById();
  });

  // const [shops, setShops] = useState<IAllShopsCore[]>([]);
  // const [offset, setOffset] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [totalShops, setTotalShops] = useState(10);
  // const [isLoading, setIsLoading] = useState(true);
  // const [search, setSearch] = useState('');
  // const [order, setOrder] = useState<Order>('asc');
  // const [orderBy, setOrderBy] = useState<keyof TableData>();

  // const getShops = useCallback(async () => {
  //   try {
  //     setIsLoading(true);
  //     setSearch('');

  //     const payloadData: ShopsSearchPayload = {
  //       limit: rowsPerPage,
  //       offset,
  //     };

  //     if (orderBy) {
  //       payloadData.orderBy = order;
  //       payloadData.sortBy = orderBy;
  //     }

  //     const { data, meta: { pagination: { total } } } = await searchShopsApi(payloadData);

  //     setShops(data);
  //     setTotalShops(total);
  //   } catch (error) {
  //     throw error;
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, [offset, rowsPerPage, orderBy, order]);

  // useEffect(() => {
  //   if (search) {
  //     return;
  //   }

  //   getShops();
  // }, [getShops, search]);

  // useEffect(() => {
  //   if (search) {
  //     const searchShops = async () => {
  //       try {
  //         const { data, meta: { pagination: { total } } } = await searchShopsApi({
  //           searchKey: search,
  //           limit: rowsPerPage,
  //           offset,
  //         });

  //         setShops(data);
  //         setTotalShops(total);
  //       } catch (error) {
  //         throw error;
  //       }
  //     };

  //     searchShops();
  //   }
  // }, [offset, rowsPerPage, search]);

  // const handleSortRequest = useCallback(
  //   async (ascOrDesc: Order, sort: keyof TableData) => {
  //     try {
  //       setIsLoading(true);
  //       setSearch('');

  //       const { data, meta: { pagination: { total } } } = await filterShopsApi({
  //         orderBy: ascOrDesc.toUpperCase(),
  //         sortBy: sort,
  //         offset,
  //         limit: rowsPerPage,
  //       });

  //       setOrder(ascOrDesc);
  //       setOrderBy(sort);
  //       setShops(data);
  //       setTotalShops(total);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  //   [offset, rowsPerPage],
  // );

  // if (isLoading) {
  //   return <Loader sx={{ justifyContent: 'center', padding: '10px' }} />;
  // }

  return (
    <Box>
      <StyledDialogTop>
        { info && (
        <Business
          info={info}
        />
        )}
        { info && (
        <Contact
          info={info}
        />
        )}
      </StyledDialogTop>
      {/* <Table
        shops={shops}
        offset={offset}
        order={order}
        orderBy={orderBy}
        setOffset={setOffset}
        totalShops={totalShops}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleSortRequest={handleSortRequest}
      /> */}
    </Box>
  );
};

export default memo(Modal);
