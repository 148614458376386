import { memo, ReactNode } from 'react';

import MuiButton, { ButtonProps } from '@mui/material/Button';

import { StyledButton } from './styled';

interface IButtonProps extends Omit<ButtonProps, 'title'> {
  title?: ReactNode;
}

const Button = ({
  color,
  variant,
  children,
  title,
  ...restProps
}: IButtonProps) => {
  if (color && variant) {
    return (
      <MuiButton color={color} variant={variant} {...restProps}>
        {children || title}
      </MuiButton>
    );
  }

  return <StyledButton {...restProps}>{children || title}</StyledButton>;
};

export default memo(Button);
