export const COLORS = {
  blue: {
    main: '#002E99',
    dark: '#01257b',
    light: '#536DFE',
    50: '#1976D2',
    100: '#f1f5f8',
  },
  green: {
    main: '#039E4F',
    dark: '#027e3f',
  },
  white: '#FFFFFF',
  black: '#333333',
  info: {
    main: 'rgba(0, 0, 0, 0.08)',
    dark: '#f5f5f5',
    text: 'rgba(0, 0, 0, 0.87)',
  },
  red: {
    main: '#FF5C00',
    dark: '#c94c00',
  },
  alpha: {
    black087: 'rgba(0, 0, 0, 0.87)',
    denim008: 'rgba(25, 118, 210, 0.08)',
    black054: 'rgba(0, 0, 0, 0.54)',
    black012: 'rgba(0, 0, 0, 0.12)',
    black023: 'rgba(0, 0, 0, 0.23)',
  },
  grey: {
    50: '#ACACAC', // main
    100: '#D4D3D3',
    200: '#F6F6F6',
    300: '#757575',
    400: '#EBEBEB', // dark
    500: '#E9E9E9',
    600: '#E5E5E5',
    700: '#EDEDED',
    800: '#f1f1f1',
    900: '#6A6A6A',
  },
};

export type ColorsType = typeof COLORS;
