import { ResponseData } from '../utils/types';
import { IUserCore } from '../types/users/index';

export type UserUpdateResponseType = ResponseData<{ user: IUserCore }>;
export type UpdateUserPayloadType = {
  isActive?: boolean;
  phone?: string;
  email?: string;
};

export enum USER_ROLE {
  SELLER ='SELLER',
  BUYER = 'BUYER',
  ADMIN = 'ADMIN'
}

export type UserFilterResponseType = ResponseData<IUserCore[]>;
export type UserFilterPayload = {
  role?: USER_ROLE;
  offset?: number;
  limit?: number;
  sortBy?: string;
  orderBy?: string;
};

export type UserSearchPayload = {
  searchKey: string;
  limit?: number;
  offset?: number;
  role?: USER_ROLE;
};
