import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';
import blueGrey from '@mui/material/colors/blueGrey';
import { PaletteOptions } from '@mui/material';

import { COLORS } from '../types/global/theme/colors';

const palette = {
  background: {
    default: COLORS.white,
    paper: COLORS.white,
  },
  primary: {
    contrastText: COLORS.white,
    dark: COLORS.blue.dark,
    main: COLORS.blue.main,
    light: COLORS.blue.light,
  },
  secondary: {
    contrastText: COLORS.white,
    dark: COLORS.green.dark,
    main: COLORS.green.main,
    light: blue.A400,
  },
  info: {
    main: COLORS.info.main,
    dark: COLORS.info.dark,
    contrastText: COLORS.info.text,
  },
  error: {
    contrastText: COLORS.white,
    dark: red[900],
    main: red[600],
    light: red[400],
  },
  text: {
    primary: COLORS.alpha.black087,
    secondary: blueGrey[600],
  },
  grey: COLORS.grey,
} as PaletteOptions;

export default palette;
