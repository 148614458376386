import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Dialog from '../../common/components/Dialog';
import { COLORS } from '../../types/global/theme/colors';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    maxWidth: '1600px !important',
    padding: '0 30px',
  },
}));

export const StyledDialogTop = styled(Box)(() => ({
  '.MuiButton-root': {
    height: '42px',
  },
}));

export const StyledDialogBotom = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  display: 'flex',
  padding: '20px',
  alignItems: 'flex-start',
  marginTop: '24px',
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.grey['200'],
  border: `1px solid ${theme.palette.grey['500']}`,
  borderRadius: '4px',
  padding: '20px !important',
  marginRight: '30px',
}));

export const StyledContentPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `1px solid ${COLORS.grey['700']}`,
  borderRadius: '6px',
  padding: '20px !important',
  display: 'flex',
  alignItems: 'flex-start',
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '20px',
}));

export const StyledBoxTitle = styled(Typography)(() => ({
  fontWeight: 800,
}));
