import { USER_ROLE } from '../../models/user-model';
import { UserFilterMenuEnum } from '../../types/users';

export const filterByRole = (filterMenu: UserFilterMenuEnum) => {
  switch (filterMenu) {
    case UserFilterMenuEnum.ALL_SELLERS:
      return USER_ROLE.SELLER;
    case UserFilterMenuEnum.ALL_BUYERS:
      return USER_ROLE.BUYER;
    case UserFilterMenuEnum.ADMIN:
      return USER_ROLE.ADMIN;
    default:
      return null;
  }
};
