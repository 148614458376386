import { memo } from 'react';

import Typography from '@mui/material/Typography';
import Flag from 'react-world-flags';

import { StyledCellBox, StyledRowBox } from './styled';

const SpecificationHead = () => {
  return (
    <StyledRowBox sx={{ borderBottomWidth: '10px', position: 'relative', height: '84px' }}>
      <StyledCellBox />
      <StyledCellBox>
        <Flag code="us" height="22" width="22" />
        <Typography sx={{ marginLeft: '10px' }}>English</Typography>
      </StyledCellBox>
      <StyledCellBox>
        <Flag code="am" height="22" width="22" />
        <Typography sx={{ marginLeft: '10px' }}>Armenian</Typography>
      </StyledCellBox>
      <StyledCellBox>
        <Flag code="ru" height="22" width="22" />
        <Typography sx={{ marginLeft: '10px' }}>Russian</Typography>
      </StyledCellBox>
    </StyledRowBox>
  );
};

export default memo(SpecificationHead);
