import * as yup from 'yup';

import { FileteredBannerMoodel, Content } from '../../../../../models/banner-card/filtered-model';
import { HomeAllBanner } from '../../../../../models/banner-card/home-all-model';
import { Translation } from '../../../../../types/category';
import { CardSize, CardTypes } from '../../../../../types/enums/banners';
import { Shape } from '../../../../../types/global/yup-object-shape';
import { initialTranlationsState, replceSpacingWithUnderscore, sortedCollection } from '../../../../../utils/helpers';
import { addTranslationsContent } from '../../../helper';

export interface FormValues {
  limit: string;
  size: FileteredBannerMoodel['size'];
  link: string;
  viewAll: boolean;
  filter: Content['filter'];
  translations: Translation[];
}

export const productBannerItemLimitOption = ['4', '6'];
export const productBannerItemSizeOption = [CardSize.row, CardSize.column];
export const productBannerItemFilterOption = [
  'popular',
  'trending',
  'history',
  'daily_deals',
];

export const defaultValues: FormValues = {
  limit: '',
  size: '',
  filter: '',
  link: '',
  viewAll: false,
  translations: initialTranlationsState,
};
export const gettingInitialValue = (bannerData:HomeAllBanner | null) => {
  const sortedTranslations = sortedCollection(bannerData?.translations);

  return {
    limit: bannerData?.translation?.content.limit,
    size: bannerData?.translation?.content.size,
    filter: bannerData?.translation?.content.filter,
    link: bannerData?.translation?.content.link,
    viewAll: bannerData?.translation?.content.viewAll,
    translations: sortedTranslations,
  };
};

export const validationSchema = yup.object<Shape<FormValues>>({
  limit: yup.string().required('Required'),
  size: yup.string().required('Required'),
  viewAll: yup.boolean().required('Required'),
  filter: yup.string().required('Required'),
  translations: yup.array().of(
    yup.object({
      text: yup.string().required('Required'),
    }),
  ),
});

export const productBannerSelect: {
  name: 'limit' | 'size' | 'filter';
  label: string;
  options: any[];
}[] = [
  {
    name: 'limit',
    label: 'Limit',
    options: productBannerItemLimitOption,
  },
  {
    name: 'size',
    label: 'Size',
    options: productBannerItemSizeOption,
  },
  {
    name: 'filter',
    label: 'Filter',
    options: productBannerItemFilterOption,
  },
];

export const sanitizeProductData = (data: FormValues) => {
  const {
    size,
    filter,
    limit,
    viewAll,
    translations } = data;

  const content:Content = {
    filter,
    limit,
    size,
    viewAll,
  };

  const payloadData: FileteredBannerMoodel = {
    type: CardTypes.filtered,
    size,
    isActive: true,
    key: replceSpacingWithUnderscore(translations[0].text),
    translations: addTranslationsContent(translations, content),
    sort: -1,
  };

  return payloadData;
};
