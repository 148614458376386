import { FC, memo, useCallback, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { useConfirm } from 'material-ui-confirm';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { StyledTreeIcon, StyledBox, StyledTreeViewNode } from './styled';
import { categoryDeleteApi, getCategoryById } from '../../../../requests/category';
import { deleteConfirmOption } from '../../../../common/components/Table/helper';
import { DepthEnum } from '../helpers';
import MorePopover from '../../../../common/components/Table/MorePopover';
import EditCategoryModal, { EditingType } from '../../EditCategory/EditCategoryModal';
import LinkRouter from '../../../../common/components/LinkRouter';
import { AdditionalDataType } from '../../helper';

interface CustomNodeProps {
  node: NodeModel<AdditionalDataType>;
  depth: DepthEnum;
  onToggle: (id: string | number) => void;
  isOpen: boolean;
  hasChild: boolean;
  deleteHandler: () => void;
  editHandler: () => void;
  addHandler: (parentId: string | number) => void;
}

const CustomNode: FC<CustomNodeProps> = ({
  node,
  depth,
  onToggle,
  isOpen,
  hasChild,
  deleteHandler,
  editHandler,
  addHandler,
}) => {
  if (node.data) {
    node.data.hasChild = hasChild;
    node.data.depth = depth;
  }

  const paddingLeft = depth * 24;

  const handleToggle = (e: any) => {
    e.stopPropagation();

    if (node?.data?.isActive) {
      onToggle(node.id);
    }
  };

  const confirm = useConfirm();

  const [open, setOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState<EditingType>({
    translations: [],
    id: '',
    key: '',
    isActive: false,
    parentId: '',
  });

  const handleClickOpen = () => {
    const gettingCategoryInfo = async () => {
      const response = await getCategoryById(node.id);

      if (response.translations) {
        setEditingCategory({
          translations: response.translations,
          id: response.id,
          key: response.key,
          isActive: response.isActive,
          parentId: response.parentId,
        });

        setOpen(true);
      }
    };

    gettingCategoryInfo();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteCategory = useCallback(() => {
    const deleteFunction = async () => {
      try {
        await confirm(deleteConfirmOption);

        await categoryDeleteApi(node.id);

        deleteHandler();
      } catch (e) {
        throw e;
      }
    };

    deleteFunction();
  }, [confirm, node.id, deleteHandler]);

  return (
    <>
      <StyledTreeViewNode style={{ paddingLeft, opacity: `${node?.data?.isActive ? 1 : 0.5}` }}>
        <StyledBox>
          {hasChild && (
            <StyledTreeIcon
              onClick={handleToggle}
              sx={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
              }}
            >
              <KeyboardArrowDownIcon fontSize="small" />
            </StyledTreeIcon>
          )}
          <Typography
            variant="body2"
            sx={{ paddingLeft: !hasChild ? '30px' : '0' }}
          >
            {node.text}
          </Typography>
        </StyledBox>
        <StyledBox>
          <IconButton>
            <MorePopover>
              {depth !== DepthEnum.level3 && node?.data?.isActive && (
                <Box
                  sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                >
                  <Box
                    onClick={() => addHandler(node.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body1">Add</Typography>
                  </Box>
                </Box>
              )}
              <Divider />
              <Box
                onClick={deleteCategory}
                sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
              >
                <Typography variant="body1">Delete</Typography>
              </Box>
              <Divider />
              {
                  depth === DepthEnum.level3 && (
                  <>
                    <Box
                      sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                    >
                      <LinkRouter to={`/category/${node.id}`} color="inherit" sx={{ textDecoration: 'none' }}>
                        Filters
                      </LinkRouter>
                    </Box>
                    <Divider />
                  </>
                  )
                }
              <Box
                onClick={handleClickOpen}
                sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
              >
                <Typography variant="body1">Edit</Typography>
              </Box>
            </MorePopover>
          </IconButton>
        </StyledBox>
      </StyledTreeViewNode>
      <EditCategoryModal
        open={open}
        handleClose={handleClose}
        editHandler={editHandler}
        info={editingCategory}
      />
    </>
  );
};

export default memo(CustomNode);
