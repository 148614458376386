import PrivacyPolicyComponent from './components/cards/PrivacyPolicy';
import TermPolicyComponent from './components/cards/TermPolicy';
import ReturnPolicyComponent from './components/cards/ReturnPolicy';

export enum CreateTabLabelEnum {
  PrivacyPolicy = 'Privacy Policy',
  TermPolicy = 'Terms and Conditions',
  ReturnPolicy = 'Return Policy',
}

export interface CreateTabs {
  title: CreateTabLabelEnum;
}

export const createTabs: CreateTabs[] = [
  {
    title: CreateTabLabelEnum.PrivacyPolicy,
  },
  {
    title: CreateTabLabelEnum.TermPolicy,
  },
  {
    title: CreateTabLabelEnum.ReturnPolicy,
  },
];

export const renderActiveTabBox = (tab?: CreateTabLabelEnum) => {
  switch (tab) {
    case CreateTabLabelEnum.PrivacyPolicy:
      return <PrivacyPolicyComponent />;
    case CreateTabLabelEnum.TermPolicy:
      return <TermPolicyComponent />;
    case CreateTabLabelEnum.ReturnPolicy:
      return <ReturnPolicyComponent />;
    default:
      return null;
  }
};
