import {
  FC,
  memo,
  Dispatch,
  useEffect,
  SetStateAction,
  ChangeEventHandler,
} from 'react';

import { InputBaseProps } from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import toHex from 'colornames';
import { UseFormSetValue } from 'react-hook-form';

import {
  StyledColorPickerContent,
  StyledInput,
  StyledInputBox,
  StyledInputLabel,
} from './styled';
import BaseInput from '../Input/BaseInput/index';
import { isHexCodeRegex } from '../../../utils/regex';
import ErrorMessage from '../ErrorMessage';

interface IUploadProps {
  id: string;
  label?: string;
  filledColorValue?: string;
  inputProps?: InputBaseProps;
  errors?: any;
  setValue?: UseFormSetValue<any>;
  setFilledColorValue?: Dispatch<SetStateAction<string>>;
}

const ColorPickerInput: FC<IUploadProps> = ({
  id,
  label,
  errors,
  inputProps,
  filledColorValue = '',
  setFilledColorValue,
  setValue,
}: IUploadProps) => {
  const handleColorPickerChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const color = e.target.value;

    setFilledColorValue?.(color);
    if (inputProps?.name && color) {
      setValue?.(inputProps.name, color);
    }
  };

  const onInputChange: InputBaseProps['onChange'] = (e) => {
    const color = isHexCodeRegex.test(e.target.value)
      ? e.target.value
      : toHex(e.target.value);

    if (color) {
      setFilledColorValue?.(color);
    }

    if (inputProps?.name && color) {
      setValue?.(inputProps.name, color);
    }
  };

  useEffect(() => {
    const color = toHex(filledColorValue);

    if (color) {
      setFilledColorValue?.(color);
    }

    if (inputProps?.name && color) {
      setValue?.(inputProps.name, color);
    }
  }, [filledColorValue, inputProps?.name, setFilledColorValue, setValue]);

  return (
    <Box>
      {label && (
        <StyledInputLabel shrink htmlFor={id}>
          {label}
        </StyledInputLabel>
      )}
      <StyledColorPickerContent>
        <StyledInputBox>
          <StyledInput
            id={id}
            type="color"
            value={filledColorValue}
            onChange={handleColorPickerChange}
          />
        </StyledInputBox>
        <BaseInput
          type="text"
          placeholder="Color"
          value={filledColorValue}
          onChange={onInputChange}
          {...inputProps}
        />
      </StyledColorPickerContent>
      {errors && inputProps?.name && (
        <ErrorMessage errors={errors} name={inputProps.name} />
      )}
    </Box>
  );
};

export default memo(ColorPickerInput);
