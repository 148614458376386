import { memo, MouseEvent, useState } from 'react';

import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

import { StyledMenu, StyledTableBody } from './styled';
import { currencyParser, dateFormatter, getCDNImagePath } from '../../../../utils/helpers';
import { STAGING_URL } from '../../../../utils/constants';
import { ReturnedOrderCore } from '../../../../types/return';
import { acceptReturnedOrderByIdApi, declineReturnedOrderByIdApi } from '../../../../requests/returns';
import { IShop } from '../../../../types/orders';

interface ITableBodyProps {
  page: number;
  rows: ReturnedOrderCore[];
  rowsPerPage: number;
  getReturnedOrders:()=> void;
}

const TableBody = ({
  rows,
  page,
  rowsPerPage,
  getReturnedOrders,
}: ITableBodyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const slicedAndSorted = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleAcceptRequest = async (id: string) => {
    try {
      await acceptReturnedOrderByIdApi(id);
      getReturnedOrders();
    } catch (e) {
      throw e;
    }
  };

  const handleDeclinedRequest = async (id: string) => {
    try {
      await declineReturnedOrderByIdApi(id);
      getReturnedOrders();
    } catch (e) {
      throw e;
    }
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [shopInfo, setShopInfo] = useState<IShop | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>, shop: IShop) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setShopInfo(shop);
    setOpen(true);
  };

  return (
    <>
      <StyledTableBody>
        {rows.map(({
          id, createdAt, shop, buyer, reason, orderItemId, orderItem: {
            price, name, image, listingId }, isAccepted, isDeclined,
        }) => {
          return (
            <TableRow
              hover
              key={id}
              tabIndex={-1}
              role="checkbox"
            >
              <TableCell align="left">
                <Link href={`${STAGING_URL}/product/${listingId}`} target="_blank" color="inherit">
                  <Avatar
                    src={image ? getCDNImagePath(image) : ''}
                    sx={{ width: 24, height: 24 }}
                  />
                </Link>
              </TableCell>
              <TableCell>{orderItemId}</TableCell>
              <TableCell>
                <Link href={`${STAGING_URL}/product/${listingId}`} target="_blank" color="inherit">
                  {name}
                </Link>
              </TableCell>
              <TableCell>
                { currencyParser(price)}
              </TableCell>
              <TableCell>{dateFormatter(createdAt)}</TableCell>
              <TableCell>
                <Typography onClick={(e: any) => handleOpen(e, shop)}>{shop?.name}</Typography>
                { open && (
                <StyledMenu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  disableScrollLock
                >
                  <MenuList dense>
                    <MenuItem>{ `Phone Number: ${shopInfo?.contactPhone}`}</MenuItem>
                    <MenuItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Box>
                        {`Address: ${shopInfo?.address?.street},`}
                      </Box>
                      {shopInfo?.address?.apartment && (
                      <Box>
                        {`${shopInfo?.address?.apartment},`}
                      </Box>
                      )}
                      <Box>
                        {`${shopInfo?.address?.city}, ${shopInfo?.address?.country},`}
                      </Box>
                    </MenuItem>
                  </MenuList>
                </StyledMenu>
                )}
              </TableCell>
              <TableCell>
                {buyer?.firstName}
                {' '}
                {buyer?.lastName}
              </TableCell>
              <TableCell>{reason}</TableCell>
              <TableCell>
                {((!isDeclined && !isAccepted) || (!isDeclined && isAccepted)) && (
                <IconButton onClick={() => handleAcceptRequest(id)} disabled={!isDeclined && isAccepted}>
                  <CheckCircleIcon color="success" />
                </IconButton>
                )}
                {((!isDeclined && !isAccepted) || (isDeclined && !isAccepted)) && (
                <IconButton onClick={() => handleDeclinedRequest(id)} disabled={isDeclined && !isAccepted}>
                  <RemoveCircleIcon color="error" />
                </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </StyledTableBody>

    </>
  );
};

export default memo(TableBody);
