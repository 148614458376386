import { FC, ReactNode } from 'react';

import { StyledContentPaper, StyledPaper, StyledTitle } from './styled';

interface IBannerCardPaperProps{
  title: ReactNode;
}

const BannerCardPaper: FC<IBannerCardPaperProps> = ({ children, title }) => (
  <StyledPaper>
    <StyledTitle variant="h5" component="h2">
      {title}
    </StyledTitle>
    <StyledContentPaper>
      {children}
    </StyledContentPaper>
  </StyledPaper>
);

export default BannerCardPaper;
