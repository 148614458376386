import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import Autocomplete from '../../common/components/Autocomplete';
import { COLORS } from '../../types/global/theme/colors';

export const StyledHomePageContent = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, minmax(170px, 420px));
  grid-gap: 24px;
`;

export const StyledHomePageContainer = styled(Box)`
  background: ${({ theme }) => theme.palette.grey['200']};
  padding: 32px 44px;
  margin-top: 32px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  max-width: 356px;
`;

export const StyledPaper = styled(Paper)`
  padding: 24px 44px 32px 20px;
`;

export const Input = styled('input')({
  display: 'none',
});

export const StyledChooseButton = styled(Button)<{component?: string}>({
  justifyContent: 'flex-start',
  padding: '15px 22px 14px 22px',
  borderRadius: '6px',
  color: COLORS.alpha.black087,
  borderColor: COLORS.alpha.black023,
});

export const StyledContainer = styled(Box)(() => ({
  marginTop: '32px',
}));
