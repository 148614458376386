import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';

import { COLORS } from '../../../../types/global/theme/colors';

export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-root,
  .MuiTableCell-root {
    font-size: 14px;
    padding: 16px;
  }
  
  .MuiLink-root {
    text-decoration: none;
  }

  .MuiTableRow-root {
    &.isEditable {
      background: ${COLORS.alpha.denim008};
    }
  }

  .MuiTableRow-hover {
    :hover {
      background: ${COLORS.alpha.denim008};
    }
  }
`;

export const StyledMenu = styled(Menu)(() => ({
  '.MuiPaper-root': {
    minWidth: '250px',
    minHeight: '130px',
    boxShadow: `0 0 0 1px ${COLORS.grey['500']}`,
  },
}));
