import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from '../../common/components/Button';

export const StyledDeleteContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  padding: '2px 10px 2px 10px',

  '&.MuiButton-containedSecondary': {
    ':hover': {
      background: theme.palette.secondary.main,
    },
  },

  '&.MuiButton-outlinedInfo': {
    color: theme.palette.grey['50'],
    borderColor: theme.palette.grey['50'],

    ':hover': {
      background: 'unset',
    },
  },
}));

export const NotActive = styled(Typography)<{component?: string}>(({ theme }) => ({
  color: theme.palette.grey['50'],
  cursor: 'pointer',
}));
