import { Box, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button from '../../common/components/Button';
import BaseInput from '../../common/components/Input/BaseInput';
import { attemptToSignIn } from '../../store/actions/auth';

export interface FormValues {
  email: string;
  password: string;
  isAdmin: boolean;
}

const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      isAdmin: true,
    },
  });

  const dispatch = useDispatch();

  const onSubmit = (data: FormValues) => {
    dispatch(attemptToSignIn(data));
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper sx={{ maxWidth: 396, width: '100%' }}>
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          spacing="18px"
          direction="column"
          sx={{ width: '100%', padding: '20px' }}
        >
          <BaseInput
            errors={errors}
            label="Email"
            inputProps={{
              placeholder: 'Email',
              ...register('email'),
            }}
          />

          <BaseInput
            errors={errors}
            label="Password"
            type="password"
            inputProps={{
              placeholder: 'Password',
              ...register('password'),
            }}
          />

          <Button type="submit" title="Log In" />
        </Stack>

      </Paper>

    </Box>
  );
};

export default SignIn;
