import { forwardRef } from 'react';

import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';

import { StyledRouterLink } from './styled';

const NavLink = forwardRef<any, LinkProps>((props, ref) => {
  const { children, to, ...restProps } = props;
  const resolved = useResolvedPath(to);

  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <StyledRouterLink isMatch={!!match}>
      <Link to={to} ref={ref} {...restProps}>
        {children}
      </Link>
    </StyledRouterLink>
  );
});

export default NavLink;
