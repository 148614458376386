export enum PAYMENT_STATUS {
  not_finished='Not Finished',
  payment_started='Started',
  payment_declined='Declined',
  payment_deposited='Deposited',
  payment_void='Void',
  payment_approved='Approved',
  payment_refunded='Refunded',
  payment_autoauthorized = 'Autoauthorized',
  pending= 'Pending'
}

export enum ORDER_STATUS {
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  READY_TO_SHIP = 'READY_TO_SHIP',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}
export interface Location {
  zip: string;
  city: string;
  state: string;
  street: string;
  address: string;
  country: string;
}

export interface AdditionalInfo {
  location: Location;
  lookingFor: string;
  socialLinks: any;
  specialties: string;
  profileLinks: any;
}

export interface Option {
  value: string;
  option: {
    name: string;
  };
}
export interface Variant {
  price: number;
  option1: Option;
  option2: Option;
  option3: Option;
  discount?: number;
}

export interface Brand {
  id: string;
  name: string;
}

export interface Image {
  image: string;
  metadata?: any;
}

export interface Listing {
  name: string;
  brands: Brand[];
  image: Image;
  id: string;
  fullSearch: string;
  shopId: string;
  categoryId: string;
  conditionId?: string;
  price: number;
  discount?: string;
  isActive: boolean;
  shortDescription: string;
  description: string;
  tags: string[];
  weight: number;
  height: number;
  width: number;
  quantity: number;
  ratesCount: number;
  salesCount: number;
  favoritesCount: number;
  view: number;
  rate: number;
  code?: string;
  featured: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface Shipping {
  id: string;
  userId: string;
  name: string;
  street: string;
  apt: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phone: string;
  type: string;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
}

export interface Buyer {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  isSeller: boolean;
  isActive: boolean;
  bio?: any;
  isVerified: boolean;
  role: string;
  image?: any;
  additionalInfo: AdditionalInfo;
  phone?: any;
  createdAt: string;
  updatedAt: string ;
  deletedAt?: any;
}

export interface Payment {
  Amount: number;
  ApprovedAmount: number;
  ApprovalCode: string;
  CardNumber: string;
  ClientName: string;
  ClientEmail: string;
  Currency: string;
  DateTime: string;
  DepositedAmount: number;
  Description: string;
  MDOrderID: string;
  MerchantId: string;
  TerminalId: string;
  OrderID: string;
  PaymentState: string;
  PaymentType: number;
  PrimaryRC: string;
  ResponseCode: string;
  ExpDate: string;
  ProcessingIP: string;
  OrderStatus: string;
  CardHolderID: string;
  BindingID: string;
  RefundedAmount: number;
  Opaque: string;
  TrxnDescription: string;
  rrn: string;
  ActionCode: string;
  ExchangeRate: number;
}

export interface ShippingAddress {
  id: string;
  userId: string;
  countryCode?: any;
  country: string;
  state: string;
  city: string;
  street: string;
  apartment: string;
  postalCode: string;
  isDefault: boolean;
  lat: string;
  lot: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface IShop {
  id: string;
  userId: string;
  businessTypeId: string;
  image: string;
  name: string;
  phone?: any;
  bankAccountNumber: string;
  registrationNumber: string;
  registrationName: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  location: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  email?: string;
  address: ShippingAddress;
  orderItems: ISingleOrderCore[];
}

export interface ISingleOrderCore {
  id: string;
  orderId: string;
  listingId: string;
  sellerId: string;
  buyerId: string;
  name: string;
  image: string;
  price: number;
  currency: string;
  discount?: number;
  quantity: number;
  variant: Variant;
  isReturned: boolean;
  createdAt: string;
  updatedAt: string;
  shop: IShop;
  phone: string;
  listing: Listing;
  seller: {
    username: string;
  };
  buyer: Buyer;
  status: keyof typeof ORDER_STATUS;
  total: number;
  items: ISingleOrderCore[];
  shippingAddress: ShippingAddress;
  shippingMethod: string;
  orderNote: string;
  paymentStatus: keyof typeof PAYMENT_STATUS;
  shops?: IShop[];
  isQtProblem: boolean;
  shippingCost: number;
}
export interface IOrdersCore {
  id: string;
  buyerId: string;
  paymentId: string;
  shippingAddressId: string;
  shippingMethod: string;
  status: keyof typeof ORDER_STATUS;
  paymentMethod: string;
  paymentStatus: keyof typeof PAYMENT_STATUS;
  total: number;
  createdAt: string;
  updatedAt: string;
  items: ISingleOrderCore[];
  shipping: Shipping;
  buyer: Buyer;
  payment: Payment;
}
