import { takeLatest, call, put } from 'redux-saga/effects';

import {
  getAllBannersApi,
  createHomeBannerDisconntectApi,
} from '../../requests/home-banner-card';
import {
  setAllHomeBannerDataAction,
  setBannerLoadingAction,
} from '../actions/banners';
import {
  BANNER_ACTIONS,
  IAttemptToCreateBannerCard,
  IAttemptToDisconnectBanner,
  IAttemptToUpdateBannerCard,
} from '../types/banners';
import handleSagaError from '../../utils/handle-errors';
import {
  createBannerCardlApi,
  updateBannerCardByIdApi,
} from '../../requests/banner-card';
import { HomeAllBanner } from '../../models/banner-card/home-all-model';

function* allBanners() {
  try {
    yield put(setBannerLoadingAction(true));

    const data: HomeAllBanner[] = yield call(getAllBannersApi);

    yield put(setAllHomeBannerDataAction(data));
  } catch (err: unknown) {
    yield put({
      type: BANNER_ACTIONS.SET_BANNER_FAILURE,
      payload: handleSagaError(err),
    });
  } finally {
    yield put(setBannerLoadingAction(false));
  }
}

function* createBannerCard({ payload }: Required<IAttemptToCreateBannerCard>) {
  try {
    payload.bannerData;

    yield put(setBannerLoadingAction(true));

    yield call(
      createBannerCardlApi,
      payload.bannerData,
    );

    const data: HomeAllBanner[] = yield call(getAllBannersApi);

    yield put(setAllHomeBannerDataAction(data));
  } catch (err: unknown) {
    yield put({
      type: BANNER_ACTIONS.SET_BANNER_FAILURE,
      payload: handleSagaError(err),
    });
  } finally {
    yield put(setBannerLoadingAction(false));
  }
}

function* updateBanners({ payload }: Required<IAttemptToUpdateBannerCard>) {
  try {
    const { bannerData, cardId } = payload;

    yield put(setBannerLoadingAction(true));

    yield call(
      updateBannerCardByIdApi,
      { payload: bannerData, cardId },
    );

    const data: HomeAllBanner[] = yield call(getAllBannersApi);

    yield put(setAllHomeBannerDataAction(data));
  } catch (err: unknown) {
    yield put({
      type: BANNER_ACTIONS.SET_BANNER_FAILURE,
      payload: handleSagaError(err),
    });
  } finally {
    yield put(setBannerLoadingAction(false));
  }
}

function* disconnectBanner({ payload }: Required<IAttemptToDisconnectBanner>) {
  try {
    const { bannerId } = payload;

    yield put(setBannerLoadingAction(true));

    yield call(
      createHomeBannerDisconntectApi,
      { bannerId },
    );

    const data: HomeAllBanner[] = yield call(getAllBannersApi);

    yield put(setAllHomeBannerDataAction(data));
  } catch (err: unknown) {
    yield put({
      type: BANNER_ACTIONS.SET_BANNER_FAILURE,
      payload: handleSagaError(err),
    });
  } finally {
    yield put(setBannerLoadingAction(false));
  }
}

function* bannersSaga() {
  yield takeLatest(BANNER_ACTIONS.ATTEMPT_TO_GET_ALL_BANNERS, allBanners);
  yield takeLatest(BANNER_ACTIONS.ATTEMPT_TO_UPDATE_BANNER_CARD, updateBanners);
  yield takeLatest(BANNER_ACTIONS.ATTEMPT_TO_DISCONNECT_BANNER, disconnectBanner);
  yield takeLatest(
    BANNER_ACTIONS.ATTEMPT_TO_CREATE_BANNER_CARD,
    createBannerCard,
  );
}

export default bannersSaga;
