import _ from 'lodash';

import { ISignInResponseType } from '../models/auth-model';
import { Translation } from '../types/category';
import { LanguageEnum } from '../types/enums/translations';
import {
  AMAZON_S3_CDN_URL,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN,
  APP_CONFIG,
} from './constants';
import StorageManager from './storage-manager';

export const sleep = (delay = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export const getCDNImagePath = (path: string) => `${AMAZON_S3_CDN_URL}/${path}`;

export const camelCaseToSensativeCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

  return finalResult;
};

export const replceSpacingWithUnderscore = (text: string): string => {
  const trimedText = text?.trim().replace(/\s+/g, '_').toLowerCase();

  return `${trimedText}_${Date.now()}`;
};

export const sortedCollection = (collection?: Translation[]): Translation[] => (
  _.sortBy(collection, (item) => [LanguageEnum.en, LanguageEnum.hy, LanguageEnum.ru].indexOf(item.lang as LanguageEnum))
);

export const changeLanguageCode = (lang: string) => {
  const langs: Record<string, string> = {
    hy: 'am',
    en: 'us',
    ru: 'ru',
  };

  return langs[lang];
};

export const formatPhoneNumber = (
  phoneNumberString: number | string,
): string => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return '';
};

export const setLocaleStorageData = ({
  accessToken,
  refreshToken,
}: ISignInResponseType) => {
  StorageManager.setItem(ACCESS_TOKEN_KEY, accessToken);
  StorageManager.setItem(REFRESH_TOKEN, refreshToken);

  // TODO: check need this function or not
  // setAuthorizationToken(access_token);
};

export const getYMD = (d: string | Date): string => {
  if (!d) {
    return '';
  }

  const date = new Date(d);

  const yyyy = date.getFullYear();
  let mm: number | string = date.getMonth() + 1; // Months start at 0!
  let dd: number | string = date.getDate();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  return `${yyyy}/${mm}/${dd}`;
};

export const dateFormatter = (date: string = '') => {
  const formattedHours = new Date(date).toLocaleString('pl-PL', {
    hour: '2-digit', minute: '2-digit',
  });

  const formattedDay = new Date(date).toLocaleString('pl-PL', {
    year: 'numeric', month: 'numeric', day: 'numeric',
  });

  return `${formattedHours}, ${formattedDay}`;
};

export const initialTranlationsState = [
  { lang: LanguageEnum.en, text: '' },
  { lang: LanguageEnum.hy, text: '' },
  { lang: LanguageEnum.ru, text: '' },
];

export const LANGUAGES_LIST = ['en', 'am', 'ru'];
export const LANGUAGES_ENUM_LIST = [LanguageEnum.en, LanguageEnum.hy, LanguageEnum.ru];

export const currencyParser = (
  number: number | string | null,
  withZeroAndDollar: boolean = false,
  locales: string = APP_CONFIG.LOCALE,
): string => {
  const removedCommasNumber = String(number).split(',').join('.');

  return `${new Intl.NumberFormat(
    locales,
    withZeroAndDollar ? {} : {},
  ).format(Number(removedCommasNumber))} AMD`;
};
