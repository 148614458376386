import { useCallback, useEffect, useState, ChangeEventHandler, memo } from 'react';

import debounce from 'lodash/debounce';
import Box from '@mui/material/Box';
import { useConfirm } from 'material-ui-confirm';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import PageTitle from '../../common/components/PageTitle';
import { StyledContainer } from '../components/StyledContainer';
import SearchField from '../../common/components/SearchField';
import Table from './Table';
import { AllProductsCore } from '../../types/allProducts';
import Loader from '../../common/components/Loader';
import { ProductsSearchPayload } from '../../models/product-model';
import {
  deactivateProductApi,
  filterProductsApi, productDeleteApi, searchProductsApi,
} from '../../requests/allProducts';
import { deleteConfirmOption } from '../../common/components/Table/helper';
import { TableData } from './Table/helper';
import { Order } from '../../utils/types';

const AllProducts = () => {
  const [products, setProducts] = useState<AllProductsCore[]>([]);
  const navigate = useNavigate();
  const [totalProducts, setTotalProducts] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>();
  const confirm = useConfirm();
  const { page: queryOffset } = queryString.parse(window.location.search);
  const [offset, setOffset] = useState(queryOffset ? +queryOffset : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isEqualQuery = offset === (queryOffset && +queryOffset);

  const getAllProducts = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearch('');

      const offsetOrder = offset * rowsPerPage;
      const offSetValue = (offsetOrder <= totalProducts || isEqualQuery) ? offsetOrder : totalProducts;

      const payloadData: ProductsSearchPayload = {
        limit: rowsPerPage,
        offset: offSetValue,
      };

      if (orderBy) {
        payloadData.orderBy = order.toUpperCase();
        payloadData.sortBy = orderBy;
      }

      const { data, meta: { pagination: { total } } } = await searchProductsApi(payloadData);

      setProducts(data);
      setTotalProducts(total);
      navigate({
        search: offset ? `?page=${offset}` : '',
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, orderBy, order, navigate, search]);

  useEffect(() => {
    if (search) {
      const searchProducts = async () => {
        try {
          const { data, meta: { pagination: { total } } } = await searchProductsApi({
            searchKey: search,
            limit: rowsPerPage,
            offset: queryOffset ? 0 : offset * rowsPerPage,
          });

          setProducts(data);
          setTotalProducts(total);
          queryOffset && setOffset(0);
          navigate({ search: '' });
        } catch (error) {
          throw error;
        }
      };

      searchProducts();
    } else {
      getAllProducts();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, search, navigate]);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = debounce(
    (event) => {
      setSearch(event.target.value);
    },
    1000,
  );

  const handleSortRequest = useCallback(
    async (ascOrDesc: Order, sort: keyof TableData) => {
      try {
        setIsLoading(true);
        setSearch('');

        const { data, meta: { pagination: { total } } } = await filterProductsApi({
          orderBy: ascOrDesc.toUpperCase(),
          sortBy: sort,
          offset: 0,
          limit: rowsPerPage,
        });

        setOffset(0);
        setOrder(ascOrDesc);
        setOrderBy(sort);
        setProducts(data);
        setTotalProducts(total);
      } finally {
        setIsLoading(false);
      }
    },
    [rowsPerPage],
  );

  const handleActivateRequest = async (productId: string, isBlocked: boolean) => {
    try {
      await deactivateProductApi(productId, {
        isBlocked,
      });

      getAllProducts();
    } catch (e) {
      throw e;
    }
  };

  const handleDeleteRequest = useCallback(
    async (productId: string) => {
      await confirm(deleteConfirmOption);

      const result = await productDeleteApi(productId);

      if (!result) {
        getAllProducts();
      }
    },
    [confirm, getAllProducts],
  );

  if (isLoading) {
    return <Loader sx={{ justifyContent: 'center', padding: '10px' }} />;
  }

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="All Products" />
          <Breadcrumbs />
        </Box>
        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            onChange={handleSearchChange}
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
          />
        </Box>

      </StyledContainer>
      <Box>
        {products?.length ? (
          <Table
            products={products}
            offset={offset}
            order={order}
            orderBy={orderBy}
            setOffset={setOffset}
            totalProducts={totalProducts}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleDeleteRequest={handleDeleteRequest}
            handleActivateRequest={handleActivateRequest}
            getAllProducts={getAllProducts}
            handleSortRequest={handleSortRequest}
          />
        ) : (
          <Typography>Still there is no product</Typography>
        )}
      </Box>
    </>
  );
};

export default memo(AllProducts);
