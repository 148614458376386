import { HomeAllBanner } from '../../../../models/banner-card/home-all-model';
import { CardTypes } from '../../../../types/enums/banners';
import { CreateBannerLabelEnum } from '../../helper';
import AddBannerCard from '../cards/AddBannerCard';
import FullRowBanner from '../cards/FullRowBanner';
import ImageCarouselBanner from '../cards/ImageCarouselBanner';
import ProductsBanner from '../cards/ProductsBanner';
import RunningLineBanner from '../cards/RunningLineBanner';

export const renderActiveCreateBanner = (banner?: CreateBannerLabelEnum) => {
  switch (banner) {
    case CreateBannerLabelEnum.ImageCarousel:
      return <ImageCarouselBanner isNew />;
    case CreateBannerLabelEnum.ADBanner:
      return <AddBannerCard isNew />;
    case CreateBannerLabelEnum.FullRowBanner:
      return <FullRowBanner isNew />;
    case CreateBannerLabelEnum.RunningLineBanner:
      return <RunningLineBanner isNew />;
    case CreateBannerLabelEnum.ProductsBanner:
      return <ProductsBanner isNew />;
    default:
      return null;
  }
};

type HomeCardType = {
  isNew?: boolean;
  bannerType?: CardTypes;
  bannerData?: HomeAllBanner;
};

export const renderHomeCardsByType = ({
  isNew = false,
  bannerType,
  bannerData,
}: HomeCardType) => {
  bannerData?.type;

  switch (bannerType) {
    case CardTypes.carousel:
      return <ImageCarouselBanner isNew={isNew} bannerData={bannerData} />;
    case CardTypes.ad:
      return <AddBannerCard isNew={isNew} bannerData={bannerData} />;
    case CardTypes.fullRow:
      return <FullRowBanner isNew={isNew} bannerData={bannerData} />;
    case CardTypes.runningLine:
      return <RunningLineBanner isNew={isNew} bannerData={bannerData} />;
    case CardTypes.filtered:
      return <ProductsBanner isNew={isNew} bannerData={bannerData} />;
    default:
      return null;
  }
};
