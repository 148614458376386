import { memo, useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { Snackbar } from '@mui/material';

import { StyledRedText, StyledTableBody } from './styled';
import { AllProductsCore } from '../../../../types/allProducts';
import { currencyParser, dateFormatter, getCDNImagePath } from '../../../../utils/helpers';
import MorePopover from '../../../../common/components/Table/MorePopover';
import { STAGING_URL } from '../../../../utils/constants';
import { changeFeaturedApi } from '../../../../requests/allProducts';
import Alert from '../../../../common/components/Alert ';

interface ITableBodyProps {
  page: number;
  rows: AllProductsCore[];
  rowsPerPage: number;
  handleDeleteRequest: (productId: string) => Promise<void>;
  handleActivateRequest: (productId: string, isBlocked: boolean) => Promise<void>;
  getAllProducts: () => void;
}

const TableBody = ({
  rows,
  page,
  rowsPerPage,
  handleDeleteRequest,
  handleActivateRequest,
  getAllProducts,
}: ITableBodyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const slicedAndSorted = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const [err, setErr] = useState(false);

  const changeFeature = async (id: string, featured: boolean) => {
    try {
      await changeFeaturedApi(id, { featured });

      getAllProducts();
    } catch (e: any) {
      if (e.data.errors.error === 'FeaturedOverCount') {
        setErr(true);
      }
    }
  };

  useEffect(() => {
    if (err) {
      const interval = setInterval(() => {
        setErr(false);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [err]);

  return (
    <>
      { err && (
      <Snackbar
        open={err}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginTop: '40px !important' }}
      >
        <Alert severity="error">
          You can not make featured more than 4 items!
        </Alert>
      </Snackbar>
      )}
      <StyledTableBody>
        {rows.map(({
          id, name, createdAt, images, shop, price, featured, isBlocked,
        }) => (
          <TableRow
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            style={{ height: 64, opacity: !isBlocked ? 1 : 0.4 }}
          >
            <TableCell align="left">
              <Link href={`${STAGING_URL}/product/${id}`} target="_blank" color="inherit">
                <Avatar
                  src={images?.[0]?.image ? getCDNImagePath(images?.[0]?.image) : ''}
                  sx={{ width: 24, height: 24 }}
                />
              </Link>
            </TableCell>
            <TableCell>{id}</TableCell>
            <TableCell>
              <Link href={`${STAGING_URL}/product/${id}`} target="_blank" color="inherit">
                {name}
              </Link>
            </TableCell>
            <TableCell>{currencyParser(price)}</TableCell>
            <TableCell>{dateFormatter(createdAt)}</TableCell>
            <TableCell>
              <Link href={`${STAGING_URL}/other-user-profile/${shop.id}`} target="_blank" color="inherit">
                {shop?.name}
              </Link>
            </TableCell>
            <TableCell>
              <StyledRedText>{featured && 'Featured'}</StyledRedText>
            </TableCell>

            {/** ACTIONS */}
            <TableCell sx={{ width: '10px', padding: 0 }} align="right">
              <MorePopover>
                <Box
                  onClick={() => handleActivateRequest(id, !isBlocked)}
                  sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                >
                  <Typography variant="body1">
                    {isBlocked ? 'Unblock' : 'Block'}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  onClick={() => handleDeleteRequest(id)}
                  sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                >
                  <Typography variant="body1">Delete</Typography>
                </Box>
                <Divider />
                <Box
                  onClick={() => changeFeature(id, !featured)}
                  sx={{ width: '230px', padding: '14px 16px', cursor: 'pointer' }}
                >
                  <Typography variant="body1">{featured ? 'Remove Featured' : 'Make Featured' }</Typography>
                </Box>
              </MorePopover>
            </TableCell>
          </TableRow>
        ))}
      </StyledTableBody>
    </>
  );
};

export default memo(TableBody);
