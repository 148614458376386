import { NodeModel } from '@minoru/react-dnd-treeview';

import { ICategoryCore } from '../../../types/category';
import { AdditionalDataType } from '../helper';

export enum DepthEnum {
  level1,
  level2,
  level3,
}

export const sanitizeCategory = (obj: ICategoryCore): NodeModel<AdditionalDataType> => {
  const { id, parentId, translation, isActive } = obj;

  return {
    id,
    parent: parentId || 0,
    text: translation?.text || '',
    droppable: true,
    data: {
      isActive,
      hasChild: false,
      depth: 0,
    },
  };
};

export const sanitizeData = (data: ICategoryCore[]): NodeModel<AdditionalDataType>[] => {
  return data.map((item) => {
    return sanitizeCategory(item);
  });
};
