import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import { breakpoints } from './breakpoints';
import { MuiButton } from './buttons';
import { MuiPaper } from './paper';
import { MuiCssBaseline } from './globalStyles';
import { COLORS } from '../types/global/theme/colors';

const theme = createTheme({
  palette,
  shadows,
  typography,
  components: {
    MuiButton,
    MuiPaper,
    MuiCssBaseline,
  },
  breakpoints,
});

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginBottom: '20px',
        borderRadius: '5px',
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: 'auto',
      },
      editor: {
        display: 'table-row',
        backgroundColor: COLORS.white,
        width: '100%',
        minHeight: '200px',
        padding: '0 20px',
        border: `1px solid ${COLORS.grey['500']}`,
        background: '#000',
      },
      placeHolder: {
        width: '100%',
        backgroundColor: COLORS.white,
        paddingLeft: 20,
        minHeight: '190px',
        position: 'absolute',
        top: '42px',
        borderLeft: `1px solid ${COLORS.grey['500']}`,
        borderRight: `1px solid ${COLORS.grey['500']}`,
      },
      anchorLink: {
        color: COLORS.black,
        textDecoration: 'underline',
      },
    },
  },
});

export default responsiveFontSizes(theme);
