import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { COLORS } from '../../../../types/global/theme/colors';

export const StyledTreeViewNode = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '32px',
  borderBottom: `solid 1px ${COLORS.grey[800]}`,

  '.MuiLink-root': {
    textDecoration: 'none',
  },
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledTreeIcon = styled(Box)(() => ({
  cursor: 'pointer',
  transition: 'transform linear 0.1s',
  transform: 'rotate(-90deg)',
  width: '16px',
  height: '16px',
  marginRight: '15px',
}));

export const StyledTextField = styled(Box)(() => ({
  input: {
    height: '32px',
    padding: 0,
    fontSize: '12px',
    borderBottom: `1px solid ${COLORS.blue.main}`,
  },

  fieldset: {
    border: 'none',
    fontSize: '12px',
  },
}));
