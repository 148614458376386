import { ChangeEventHandler, memo, useState } from 'react';

import Box from '@mui/material/Box';
import debounce from 'lodash/debounce';
import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import Paper from '@mui/material/Paper';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import PageTitle from '../../common/components/PageTitle';
import SearchField from '../../common/components/SearchField';
import { StyledContainer } from '../components/StyledContainer';
import DndComponent from './DragAndDrop';
import NewCategoryModal from './CreateCategory/CreateCategoryModal';
import { sanitizeData } from './DragAndDrop/helpers';
import { getAllCategoryApi, updateCategoryTree } from '../../requests/category';
import { AdditionalDataType, canDragAndCrop, filterTreeView, sortTreeViewList } from './helper';
import Button from '../../common/components/Button';
import useMount from '../../hooks/useMount';

const Category = () => {
  const [open, setOpen] = useState(false);
  const [parentId, setParentId] = useState<string | number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const addSubCategory = (parent: string | number) => {
    setOpen(true);

    setParentId(parent);
  };

  const handleClose = () => {
    setOpen(false);
    setParentId(undefined);
  };

  const [treeData, setTreeData] = useState<NodeModel<AdditionalDataType>[]>([]);
  const handleDrop = async (newTree: NodeModel<AdditionalDataType>[], options: DropOptions<AdditionalDataType>) => {
    const canDND = canDragAndCrop(options);
    const { dropTargetId } = options;

    if (canDND && !isLoading) {
      try {
        setIsLoading(true);

        const sortedTree = sortTreeViewList(newTree);

        const treeList = filterTreeView(sortedTree, dropTargetId);

        await updateCategoryTree({
          data: treeList,
        });

        setTreeData(sortedTree);
      } catch (e) {
        throw e;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getCategories = async () => {
    try {
      const response = await getAllCategoryApi();

      response && setTreeData(sanitizeData(response));
    } catch (e) {
      throw e;
    }
  };

  useMount(() => getCategories());

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = debounce(
    (event) => {
      console.log(event.target.value);
    },
    1000,
  );

  const createHandler = () => {
    getCategories();
    handleClose();
  };

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Category" />
          <Breadcrumbs />
        </Box>

        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            onChange={handleSearchChange}
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
          />
        </Box>
      </StyledContainer>
      <Paper sx={{ borderRadius: '6px 6px 0 0', boxShadow: 'none' }}>
        <Box sx={{ padding: '10px', display: 'flex', justifyContent: 'end' }}>
          <Button
            sx={{ height: '40px' }}
            onClick={handleClickOpen}
          >
            Add
          </Button>
        </Box>
      </Paper>
      <DndComponent
        treeData={treeData}
        handleDrop={handleDrop}
        deleteHandler={getCategories}
        editHandler={getCategories}
        addHandler={addSubCategory}
      />
      {open && (
      <NewCategoryModal
        open={open}
        handleClose={handleClose}
        createHandler={createHandler}
        parentId={parentId}
      />
      )}
    </>
  );
};

export default memo(Category);
