import { memo, useState } from 'react';

import Stack from '@mui/material/Stack';
import { useFormContext } from 'react-hook-form';
import Flag from 'react-world-flags';

import UploadContainer from '../../../../../UploadContainer';
import InputLabel from '../../../../../../../../common/components/InputLabel/InputLabel';
import Link from './components/Link';
import { LANGUAGES_LIST } from '../../../../../../../../utils/helpers';

interface IBannerProps {
  bannerIndex: number;
}

const Banner = ({ bannerIndex }: IBannerProps) => {
  const { setValue, watch, formState: { errors } } = useFormContext();

  const [itemImageEN, itemImageAM, itemImageRU] = watch([
    `allBanners.translations.${0}.content.[${bannerIndex}].image`,
    `allBanners.translations.${1}.content.[${bannerIndex}].image`,
    `allBanners.translations.${2}.content.[${bannerIndex}].image`,
  ]);

  const [imagePreivew, setImagePreview] = useState<string>(itemImageEN || '');
  const [imagePreivewAM, setImagePreviewAM] = useState<string>(itemImageAM || '');
  const [imagePreivewRU, setImagePreviewRU] = useState<string>(itemImageRU || '');

  return (
    <Stack spacing="18px" direction="column">
      {
        LANGUAGES_LIST.map((lang, idx) => (
          <UploadContainer
            key={lang}
            label={(
              <InputLabel
                title="Image"
                flag={<Flag code={lang === 'en' ? 'us' : lang} height="22" width="22" />}
              />
            )}
            errors={errors}
            setValue={setValue}
            imagePreivew={
              lang === 'en' ? imagePreivew : (lang === 'am' ? imagePreivewAM : imagePreivewRU)
            }
            setImagePreview={
              lang === 'en' ? setImagePreview : (lang === 'am' ? setImagePreviewAM : setImagePreviewRU)
            }
            id={`add-carousel-banner-card-upload-${lang}`}
            inputName={`allBanners.translations.${idx}.content.${bannerIndex}.image`}
          />
        ))
      }
      {/* // TODO maybe used in future */}
      {/* <Title bannerIndex={bannerIndex} /> */}

      <Link bannerIndex={bannerIndex} />

    </Stack>
  );
};

export default memo(Banner);
