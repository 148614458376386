import { styled, alpha } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { COLORS } from '../../../../../../types/global/theme/colors';
import { FontFamilyNames } from '../../../../../../types/global/theme/fonts';

export const StyledTabs = styled(Tabs)(() => ({
  '&.MuiTabs': {
    '&-root': {
      position: 'relative',

      '.MuiTabs-scroller': {
        height: '100%',

        '.MuiTabs-indicator': {
          display: 'none',
        },

        '.Mui-selected': {
          color: COLORS.black,
        },
      },

      '.MuiTabScrollButton-root': {
        display: 'none',
      },
    },
  },
}));

export const StyledTab = styled(Tab)(() => ({
  '&.MuiTab': {
    '&-root': {
      padding: 0,
      minHeight: '0',
      opacity: '1',
      textTransform: 'none',
      fontFamily: FontFamilyNames.CircularStdBook,
      fontWeight: 450,
      fontSize: 16,
      letterSpacing: '-0.04em',
      minWidth: '65px',
      marginRight: '16px',
      color: alpha(COLORS.black, 0.3),
    },
  },
}));
