import { Components, Theme } from '@mui/material';

import { FontFamilyNames } from '../types/global/theme/fonts';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: {
      fontFamily: FontFamilyNames.RobotoNormal400,
      fontSize: '13px',
      lineHeight: '18px',
      padding: '7px 10px',
      letterSpacing: '0.16px',
      borderRadius: '16px',
      textTransform: 'none',
      boxShadow: 'unset',
      fontWeight: '400',
      ':hover': {
        boxShadow: 'unset',
      },
    },
  },
};
