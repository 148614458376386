import { IAuthState } from './auth';
import { IBannerState } from './banners';

export enum StoreReducersEnum {
  AUTH = 'auth',
  BANNERS = 'banners',
}
export interface AppState {
  [StoreReducersEnum.AUTH]: IAuthState;
  [StoreReducersEnum.BANNERS]: IBannerState;
}
