const BASE_ERRORS = {
  AUTHENTICATION: 'AUTHENTICATION_ERROR',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND_ERROR',
  INVALID_USER_INPUT: 'INVALID_USER_INPUT',
};

const ERROR_TYPES = {
  FORBIDDEN: 'FORBIDDEN',
  UNAUTHORIZED: 'UNAUTHORIZED',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
};

const ERROR_MESSAGES = {
  FORBIDDEN: 'Forbidden',
  INCORRECT_EMAIL_OR_PASSWORD: 'Your email or password is incorrect',
  UNKNOWN: 'Unknown error',
  NETWORK: 'Network error',
  RESOURCE_NOT_FOUND: 'Resource not found',
};

const ENDPOINT_MATCHES = {
  SIGN_IN: '/auth/signIn',
};

const handleAuthError = (error?: any, url?: string) => {
  switch (error.message) {
    case ERROR_TYPES.FORBIDDEN:
      if (ENDPOINT_MATCHES.SIGN_IN === url) {
        return ERROR_MESSAGES.INCORRECT_EMAIL_OR_PASSWORD;
      }

      return ERROR_MESSAGES.FORBIDDEN;
    default:
      return ERROR_MESSAGES.INCORRECT_EMAIL_OR_PASSWORD;
  }
};

const handleSagaError = (error: any) => {
  const err = error?.response?.data?.error;
  const config = error?.response?.config?.url;
  let message = '';

  if (err && err.debug) {
    switch (err.debug) {
      case BASE_ERRORS.AUTHENTICATION:
        message = handleAuthError(err, config?.url);
        break;
      default:
        message = ERROR_MESSAGES.UNKNOWN;
    }
  }

  return message;
};

export default handleSagaError;
