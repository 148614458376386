import {
  memo,
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Breadcrumbs from '../../common/components/Breadcrumbs';
import SearchField from '../../common/components/SearchField';
import {
  getOrderByIdApi,
  openOrderByIdApi,
  ordersExportApi,
  ordersFilterApi,
  searchOrdersApi,
} from '../../requests/orders';
import Table from './Table/index';
import Loader from '../../common/components/Loader/index';
import { Order } from '../../utils/types';
import { sanitizeAllOrders, sanitizeSingleOrder, TableData } from './Table/helper';
import { StyledContainer } from '../components/StyledContainer';
import PageTitle from '../../common/components/PageTitle';
import { StyledDialog, StyledDialogBotom, StyledDialogTop } from './styled';
import { OrderSearchPayload } from '../../models/order-model';
import Button from '../../common/components/Button';
import Map from './Map';
import BuyerInfo from './BuyerInfo';
import OrderNotes from './OrderNotes';
import { ISingleOrderCore, ORDER_STATUS } from '../../types/orders';
import DownloadPage from './DownloadPage';
import RefundShipping from './RefundShipping';

type OrderFilterPayload = {
  isSeller?: boolean;
  offset?: number;
  limit?: number;
  orderBy?: string;
  sortBy?: string;
};

const Orders = () => {
  const [open, setOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(null);
  const [singleOrderRow, setSingleOrderRow] = useState<TableData[] | []>([]);
  const [orders, setOrders] = useState<TableData[]>([]);
  const [singleOrderInfo, setSingleOrderInfo] = useState<ISingleOrderCore>({} as ISingleOrderCore);
  const [isLoading, setIsLoading] = useState(true);
  const [totalOrders, setTotalOrders] = useState(10);
  const { page: queryOffset, id: queryId } = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>();
  const [search, setSearch] = useState('');
  const [currentId, setCurrentId] = useState <string | null>(null);
  const [offset, setOffset] = useState(queryOffset ? +queryOffset : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isEqualQuery = offset === (queryOffset && +queryOffset);

  const gettingSingleOrderInfo = async (id: string) => {
    const { data: singleOrderData } = await getOrderByIdApi(id);

    if (singleOrderData) {
      setCurrentId(id);
      setSingleOrderInfo(singleOrderData);
      setSingleOrderRow(sanitizeSingleOrder(singleOrderData));
      setOpen(true);

      navigate({
        search: offset ? `?page=${offset}&id=${id}` : `?id=${id}`,
      });
    }
  };

  const handleOpen = (id: string, status: keyof typeof ORDER_STATUS) => {
    const openOrder = async () => {
      try {
        await openOrderByIdApi(id);

        gettingSingleOrderInfo(id);
      } catch (e) {
        throw e;
      }
    };

    status === 'PENDING' ? openOrder() : gettingSingleOrderInfo(id);
  };

  const handleClose = () => {
    setOpen(false);
    setSingleOrderInfo({} as ISingleOrderCore);
    setCurrentId(null);
    getFilteredOrders();
    navigate({
      search: offset ? `?page=${offset}` : '',
    });
  };

  const getFilteredOrders = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearch('');

      const offsetOrder = offset * rowsPerPage;
      const offSetValue = (offsetOrder <= totalOrders || isEqualQuery) ? offsetOrder : totalOrders;

      const payloadData: OrderFilterPayload = {
        limit: rowsPerPage,
        offset: offSetValue,
      };

      if (orderBy) {
        payloadData.orderBy = order?.toUpperCase();
        payloadData.sortBy = orderBy;
      }

      const { data, meta: { pagination: { total } } } = await searchOrdersApi(payloadData);

      setOrders(sanitizeAllOrders(data));
      setTotalOrders(total);

      navigate({
        search: offset ? `?page=${offset}` : '',
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, order, orderBy, navigate, totalOrders]);

  const handleSortRequest = useCallback(
    async (ascOrDesc: Order, sort: keyof TableData) => {
      try {
        setIsLoading(true);
        setSearch('');

        const { data, meta: { pagination: { total } } } = await ordersFilterApi({
          orderBy: ascOrDesc.toUpperCase(),
          sortBy: sort,
          offset: 0,
          limit: rowsPerPage,
        });

        setOrder(ascOrDesc);
        setOrderBy(sort);
        setOrders(sanitizeAllOrders(data));
        setTotalOrders(total);
        setOffset(0);
      } finally {
        setIsLoading(false);
      }
    },
    [rowsPerPage],
  );

  useEffect(() => {
    if (search) {
      const searchOrders = async () => {
        try {
          const { data, meta: { pagination: { total } } } = await searchOrdersApi({
            searchKey: search,
            limit: rowsPerPage,
            offset: queryOffset ? 0 : offset * rowsPerPage,
          });

          setOrders(sanitizeAllOrders(data));
          setTotalOrders(total);
          queryOffset && setOffset(0);
          navigate({ search: '' });
        } catch (error) {
          throw error;
        }
      };

      searchOrders();
    } else {
      getFilteredOrders();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, search, navigate]);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = debounce(
    (event) => {
      setSearch(event.target.value);
    },
    1000,
  );

  const checkOrderCount = async () => {
    const body = {
      offset: 0,
      limit: 1,
    } as OrderSearchPayload;

    if (search) {
      body.searchKey = search;
    }

    const { meta: { pagination: { total } } } = await searchOrdersApi(body);

    if (total !== totalOrders) {
      getFilteredOrders();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkOrderCount();
    }, 15000);

    return () => clearInterval(interval);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalOrders]);

  const handleStatusChanging = () => {
    currentId && gettingSingleOrderInfo(currentId);
  };

  const ordersExport = async () => {
    if (currentId) {
      const { data } = await ordersExportApi(currentId);

      setIsDownload(data);

      window.print();
      setIsDownload(null);
    }
  };

  window.onafterprint = () => {
    setIsDownload(null);
  };

  if (isLoading) {
    return <Loader sx={{ justifyContent: 'center', padding: '10px' }} />;
  }

  return (
    <>
      <StyledContainer>
        <Box>
          <PageTitle title="Orders" />
          <Breadcrumbs />
        </Box>

        <Box sx={{ flexBasis: '368px' }}>
          <SearchField
            onChange={handleSearchChange}
            variant="outlined"
            label="Search"
            fullWidth
            InputProps={{
              type: 'search',
            }}
          />
        </Box>
      </StyledContainer>

      {orders?.length ? (
        <Table
          order={order}
          orderBy={orderBy}
          rows={orders}
          offset={offset}
          setOffset={setOffset}
          totalOrders={totalOrders}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleSortRequest={handleSortRequest}
          handleOpen={handleOpen}
          isDialogContent={false}
          getFilteredOrders={getFilteredOrders}
        />
      ) : (
        <Typography>Still there is no order</Typography>
      )}

      {open && (
      <StyledDialog
        open={open}
        handleClose={handleClose}
        fullWidth
      >
        <StyledDialogTop>
          <Button onClick={ordersExport}>Download order report</Button>
        </StyledDialogTop>
        <Table
          order={order}
          rows={singleOrderRow}
          offset={offset}
          orderBy={orderBy}
          setOffset={setOffset}
          totalOrders={totalOrders}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleSortRequest={handleSortRequest}
          isDialogContent
          handleStatusChanging={handleStatusChanging}
        />
        <StyledDialogBotom>
          <BuyerInfo {...singleOrderInfo} />
          {singleOrderInfo?.orderNote && <OrderNotes orderNote={singleOrderInfo?.orderNote} />}
          <RefundShipping id={queryId as string} shippingCost={singleOrderInfo?.shippingCost} />
          <Map lat={singleOrderInfo?.shippingAddress?.lat} lot={singleOrderInfo?.shippingAddress?.lot} />
        </StyledDialogBotom>

      </StyledDialog>
      )}

      {!!isDownload && (
      <StyledDialog
        open={!!isDownload}
        handleClose={handleClose}
        fullWidth
      >
        <DownloadPage data={isDownload} />
      </StyledDialog>
      )}
    </>
  );
};

export default memo(Orders);
