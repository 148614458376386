import { memo } from 'react';

import { PRIVACY_ENUM } from '../../../../models/privacy-model';
import Policy from './Policy';

const PrivacyPolicy = () => (
  <>
    <Policy type={PRIVACY_ENUM.PRIVACY} />
  </>
);

export default memo(PrivacyPolicy);
