import { AxiosResponse } from 'axios';

import {
  CategoryCreatePayload, CategoryUpdateTreePayload, CategoryUpdateType,
} from '../models/category-model';
import { RefundableCategoryPayload, SpecificationGroupCore } from '../models/category-specifications-model';
import { http } from '../services/RequestService';
import { ICategoryCore } from '../types/category';

const prefix = '/categories';

export const getAllCategoryApi = async () => {
  const response = await http.get<AxiosResponse<ICategoryCore[]>>(prefix);

  return response.data.data;
};

export const getCategoryById = async (id: string | number) => {
  const response = await http.get <AxiosResponse<ICategoryCore>>(`${prefix}/${id}/info`);

  return response.data.data;
};

export const createCategoryApi = async (data: CategoryCreatePayload) => {
  const response = await http.post<
    CategoryCreatePayload,
    AxiosResponse<ICategoryCore>
    >(`${prefix}`, data);

  return response.data;
};

export const updateCategoryByIdApi = async (
  id: string,
  data: CategoryUpdateType,
) => {
  const response = await http.put<
    CategoryUpdateType,
    AxiosResponse<{
        dataValues: ICategoryCore;
    }>
  >(`${prefix}/${id}/update`, data);

  return response.data.dataValues;
};

export const categoryDeleteApi = async (id: string | number) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const updateCategoryTree = async (data: CategoryUpdateTreePayload) => {
  const response = await http.put<CategoryUpdateTreePayload>(`${prefix}/sort`, data);

  return response;
};

export const getSpecificationByCategoryId = async (id: string | undefined) => {
  const response = await http.get<AxiosResponse<SpecificationGroupCore>>(
    `${prefix}/${id}/specificationsTree?translations=${true}`,
  );

  return response.data.data;
};

export const updateRefundableCategoryApi = async (id: string, data: RefundableCategoryPayload) => {
  const response = await http.patch<
    RefundableCategoryPayload
    >(`${prefix}/refundable/${id}`, data);

  return response.data;
};
