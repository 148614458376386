import { memo } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { headCells } from '../../helper';
import { StyledTableHead } from './styled';

const TableHead = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            size="small"
            sx={{
              whiteSpace: 'nowrap',
              height: '64px',
              paddingLeft: '16px',
              fontWeight: 500,
              fontFamily: 'Roboto Medium',
              fontSize: '14px',
              ...headCell.styles,
            }}
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export default memo(TableHead);
