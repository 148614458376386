import {
  memo,
  useState,
  Dispatch,
  useEffect,
  MouseEvent,
  ChangeEvent,
  SetStateAction,
} from 'react';

import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { TableData, UpdateType } from './helper';
import TableHead from './TableHead';
import TableBody from './TableBody/index';
import { IUserCore } from '../../../../types/users/index';
import { dateFormatter } from '../../../../utils/helpers';
import { updateUserByIdApi } from '../../../../requests/user';
import { Order } from '../../../../utils/types';

interface ITableProps {
  order: Order;
  offset: number;
  users: IUserCore[];
  totalUsers: number;
  rowsPerPage: number;
  orderBy?: keyof TableData;
  handleUpdateRequest: () => void;
  setOffset: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  handleDeleteRequest: (userId: string) => Promise<void>;
  handleSortRequest: (order: Order, sort: keyof TableData) => Promise<void>;
  handleActivateRequest: (userInfo: UpdateType, isActive: boolean) => Promise<void>;
}

const Table = ({
  order,
  users,
  offset,
  orderBy,
  setOffset,
  totalUsers,
  rowsPerPage,
  setRowsPerPage,
  handleSortRequest,
  handleUpdateRequest,
  handleDeleteRequest,
  handleActivateRequest,
}: ITableProps) => {
  const [rows, setRows] = useState<TableData[]>([]);
  const [previous, setPrevious] = useState<Record<string, TableData>>({});
  const [isEqualPrevAndRowData, setIsEqualPrevAndRowData] = useState(true);
  const [changedRowId, setChangedRowId] = useState('');

  useEffect(() => {
    const compareRows = (rowId: string): boolean => {
      const foundChangedItem = rows.find((r) => r.id === rowId);

      return isEqual(foundChangedItem, previous[rowId]);
    };

    if (!isEmpty(previous) && rows.length) {
      const result = compareRows(changedRowId);

      setIsEqualPrevAndRowData(result);
    }
  }, [previous, rows, changedRowId]);

  useEffect(() => {
    const sanitizeUsers = (data: IUserCore[]): TableData[] => {
      return data.map(
        ({
          id,
          image,
          email,
          phone,
          firstName,
          lastName,
          isActive,
          createdAt,
          address,
        }) => ({
          id,
          image,
          email,
          name: `${firstName}  ${lastName || ''} `,
          isActive,
          isEditMode: false,
          createdAt: dateFormatter(createdAt),
          phone,
          address,
        }),
      );
    };

    const result = sanitizeUsers(users);

    setRows(result);
  }, [users]);

  const onSave = (rowId: string) => {
    if (!isEqualPrevAndRowData) {
      const updateUserById = async () => {
        const foundChangedItem = rows.find((r) => r.id === rowId);

        if (foundChangedItem) {
          const { phone = '', email, isActive } = foundChangedItem;

          await updateUserByIdApi(rowId, {
            email,
            phone,
            isActive,
          });
        }

        handleUpdateRequest();
      };

      updateUserById();
    }
  };

  const onToggleEditMode = (rowId: string) => {
    setRows(() =>
      rows.map((row) =>
        (row.id === rowId ? { ...row, isEditMode: !row.isEditMode } : row)));
  };

  const onRevert = (rowId: string) => {
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        return previous[rowId] ? previous[rowId] : row;
      }

      return row;
    });

    setRows(newRows);

    setPrevious((state) => {
      delete state[rowId];

      return state;
    });
    onToggleEditMode(rowId);
  };

  const onChange = (e: any, rowData: TableData) => {
    const { id } = rowData;

    if (!previous[id]) {
      setPrevious((state) => ({ ...state, [id]: rowData }));
    }

    const { value, name } = e.target;

    const newRows = rows.map((row) =>
      (row.id === id ? { ...row, [name]: value } : row));

    setChangedRowId(id);

    setRows(newRows);
  };

  // TODO: this functional still not used
  // const handleDatePickerChange = (date: Date | null, rowData: TableData) => {
  //   const { id } = rowData;

  //   if (!previous[id]) {
  //     setPrevious((state) => ({ ...state, [id]: rowData }));
  //   }

  //   const newRows = rows.map((row) =>
  //     (row.id === id ? { ...row, createdAt: date } : row));

  //   setRows(newRows);
  // };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    handleSortRequest(isAsc ? 'desc' : 'asc', property);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
  };

  // FIXME: this may need in future -  Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  // offset > 0 ? Math.max(0, (1 + offset) * rowsPerPage - rows.length) : 0;
  // const emptyRows = 2;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody
              rows={rows}
              page={offset}
              onSave={onSave}
              onChange={onChange}
              onRevert={onRevert}
              // emptyRows={emptyRows}
              rowsPerPage={rowsPerPage}
              onToggleEditMode={onToggleEditMode}
              handleDeleteRequest={handleDeleteRequest}
              isEqualPrevAndRowData={isEqualPrevAndRowData}
              handleActivateRequest={handleActivateRequest}
            />
          </MuiTable>
        </TableContainer>

        <TablePagination
          page={offset}
          component="div"
          count={totalUsers}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, newPage: number) => setOffset(newPage)}
        />
      </Paper>
    </Box>
  );
};

export default memo(Table);
