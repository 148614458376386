import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ICreateAWSLinkResponse, IUploadFileProps } from '../models/upload-model';
import { http } from '../services/RequestService';
import { AWS_S3_PREFIX } from '../utils/constants';

export const uploadFile = async ({ file, url }: IUploadFileProps) => {
  const options: AxiosRequestConfig = {
    headers: { 'Content-Type': 'application/octet-stream' },
    withCredentials: false,
  };

  try {
    const response = await axios.put<IUploadFileProps['file'], boolean>(url, file, options);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getUploadUrl = async () => {
  const { data: { data } } = await http.get<AxiosResponse<ICreateAWSLinkResponse>>(`${AWS_S3_PREFIX}?extension=png`);

  return { url: data?.putUrl || '', img: data?.path || '' };
};
