import { memo, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import Flag from 'react-world-flags';
import InputAdornment from '@mui/material/InputAdornment';

import UploadContainer from '../../../../UploadContainer';
import BaseInput from '../../../../../../../common/components/Input/BaseInput';
import { FormValues } from '../../helper';

const LeftSection = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();

  const leftPartImageValue = watch('leftSection.image');

  const [leftPartImagePreview, setLeftPartImagePreview] = useState<string>(leftPartImageValue);

  return (
    <Box>
      <Typography component="p" variant="h5">
        Left Section
      </Typography>

      <Stack spacing="18px">
        <Stack spacing="8px">
          <UploadContainer
            label="Image"
            errors={errors}
            setValue={setValue}
            inputName="leftSection.image"
            id="left-part-full-row-banner"
            imagePreivew={leftPartImagePreview}
            setImagePreview={setLeftPartImagePreview}
          />
        </Stack>

        <Stack spacing="8px">
          <BaseInput
            errors={errors}
            label="Title"
            inputProps={{ ...register(`translations.${0}.content.leftSection.title`) }}
            placeholder="Title"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="us" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${1}.content.leftSection.title`) }}
            placeholder="Title"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="am" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${2}.content.leftSection.title`) }}
            placeholder="Title"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="ru" height="22" width="22" />
              </InputAdornment>
          )}
          />
        </Stack>
        <Stack spacing="8px">
          <BaseInput
            errors={errors}
            label="Description"
            inputProps={{ ...register(`translations.${0}.content.leftSection.description`) }}
            placeholder="Description"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="us" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${1}.content.leftSection.description`) }}
            placeholder="Description"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="am" height="22" width="22" />
              </InputAdornment>
          )}
          />
          <BaseInput
            errors={errors}
            inputProps={{ ...register(`translations.${2}.content.leftSection.description`) }}
            placeholder="Description"
            endAdornment={(
              <InputAdornment position="end">
                <Flag code="ru" height="22" width="22" />
              </InputAdornment>
          )}
          />
        </Stack>

        <Stack spacing="8px">
          <BaseInput
            errors={errors}
            label="Link"
            inputProps={{
              placeholder: 'Link',
              ...register('leftSection.link'),
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(LeftSection);
