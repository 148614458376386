import { memo } from 'react';

import Typography from '@mui/material/Typography';

import { StyledPaper, StyledContentPaper, StyledBox, StyledBoxTitle } from '../styled';

interface OrderNotesProps {
  orderNote: string;
}

const OrderNotes = ({ orderNote }:OrderNotesProps) => (
  <StyledPaper sx={{ minWidth: '480px' }}>
    <StyledContentPaper>
      <StyledBox>
        <StyledBoxTitle>Customer</StyledBoxTitle>
        <Typography>
          {orderNote}
        </Typography>

      </StyledBox>
    </StyledContentPaper>
  </StyledPaper>
);

export default memo(OrderNotes);
