import { forwardRef, memo, ReactNode } from 'react';

import { InputBaseProps } from '@mui/material/InputBase';
import Box from '@mui/material/Box';

import {
  StyledBaseInput,
  StyledFormControl,
  StyledInputLabel,
  StyledInputBox,
  StyledInputBoxTitle,
} from './styled';
import ErrorMessage from '../../ErrorMessage';

interface IBaseInputProps extends InputBaseProps {
  label?: string | ReactNode;
  accept?: string;
  inputBoxTitle?: string;
  calculateInputWidth?: boolean;
  customMessageText?: ReactNode;
  withStyledInputBox?: boolean;
  errors?: any;
}

const BaseInput = forwardRef((props: IBaseInputProps, ref) => {
  const {
    id,
    label,
    accept,
    errors,
    inputBoxTitle,
    customMessageText,
    withStyledInputBox,
    calculateInputWidth = false,
    inputProps,
    sx,
    ...restProps
  } = props;

  const renderInputContent = () => (
    <>
      {label && (
        <StyledInputLabel shrink htmlFor={id}>
          {label}
        </StyledInputLabel>
      )}
      <StyledFormControl variant="standard">
        <StyledBaseInput
          inputRef={ref}
          inputProps={inputProps}
          sx={calculateInputWidth ? {
            input: {
              width: `${`${restProps.value || 1}`.length || 1}ch`,
            },
            ...sx,
          } : sx}
          {...restProps}
        />
      </StyledFormControl>
    </>
  );

  return (
    <>
      {withStyledInputBox ? (
        <>
          <StyledInputBox>
            {inputBoxTitle && (
              <StyledInputBoxTitle>{inputBoxTitle}</StyledInputBoxTitle>
            )}

            <Box>{renderInputContent()}</Box>
          </StyledInputBox>

          {errors && inputProps?.name && (
            <ErrorMessage
              errors={errors}
              name={inputProps.name}
            />
          )}
        </>
      ) : (
        <Box>
          {renderInputContent()}
          {errors && inputProps?.name && (
            <ErrorMessage
              errors={errors}
              name={inputProps.name}
            />
          )}
        </Box>
      )}
    </>
  );
});

export default memo(BaseInput);
