import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import useMount from '../../hooks/useMount';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../routes';
import { attemptToGetUserAction } from '../../store/actions/auth';
import { selectIsAuth } from '../../store/selectors/auth';

const PageLayout = () => {
  // TODO: this is temporary solution
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(attemptToGetUserAction());
  });

  const { isAuth } = useSelector(selectIsAuth);

  const isLoggedIn: boolean = isAuth;
  const element = useRoutes(isLoggedIn ? PRIVATE_ROUTES : PUBLIC_ROUTES);

  return <>{element}</>;
};

export default PageLayout;
