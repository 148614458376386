import { memo } from 'react';

import { useFormContext } from 'react-hook-form';

import BaseInput from '../../../../../../../../../../common/components/Input/BaseInput';

interface ILinkProps {
  bannerIndex: number;
}

const Link = ({ bannerIndex }: ILinkProps) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <>
      <BaseInput
        placeholder="Link"
        label="Link"
        inputProps={{
          ...register(`allBanners.items[${bannerIndex}].link`),
        }}
        errors={errors}
      />
    </>
  );
};

export default memo(Link);
