import { Palette } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import CircularStdFont from '../assets/fonts/CircularStd';
import RobotoFont from '../assets/fonts/Roboto';
import { FontFamilyNames } from '../types/global/theme/fonts';

const typography = (palette: Palette): TypographyOptions => ({
  allVariants: {
    fontFamily: FontFamilyNames.RobotoNormal400,
    color: palette.text.primary,
    letterSpacing: '-0.04em',
  },
  fontFamily: [
    ...RobotoFont.fontFamily,
    ...CircularStdFont.fontFamily,
    'sans',
  ].join(', '),
  h1: {
    fontSize: 35,
    letterSpacing: '-0.24px',
  },
  h2: {
    fontSize: 16,
    letterSpacing: '-0.24px',
    fontFamily: FontFamilyNames.CircularStdBook,
    fontWeight: '450',
  },
  /** Used */
  h3: {
    fontSize: '28px',
    fontFamily: FontFamilyNames.RobotoMedium500,
    letterSpacing: '0.25px',
  },
  /** Used */
  h4: {
    fontSize: '20px',
    letterSpacing: '0.15px',
  },
  /** Used */
  h5: {
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  h6: {
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  /** Used */
  subtitle1: {
    fontSize: '14px',
    letterSpacing: '0.25px',
  },
  body1: {
    fontSize: '14px',
    letterSpacing: '0.17px',
  },
  body2: {
    fontSize: 12,
    letterSpacing: '-0.04px',
  },
  overline: {
    letterSpacing: '0.33px',
    textTransform: 'uppercase',
  },
});

export default typography;
