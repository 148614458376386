import { AxiosResponse } from 'axios';

import { BannerCardResponse } from '../models/banner-card/runing-line-model';
import { http } from '../services/RequestService';

const prefix = '/cards';

interface UpdateBanner<T> {
  payload: T;
  cardId?: string;
}
export const updateBannerCardByIdApi = async <T extends any = any>({
  payload,
  cardId,
}: UpdateBanner<T>) => {
  const response = await http.put<T, AxiosResponse<BannerCardResponse<T>>>(
    `${prefix}/${cardId}`,
    payload,
  );

  return response;
};

export const createBannerCardlApi = async <T extends any = any>(payload: T) => {
  const response = await http.post<T, AxiosResponse<BannerCardResponse<T>>>(
    prefix,
    payload,
  );

  return response;
};
