import { CSSProperties } from 'react';

import { IBusinessTypeCore } from '../../../../types/businessTypes';

export interface TableData {
  id: string;
  key: string;
  text: string;
}

export interface TableHeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  styles: CSSProperties;
}

export const headCells: readonly TableHeadCell[] = [
  {
    id: 'id',
    disablePadding: true,
    label: 'Id',
    styles: {
      width: '250px',
      paddingLeft: '16px',
    },
  },
  {
    id: 'key',
    disablePadding: false,
    label: 'Key',
    styles: {
      width: '250px',
    },
  },
  {
    id: 'text',
    disablePadding: false,
    label: 'Name',
    styles: {
      width: '250px',
    },
  },
];

export const sanitizeBusinessTypes = (data: IBusinessTypeCore[]): TableData[] => {
  return data.map(({ id, key, translation }) => {
    return {
      id,
      key,
      text: translation.text,
    };
  });
};
