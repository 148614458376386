import { AxiosResponse } from 'axios';

import {
  UserFilterPayload,
  UserSearchPayload,
  UpdateUserPayloadType,
  UserFilterResponseType,
  UserUpdateResponseType,
} from '../models/user-model';
import { http } from '../services/RequestService';
import { IUserCore } from '../types/users/index';
import { ResponseData } from '../utils/types';

const prefix = 'users';

export const getAllUsersApi = async () => {
  const response = await http.get<ResponseData<IUserCore[]>>(prefix);

  return response.data;
};

export const getUserByIdApi = async (id: string) => {
  const response = await http.get(`${prefix}/${id}`);

  return response.data;
};

export const updateUserByIdApi = async (
  id: string,
  data: UpdateUserPayloadType,
) => {
  const response = await http.put<
    UpdateUserPayloadType,
    UserUpdateResponseType
  >(`${prefix}/${id}`, data);

  return response.data;
};

export const userFilterApi = async (params: UserFilterPayload) => {
  const response = await http.get<
    UserFilterPayload,
    AxiosResponse<UserFilterResponseType>
  >(`${prefix}`, { params });

  return response.data;
};

export const userDeleteApi = async (id: string) => {
  const response = await http.delete<number>(`${prefix}/${id}`);

  return response.data;
};

export const searchUsersApi = async (params:UserSearchPayload) => {
  const response = await http.get<UserSearchPayload, AxiosResponse<ResponseData<IUserCore[]>>>(`${prefix}`, {
    params,
  });

  return response.data;
};
