import { memo } from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

import Row from './components/SpecificationsGroupRows';
import { SpecificationGroup, SpecificationGroupCore } from '../../../../models/category-specifications-model';
import SpecificationsHead from './components/SpecificationsHead';
import { sortSpecificationGroupApi } from '../../../../requests/specification-group';
import { sanitizeSpecificationGroup } from './components/helpers';

interface SpecificationGroupsProps {
  currentCategory: SpecificationGroupCore | null;
  getCategoryInfo: ()=> void;
}

const CollapsibleTable = ({ currentCategory, getCategoryInfo }: SpecificationGroupsProps) => {
  const specificationGroups:SpecificationGroup[] = currentCategory?.specificationGroups || [];

  const onDragEnd = (result: DropResult) => {
    if (specificationGroups.length && result.destination) {
      const [removed] = specificationGroups.splice(result.source.index, 1);

      specificationGroups?.splice(result.destination.index, 0, removed);

      const sortSpecificationGroup = async () => {
        const data = sanitizeSpecificationGroup(specificationGroups || []);

        const response = await sortSpecificationGroupApi({ data });

        if (response) {
          getCategoryInfo();
        }
      };

      sortSpecificationGroup();
    }
  };

  return (
    <Box component={Paper}>
      <SpecificationsHead />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableRow">
          {(providedDroppable:DroppableProvided) => (
            <Box {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {specificationGroups?.map((row, index) => (
                <Draggable key={row.id} draggableId={row.id} index={index}>
                  {(providedDraggable, snapshot) => (
                    <Box
                      ref={providedDraggable.innerRef}
                      data-snapshot={snapshot}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                    >
                      <Row row={row || null} getCategoryInfo={getCategoryInfo} />
                      {providedDroppable.placeholder}
                    </Box>
                  )}
                </Draggable>
              ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default memo(CollapsibleTable);
